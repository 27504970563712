import {FC, useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {getClinicById} from '../../apis'
import {ClinicStaffList} from '../../components/ClinicStaffList'

export const ClinicStaffListPageWrapper: FC = () => {
  const navigate = useNavigate()
  const {id} = useParams()
  const [clinic, setClinic] = useState<any>({})

  const getClinicCallback = useCallback(async () => {
    try {
      if (!id) return navigate('/clinics/list', {state: {showNotFoundNoti: true}})

      const clinicData = await getClinicById(id)
      setClinic(clinicData)
    } catch (error) {
      return navigate('/clinics/list', {state: {showNotFoundNoti: true}})
    }
  }, [id, navigate])

  useEffect(() => {
    getClinicCallback()
  }, [getClinicCallback])

  const ClinicStaffListBreadCrumbs: Array<PageLink> = [
    {
      title: 'Clinics',
      path: '/clinics/list',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: clinic.name,
      path: '/clinics/list',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={ClinicStaffListBreadCrumbs}>Staff list</PageTitle>
      <ClinicStaffList clinicId={id || ''} />
    </>
  )
}
