import {axiosInstace} from '../common/axiosInstace'

export const getCallLogByClinic = async (
  search: string = '',
  hideCompleted = '',
  page: any = 1,
  limit: any = 20
) => {
  const params = {
    search,
    page,
    limit,
    hideCompleted,
  }

  const paramString = new URLSearchParams(params).toString()

  const res = await axiosInstace.get(`/call-log?${paramString}`)
  return res.data
}

export const createCallLog = async (data: any) => {
  const res = await axiosInstace.post('/call-log', data)
  return res.data
}

export const getCallLogInfo = async (id: any) => {
  const res = await axiosInstace.get(`/call-log/${id}`)
  return res.data
}

export const updateCallLogInfo = async (id: any, data: any) => {
  const res = await axiosInstace.put(`/call-log/${id}`, data)
  return res.data
}

export const markCallLogDone = async (id: number) => {
  const res = await axiosInstace.put(`/call-log/${id}/done`)
  return res.data
}

export const markCallLogUnDone = async (id: number) => {
  const res = await axiosInstace.put(`/call-log/${id}/undone`)
  return res.data
}

export const getBlobTimeSpentReport = async (startDate: any, endDate: any) => {
  return await axiosInstace.get('/call-log/generate-report', {
    params: {
      startDate,
      endDate,
    },
    responseType: 'blob',
  })
}
