import {Navigate, Route, Routes} from 'react-router-dom'
import {privateRoute, staffRoutes} from '.'

export const StaffRoutes = () => (
  <Routes>
    {staffRoutes.map((item) => {
      return privateRoute(item.path, item.element)
    })}
    {/* Page Not Found */}
    <Route path='*' element={<Navigate to='/error/404' />} />
  </Routes>
)
