import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {PatientAddDelegateForm} from '../../components/patient/PatientAddDelegateForm'

export const PatientAddDelegatePageWrapper: FC = () => {
  const ClinicAddDelegateBreadCrumbs: Array<PageLink> = [
    {
      title: 'Manage Delegates',
      path: '/patient/delegates',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={ClinicAddDelegateBreadCrumbs}>Add Delegate</PageTitle>
      <PatientAddDelegateForm />
    </>
  )
}
