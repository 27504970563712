import {axiosInstace} from '../common/axiosInstace'

export const getContactsByClinic = async (search: string = '', page: any = 1, limit: any = 20) => {
  const params = {
    search,
    page,
    limit,
  }

  const paramString = new URLSearchParams(params).toString()

  const res = await axiosInstace.get(`/frequent-contacts?${paramString}`)
  return res.data
}

export const importContactsCsv = async (frmData: FormData) => {
  const res = await axiosInstace.post(`/frequent-contacts/import-contacts-csv`, frmData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
  return res.data
}

export const createContact = async (data: any) => {
  const res = await axiosInstace.post('/frequent-contacts', data)
  return res.data
}
