import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import Select from 'react-select'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import {useCommonAlert} from '../../../common/CommonAlert'
import {addSubClinicStaff, getRoles} from '../../../apis'

const addClinicStaffSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Wrong email format').required('Email is required'),
  phone: Yup.string().required('Phone is required'),
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Minimum 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      'Must have at least 1 uppercase, 1 lowercase and 1 number'
    ),
  passwordConfirm: Yup.string().required('Confirm password is required'),
  role: Yup.object(),
})

type Props = {
  clinicId: any
}

export const AddSubClinicStaffForm: FC<Props> = (props) => {
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()

  const [roleOpts, setRoleOpts] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const getRoleListCallback = useCallback(async () => {
    try {
      const roles = await getRoles()
      setRoleOpts(
        roles.map((item: any) => {
          return {
            value: item.id,
            label: item.description,
          }
        })
      )
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getRoleListCallback()
  }, [getRoleListCallback])

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirm: '',
      role: roleOpts[0],
    },
    validationSchema: addClinicStaffSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        await addSubClinicStaff({
          ...values,
          role: values.role.value,
          clinic: props.clinicId
        })
        return navigate(`/clinic/sub-clinics/${props.clinicId}/staff-list`, {
          state: {showAddedNoti: true},
        })
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Add Sub Clinic Staff</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          <h4 className='card-title my-3'>Staff Detail</h4>
          <Row>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='FNameInput'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder='First Name'
                {...formik.getFieldProps('firstName')}
                className={clsx({
                  'is-invalid': formik.touched.firstName && formik.errors.firstName,
                })}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.firstName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='LNameInput'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder='Last Name'
                {...formik.getFieldProps('lastName')}
                className={clsx({'is-invalid': formik.touched.lastName && formik.errors.lastName})}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.lastName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='EmailInput'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Email'
                {...formik.getFieldProps('email')}
                autoComplete='new-email'
                className={clsx({'is-invalid': formik.touched.email && formik.errors.email})}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='PhoneInput'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder='Phone Number'
                {...formik.getFieldProps('phone')}
                autoComplete='off'
                className={clsx({'is-invalid': formik.touched.phone && formik.errors.phone})}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='PassInput'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Password'
                {...formik.getFieldProps('password')}
                autoComplete='new-password'
                className={clsx({'is-invalid': formik.touched.password && formik.errors.password})}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.password}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='PassConfirmInput'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm Password'
                {...formik.getFieldProps('passwordConfirm')}
                autoComplete='new-password'
                className={clsx({
                  'is-invalid': formik.touched.passwordConfirm && formik.errors.passwordConfirm,
                })}
              />
              {formik.touched.passwordConfirm && formik.errors.passwordConfirm && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.passwordConfirm}</div>
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} md='12' lg='6' className='mb-3'>
              <Form.Label>Select Role</Form.Label>
              <Select
                options={roleOpts}
                value={formik.values.role}
                onChange={(values) => {
                  formik.setFieldValue('role', values)
                }}
              />
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end py-6 px-9'>
          <Link to={`/clinic/sub-clinics/${props.clinicId}/staff-list`} className='btn btn-secondary me-5'>
            Cancel
          </Link>

          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}
