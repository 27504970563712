import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Form} from 'react-bootstrap'
import Select from 'react-select'
import * as Yup from 'yup'
import {getAllClinics, getOrganizationById, updateOrganization} from '../apis'
import {useCommonAlert} from '../common/CommonAlert'
import {Link, useNavigate, useParams} from 'react-router-dom'

const editOrganizationSchema = Yup.object().shape({
  name: Yup.string().required('Organization name is required'),
  description: Yup.string(),
  clinics: Yup.array(),
})

export const EditOrganizationForm: FC = () => {
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()
  const {id} = useParams()

  const [clinicOpts, setClinicOpts] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [organization, setOrganization] = useState<any>({})

  const getDataCallback = useCallback(async () => {
    try {
      if (!id) return navigate('/organizations/list', {state: {showNotFoundNoti: true}})

      const data = await getAllClinics()
      setClinicOpts(
        data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          }
        })
      )

      const organizationData = await getOrganizationById(id)
      const OrganizationClinics = organizationData.clinics.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setOrganization({...organizationData, clinics: OrganizationClinics})
    } catch (error) {
      return navigate('/organizations/list', {state: {showNotFoundNoti: true}})
    }
  }, [id, navigate])

  useEffect(() => {
    getDataCallback()
  }, [getDataCallback])

  const formik = useFormik({
    initialValues: {
      name: (organization.name as string) || '',
      description: (organization.description as string) || '',
      clinics: organization.clinics || [],
    },
    validationSchema: editOrganizationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if (!id) return navigate('/organizations/list', {state: {showNotFoundNoti: true}})
      setLoading(true)
      try {
        setLoading(false)
        await updateOrganization(id, {
          ...values,
          clinics: values.clinics.map((item: any) => item.value),
        })
        return navigate('/organizations/list', {state: {showSavedNoti: true}})
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Edit Organization</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          {/* Organization detail */}
          <h4 className='card-title mb-3'>Organization Detail</h4>
          <Form.Group className='mb-3' controlId='organizationNameInput'>
            <Form.Label>Organization Name</Form.Label>
            <Form.Control
              placeholder='Organization Name'
              {...formik.getFieldProps('name')}
              className={clsx({'is-invalid': formik.touched.name && formik.errors.name})}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.name}</div>
              </div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='organizationDescriptionInput'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              placeholder='Description'
              {...formik.getFieldProps('description')}
              className={clsx({
                'is-invalid': formik.touched.description && formik.errors.description,
              })}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.description}</div>
              </div>
            )}
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Select Clinics</Form.Label>
            <Select
              isMulti={true}
              options={clinicOpts}
              placeholder='Select Clinics'
              value={formik.values.clinics}
              onChange={(values) => {
                formik.setFieldValue('clinics', values)
              }}
            />
          </Form.Group>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end py-6 px-9'>
          <Link to='/organizations/list' className='btn btn-secondary me-5'>
            Cancel
          </Link>

          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}
