import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Card} from 'react-bootstrap'
import {useAuth} from '../../modules/auth'
import {choosePatientProfile, getPatientProfileList} from '../../apis'
import {useCommonAlert} from '../../common/CommonAlert'
import {useNavigate} from 'react-router-dom'

export const PatientChooseProfile: FC = () => {
  const {currentPatient} = useAuth()
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()

  const [profileList, setProfileList] = useState<any[]>([])
  const [activeId, setActiveId] = useState<number>(currentPatient?.currentProfileId || 0)

  useEffect(() => {
    if (currentPatient && currentPatient.profileCount === 1) {
      navigate('/patient/dashboard')
    }
  }, [currentPatient, navigate])

  const getProfileListCallback = useCallback(async () => {
    try {
      const resData = await getPatientProfileList()
      setProfileList(resData)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getProfileListCallback()
  }, [getProfileListCallback])

  const selectProfile = async (profileId: number) => {
    try {
      await choosePatientProfile(profileId)
      window.location.reload()
    } catch (error) {
      showAlert('error')
      console.log(error)
    }
  }

  return (
    <>
      <h1 className='mb-6'>
        Hello, {currentPatient?.fullName}. Please choose the profile you want to login as...
      </h1>

      <div className='row g-6'>
        {profileList.map((item: any) => (
          <div className='col-12 col-sm-6 col-lg-4' key={item.profileId}>
            <Card
              className={`${activeId === item.profileId ? 'active ' : ''}selection-card`}
              key={item.profileId}
              onClick={() => setActiveId(item.profileId)}
            >
              <Card.Header className='min-h-50px'>
                <Card.Title className='m-0'>
                  <h3 className='fw-bolder m-0'>A {item.type}</h3>
                </Card.Title>
              </Card.Header>

              <Card.Body className='pt-3'>
                <p>{item.name}</p>
                <p>{item.totalUnreadCount ? item.totalUnreadCount : 0} new messages</p>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>

      <div className='d-flex justify-content-center mt-6'>
        <Button onClick={() => selectProfile(activeId)}>Save</Button>
      </div>
    </>
  )
}
