export interface AuthModel {
  access_token: string
  refreshToken?: string
}

export interface UserAddressModel {
  addressLine: string
  city: string
  state: string
  postCode: string
}

export interface UserCommunicationModel {
  email: boolean
  sms: boolean
  phone: boolean
}

export interface UserEmailSettingsModel {
  emailNotification?: boolean
  sendCopyToPersonalEmail?: boolean
  activityRelatesEmail?: {
    youHaveNewNotifications?: boolean
    youAreSentADirectMessage?: boolean
    someoneAddsYouAsAsAConnection?: boolean
    uponNewOrder?: boolean
    newMembershipApproval?: boolean
    memberRegistration?: boolean
  }
  updatesFromKeenthemes?: {
    newsAboutKeenthemesProductsAndFeatureUpdates?: boolean
    tipsOnGettingMoreOutOfKeen?: boolean
    thingsYouMissedSindeYouLastLoggedIntoKeen?: boolean
    newsAboutStartOnPartnerProductsAndOtherServices?: boolean
    tipsOnStartBusinessProducts?: boolean
  }
}

export interface UserAuthModel {
  id: number
  email: string
  firstName: string
  lastName: string
  fullName: string
  role: {
    id: number
    title: string
    description: string
  }
  isClinicAdmin: boolean
  clinic?: {
    id: number
    isFaxageEnabled: boolean
    parentClinic?: any
  }
  chatParticipant: {
    id: number
  }
  phone: number
  unreadMessages: UnreadMessages[]
  unreadSMSMessages: UnreadMessages[]
  organization: string
  avatar: string
  signature: string
  hasPin?: boolean
}

export interface PatientAuthModel {
  id: number
  avatar: string
  email: string
  phone: number
  dob: string
  address: string
  firstName: string
  lastName: string
  fullName: string
  isVerified: boolean
  data: Record<string, any>
  currentProfileId: number
  profileCount: number
  clinic: {
    id: number
    isFaxageEnabled: boolean
  }
  chatParticipant: {
    id: number
  }
  unreadMessages: UnreadMessages[]
  ihealth: string
}

export interface UnreadMessages {
  conversationId: number
  unreadCount: number
}

export enum UserStatus {
  AUTO = 'AUTO',
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
  PATIENT = 'PATIENT',
}
