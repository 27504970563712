import {FC, useCallback, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getStaffClinicInfo} from '../../../apis'
import {OutgoingFaxList} from '../../../components/staff/OutgoingFaxList'

export const OutgoingFaxListPageWrapper: FC = () => {
  const navigate = useNavigate()

  const getClinicCallback = useCallback(async () => {
    try {
      await getStaffClinicInfo()
    } catch (error) {
      return navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    getClinicCallback()
  }, [getClinicCallback])

  return (
    <>
      <PageTitle breadcrumbs={[]}>Outgoing Fax List</PageTitle>
      <OutgoingFaxList />
    </>
  )
}
