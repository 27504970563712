import {FC, Suspense} from 'react'
import {Route} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import ChatPage from '../modules/apps/chat/ChatPage'
import {AddClinicsPageWrapper} from '../pages/clinics/AddClinicsPageWrapper'
import {AddOrganizationsPageWrapper} from '../pages/clinics/AddOrganizationsPageWrapper'
import {ClinicAddStaffPageWrapper} from '../pages/clinics/ClinicAddStaffPageWrapper'
import {ClinicEditStaffPageWrapper} from '../pages/clinics/ClinicEditStaffPageWrapper'
import {ClinicsListWrapper} from '../pages/clinics/ClinicsListWrapper'
import {ClinicStaffListPageWrapper} from '../pages/clinics/ClinicStaffListPageWrapper'
import {EditClinicPageWrapper} from '../pages/clinics/EditClinicPageWrapper'
import {EditOrganizationPageWrapper} from '../pages/clinics/EditOrganizationPageWrapper'
import {OrganizationListWrapper} from '../pages/organizations/OrganizationListWrapper'
import {StaffAddStaffPageWrapper} from '../pages/staff/clinic-staff/StaffAddStaffPageWrapper'
import {StaffEditStaffPageWrapper} from '../pages/staff/clinic-staff/StaffEditStaffPageWrapper'
import {StaffListPageWrapper} from '../pages/staff/clinic-staff/StaffListPageWrapper'
import {ClinicPatientListPageWrapper} from '../pages/staff/patient/ClinicPatientListPageWrapper'
import {IncomingFaxListPageWrapper} from '../pages/staff/incoming-faxes/IncomingFaxListPageWrapper'
import {ClinicAddPatientPageWrapper} from '../pages/staff/patient/ClinicAddPatientPageWrapper'
import {ClinicAdminEditClinicPageWrapper} from '../pages/staff/clinic/ClinicAdminEditClinicPageWrapper'
import {EditClinicFaxagePageWrapper} from '../pages/staff/clinic/EditClinicFaxagePageWrapper'
import {FaxRecipientListPageWrapper} from '../pages/staff/clinic/FaxRecipientListPageWrapper'
import {ClinicAddDelegatePageWrapper} from '../pages/staff/patient/ClinicAddDelegatePageWrapper'
import {ClinicIhealthPatientListPageWrapper} from '../pages/staff/patient/ClinicIhealthPatientListPageWrapper'
import {ClinicEditPatientPageWrapper} from '../pages/staff/patient/ClinicEditPatientPageWrapper'
import {IncomingFaxPreviewPageWrapper} from '../pages/staff/incoming-faxes/IncomingFaxPreviewPageWrapper'
import {OutgoingFaxListPageWrapper} from '../pages/staff/outgoing-faxes/OutgoingFaxListPageWrapper'
import {OutgoingFaxPreviewPageWrapper} from '../pages/staff/outgoing-faxes/OutgoingFaxPreviewPageWrapper'
import {SMSChatPage} from '../pages/staff/sms-chat/SMSChatPage'
import {ClinicCallLogPageWrapper} from '../pages/staff/clinic/ClinicCallLogPageWrapper'
import {ClinicAddCallLogPageWrapper} from '../pages/staff/clinic/ClinicAddCallLogPageWrapper'
import {ClinicFrequentContactsPageWrapper} from '../pages/staff/clinic/ClinicFrequentContactsPageWrapper'
import {ClinicEditCallLogPageWrapper} from '../pages/staff/clinic/ClinicEditCallLogPageWrapper'
import {ClinicSubClinicListWrapper} from '../pages/staff/sub-clinic/ClinicSubClinicListWrapper'
import {ClinicAddSubClinicPageWrapper} from '../pages/staff/sub-clinic/ClinicAddSubClinicPageWrapper'
import {ClinicSubClinicStaffListPageWrapper} from '../pages/staff/sub-clinic/ClinicSubClinicStaffListPageWrapper'
import {AddSubClinicStaffPageWrapper} from '../pages/staff/sub-clinic/AddSubClinicStaffPageWrapper'
import { ClinicSubClinicPatientListPageWrapper } from '../pages/staff/sub-clinic/ClinicSubClinicPatientListPageWrapper'

export const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export const privateRoute = (path: string, elem: JSX.Element) => (
  <Route key={path} path={path} element={<SuspensedView>{elem}</SuspensedView>} />
)

type RouteConfig = {
  path: string
  element: JSX.Element
}

export const adminRoutes: RouteConfig[] = [
  {
    path: 'clinics/list',
    element: <ClinicsListWrapper />,
  },
  {
    path: 'clinics/add',
    element: <AddClinicsPageWrapper />,
  },
  {
    path: 'clinics/:id/edit',
    element: <EditClinicPageWrapper />,
  },
  {
    path: 'clinics/:id/staff-list',
    element: <ClinicStaffListPageWrapper />,
  },
  {
    path: 'clinics/:id/add-staff',
    element: <ClinicAddStaffPageWrapper />,
  },
  {
    path: 'clinics/:id/staff/:staffId/edit',
    element: <ClinicEditStaffPageWrapper />,
  },
  {
    path: 'organizations/list',
    element: <OrganizationListWrapper />,
  },
  {
    path: 'organizations/add',
    element: <AddOrganizationsPageWrapper />,
  },
  {
    path: 'organizations/:id/edit',
    element: <EditOrganizationPageWrapper />,
  },
]

export const staffRoutes: RouteConfig[] = [
  {
    path: 'clinic/staff',
    element: <StaffListPageWrapper />,
  },
  {
    path: 'clinic/add-staff',
    element: <StaffAddStaffPageWrapper />,
  },
  {
    path: 'clinic/edit-staff/:staffId',
    element: <StaffEditStaffPageWrapper />,
  },
  {
    path: 'chat/*',
    element: <ChatPage />,
  },
  {
    path: 'sms-chat/*',
    element: <SMSChatPage />,
  },
  {
    path: 'clinic/incoming-faxes',
    element: <IncomingFaxListPageWrapper />,
  },
  {
    path: 'clinic/incoming-faxes/:faxId',
    element: <IncomingFaxPreviewPageWrapper />,
  },
  {
    path: 'clinic/outgoing-faxes',
    element: <OutgoingFaxListPageWrapper />,
  },
  {
    path: 'clinic/outgoing-faxes/:id',
    element: <OutgoingFaxPreviewPageWrapper />,
  },
  {
    path: 'clinic/ihealth/patients',
    element: <ClinicIhealthPatientListPageWrapper />,
  },
  {
    path: 'clinic/patients',
    element: <ClinicPatientListPageWrapper />,
  },
  {
    path: 'clinic/patient/:id/add-delegate',
    element: <ClinicAddDelegatePageWrapper />,
  },
  {
    path: 'clinic/patient/:id/edit',
    element: <ClinicEditPatientPageWrapper />,
  },
  {
    path: 'clinic/add-patient',
    element: <ClinicAddPatientPageWrapper />,
  },
  {
    path: 'clinic/edit',
    element: <ClinicAdminEditClinicPageWrapper />,
  },
  {
    path: 'clinic/edit/faxage',
    element: <EditClinicFaxagePageWrapper />,
  },
  {
    path: 'clinic/fax-recipients',
    element: <FaxRecipientListPageWrapper />,
  },
  {
    path: 'clinic/call-logs',
    element: <ClinicCallLogPageWrapper />,
  },
  {
    path: 'clinic/add-call-log',
    element: <ClinicAddCallLogPageWrapper />,
  },
  {
    path: 'clinic/frequent-contacts',
    element: <ClinicFrequentContactsPageWrapper />,
  },
  {
    path: 'clinic/edit-call-log/:id',
    element: <ClinicEditCallLogPageWrapper />,
  },
  {
    path: 'clinic/sub-clinics',
    element: <ClinicSubClinicListWrapper />,
  },
  {
    path: 'clinic/add-sub-clinic',
    element: <ClinicAddSubClinicPageWrapper />,
  },
  {
    path: 'clinic/sub-clinics/:id/staff-list',
    element: <ClinicSubClinicStaffListPageWrapper />,
  },
  {
    path: 'clinic/sub-clinics/:id/add-staff',
    element: <AddSubClinicStaffPageWrapper />,
  },
  {
    path: 'clinic/sub-clinics/:id/patients',
    element: <ClinicSubClinicPatientListPageWrapper />,
  },
]
