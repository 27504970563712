import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Pagination} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTSVG, useDebounce} from '../../../_metronic/helpers'
import {useCommonAlert} from '../../common/CommonAlert'
import {getPageNumbersToShow} from '../../common/helpers'
import {getContactsByClinic, importContactsCsv} from '../../apis'
import {ImportContactsCsvModal} from './modal/ImportContactsCsvModal'

export const ClinicFrequentContacts: FC = () => {
  const location = useLocation()
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()

  const [contacts, setContacts] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageNumbers, setPageNumbers] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [showImportModal, setShowImportModal] = useState<boolean>(false)

  const debounceSearch = useDebounce(search, 300)

  const getContactsCallback = useCallback(async () => {
    try {
      const data = await getContactsByClinic(debounceSearch, currentPage, 20)
      setContacts(data.data)
      setTotalPages(data.pagination.totalPages)
      setPageNumbers(getPageNumbersToShow(currentPage, data.pagination.totalPages))
    } catch (error) {
      console.log(error)
    }
  }, [currentPage, debounceSearch])

  useEffect(() => {
    getContactsCallback()
  }, [getContactsCallback])

  useEffect(() => {
    const state: any = location.state
    if (state) {
      if (state.showAddedNoti) {
        showAlert('success', 'Contact added successfully')
      } else if (state.showEditedNoti) {
        showAlert('success', `Contact updated successfully`)
      }

      navigate('', {state: null, replace: true})
    }
  }, [location.state, showAlert, navigate])

  const handleImportCsv = useCallback(
    async (fromLine: number, selectedFile: any) => {
      try {
        const frmData = new FormData()
        frmData.append('from', fromLine.toString())
        frmData.append('file', selectedFile, selectedFile.name)
        await importContactsCsv(frmData)
        setShowImportModal(false)
        showAlert('success', 'Contacts imported successfully')
        await getContactsCallback()
      } catch (error: any) {
        if (error.response && error.response.data.statusCode === 400) {
          showAlert('error', error.response.data.message)
        } else {
          showAlert('error', 'Failed to import, please try again later')
        }
      }
    },
    [getContactsCallback, showAlert]
  )

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Frequent Contacts</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Clinic Frequent Contacts</span>
        </h3>

        <div className='card-toolbar'>
          {/* <Link to='/clinic/add-frequent-contact' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Contact
          </Link> */}
          <Button className='d-flex align-items-center' onClick={() => setShowImportModal(true)}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            <span>Import Contacts CSV</span>
          </Button>
        </div>
      </div>

      <div className='card-body py-3'>
        <div className='d-flex justify-content-between'>
          <form className='position-relative mb-4' autoComplete='off' style={{maxWidth: 400}}>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute mt-3 ms-5'
            />

            <input
              className='form-control form-control-solid ps-15 mb-3'
              placeholder='Search contact name...'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setCurrentPage(1)
              }}
            />
          </form>
        </div>

        <div className='table-responsive mb-5'>
          <table className='table align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted border border-secondary-subtle'>
                {/* <th>Actions</th> */}
                <th>Practice Name</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Specialty</th>
                <th>IPA</th>
                <th>Phone</th>
                <th>Fax</th>
                <th>Primary Address</th>
                <th>City</th>
                <th>ST</th>
                <th>Zip</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold border border-secondary-subtle'>
              {contacts.map((item: any) => (
                <tr key={item.id}>
                  {/* <td>
                    <PatientActionsCell
                      patient={item}
                      getPatientsCallback={getPatientsCallback}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </td> */}
                  <td className='ps-3'>{item.practiceName}</td>
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.specialty}</td>
                  <td>{item.IPA}</td>
                  <td>{item.phone}</td>
                  <td>{item.fax}</td>
                  <td>{item.address}</td>
                  <td>{item.city}</td>
                  <td>{item.ST}</td>
                  <td>{item.zip}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {contacts.length ? (
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {pageNumbers.map((pageNumber, index) => {
              const isEllipsis = pageNumber === '...'

              return isEllipsis ? (
                <Pagination.Ellipsis key={index} disabled />
              ) : (
                <Pagination.Item
                  key={index}
                  active={pageNumber === currentPage}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              )
            })}
            <Pagination.Next
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        ) : (
          <></>
        )}

        <ImportContactsCsvModal
          show={showImportModal}
          onSubmit={(fromLine: number, selectedFile: any) =>
            handleImportCsv(fromLine, selectedFile)
          }
          onHide={() => setShowImportModal(false)}
        />
      </div>
    </div>
  )
}
