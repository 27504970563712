/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AccountNavbar} from '../../components/AccountNavbar'
import {ChangePasswordForm} from '../../components/ChangePasswordForm'

export const ChangePassword: FC = () => {
  return (
    <>
      <div className='container-xl px-4 mt-4 account-page'>
        {/* Account page navigation*/}
        <AccountNavbar active='change_pass' />
        <hr className='mt-0 mb-4' />
        <ChangePasswordForm />
      </div>
    </>
  )
}
