import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useRef, useState} from 'react'
import {Button, Col, Form} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import {getPatientAvatarUploadConfig, setPatientAvatarUrl, updatePatientProfile} from '../../apis'
import {useCommonAlert} from '../../common/CommonAlert'
import {defaultAvatar} from '../../common/helpers'
import {useAuth} from '../../modules/auth'
import {AvatarPreviewModal} from '../AvatarPreviewModal'

export const PatientEditProfile: FC = () => {
  const {currentPatient, setCurrentPatient} = useAuth()

  const {showAlert} = useCommonAlert()
  const hiddenInputFileRef = useRef<HTMLInputElement>(null)

  const [loading, setLoading] = useState(false)
  const [avatar, setAvatar] = useState<File>()
  const [showAvatarPreviewModal, setShowAvatarPreviewModal] = useState<boolean>(false)

  const handleSelectAvatar = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0]

      if (!file.type.startsWith('image')) {
        showAlert('error', 'Avatar must be an image')
      }

      const maxFileSize = 2 * 1024 * 1024 // 2 MB

      if (file.size > maxFileSize) {
        showAlert('error', 'File size must be smaller than 2MB')
      }

      setAvatar(e.target.files[0])
      setShowAvatarPreviewModal(true)
    }
  }

  const handleUpdateAvatar = async () => {
    try {
      if (avatar) {
        const data = await getPatientAvatarUploadConfig({
          fileName: avatar.name,
          fileMimeType: avatar.type,
        })

        const {uploadConfig, bucket, objKey} = data

        const formData = new FormData()

        Object.entries(uploadConfig.fields).forEach(([key, value]) => {
          formData.append(key, value as string)
        })
        formData.append('file', avatar)

        await fetch(uploadConfig.url, {
          method: 'POST',
          body: formData,
        })

        // TODO: Upload Animation

        const avatarData = await setPatientAvatarUrl(bucket, objKey)
        setCurrentPatient({...currentPatient!, avatar: avatarData.avatar})

        showAlert('success')

        setShowAvatarPreviewModal(false)
      }
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed to send, please try again later')
    }
  }

  const {handleSubmit, getFieldProps, touched, errors, isSubmitting, isValid} = useFormik({
    initialValues: {
      email: (currentPatient?.email as string) || '',
      phone: (currentPatient?.phone as number) || 0,
      address: (currentPatient?.address as string) || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Wrong email format'),
      phone: Yup.string()
        .required('Phone is required')
        .length(10)
        .matches(/^[0-9]*$/, 'Phone contains only digits'),
      address: Yup.string(),
    }),
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)

        await updatePatientProfile(values)
        setCurrentPatient({...currentPatient!, ...values})

        showAlert('success')
      } catch (error: any) {
        if (error.response) {
          const res = error.response

          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <div className='account-page'>
      <div className='container-xl px-4 mt-4 profile-page'>
        <div className='row'>
          <div className='col-xl-3'>
            {/* Profile picture card*/}
            <div className='card mb-4 mb-xl-0'>
              <div className='card-header'>Profile Picture</div>
              <div className='card-body text-center'>
                {/* Profile picture image*/}
                <img
                  className='img-account-profile rounded-circle mb-2'
                  // TODO: CSS rounded image
                  src={currentPatient?.avatar || defaultAvatar}
                  alt='avatar'
                />
                {/* Profile picture upload button*/}
                <div>
                  <input
                    ref={hiddenInputFileRef}
                    type='file'
                    accept='image/*'
                    className='d-none'
                    onChange={(e) => handleSelectAvatar(e)}
                    onClick={(e: any) => (e.target.value = null)}
                  />
                  <button
                    className='btn btn-primary'
                    onClick={() => hiddenInputFileRef.current?.click()}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xl-9'>
            {/* Account details card*/}
            <div className='card mb-4'>
              <div className='card-header'>Account Details</div>
              <div className='card-body'>
                <Form onSubmit={handleSubmit}>
                  {/* Form Row*/}
                  <div className='row gx-3 mb-3'>
                    {/* Form Group (first name)*/}
                    <Form.Group as={Col} md='12' lg='6' className='mb-3'>
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter your first name'
                        value={currentPatient?.firstName}
                        disabled
                      />
                    </Form.Group>

                    {/* Form Group (last name)*/}
                    <Form.Group as={Col} md='12' lg='6' className='mb-3'>
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter your last name'
                        value={currentPatient?.lastName}
                        disabled
                      />
                    </Form.Group>
                  </div>

                  {/* Form Row */}
                  <div className='row gx-3 mb-3'>
                    {/* Form Group (phone)*/}
                    <Form.Group as={Col} md='12' lg='6' className='mb-3'>
                      <Form.Label>Phone number</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter your phone number'
                        autoComplete='off'
                        {...getFieldProps('phone')}
                        className={clsx({'is-invalid': touched.phone && errors.phone})}
                      />
                      {touched.phone && errors.phone && (
                        <div className='mt-3 text-danger'>{errors.phone}</div>
                      )}
                    </Form.Group>

                    {/* Form Group (email address)*/}
                    <Form.Group as={Col} md='12' lg='6' className='mb-3'>
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter your email address'
                        autoComplete='off'
                        {...getFieldProps('email')}
                        className={clsx({'is-invalid': touched.email && errors.email})}
                      />
                      {touched.email && errors.email && (
                        <div className='mt-3 text-danger'>{errors.email}</div>
                      )}
                    </Form.Group>
                  </div>

                  {/* Form Row */}
                  <div className='row gx-3 mb-3'>
                    {/* Form Group (phone)*/}
                    <Form.Group as={Col} md='12' lg='6' className='mb-3'>
                      <Form.Label>DOB</Form.Label>
                      <Form.Control
                        type='date'
                        autoComplete='off'
                        value={currentPatient?.dob}
                        disabled
                      />
                    </Form.Group>

                    {/* Form Group (email address)*/}
                    <Form.Group as={Col} md='12' lg='6' className='mb-3'>
                      <Form.Label>Address</Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter your address'
                        autoComplete='off'
                        {...getFieldProps('address')}
                        className={clsx({'is-invalid': touched.address && errors.address})}
                      />
                      {touched.address && errors.address && (
                        <div className='mt-3 text-danger'>{errors.address}</div>
                      )}
                    </Form.Group>
                  </div>

                  <div className='mt-3'>
                    {/* Save changes button*/}
                    <Button className='me-5' type='submit' disabled={isSubmitting || !isValid}>
                      {loading ? (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2' />
                        </span>
                      ) : (
                        'Save Changes'
                      )}
                    </Button>

                    <Link to={`/`} className='btn btn-secondary'>
                      Cancel
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AvatarPreviewModal
        show={showAvatarPreviewModal}
        fileMimeType={avatar?.type}
        fileName={avatar?.name}
        onHide={() => setShowAvatarPreviewModal(false)}
        onSubmit={() => handleUpdateAvatar()}
        tempUrl={avatar ? URL.createObjectURL(avatar) : ''}
      />
    </div>
  )
}
