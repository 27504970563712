import {axiosInstace} from '../common/axiosInstace'

export const getPaginatedOutgoingFaxesByClinic = async (
  page: number = 1,
  limit: number = 20,
  search: string = ''
) => {
  const res = await axiosInstace.get('/outgoing-faxes/get-by-clinic', {
    params: {
      page,
      limit,
      tags: [],
      search,
    },
  })
  return res.data
}

export const getListOutgoingFaxesByClinic = async (search: string = '') => {
  const res = await axiosInstace.get('/outgoing-faxes/get-list-receiver-num-by-clinic', {
    params: {
      search,
    },
  })
  return res.data
}

export const getBlobOutgoingFaxFile = async (id: string) => {
  return await axiosInstace.get('/outgoing-faxes/get-fax', {
    params: {
      id,
    },
    responseType: 'blob',
  })
}

export const getArrayBufferOutgoingFaxFile = async (id: string) => {
  return await axiosInstace.get('/outgoing-faxes/get-fax', {
    params: {
      id,
    },
    responseType: 'arraybuffer',
  })
}

export const getOutgoingFaxInfo = async (id: string) => {
  const res = await axiosInstace.get(`/outgoing-faxes/${id}`)
  return res.data
}

export const deleteOutgoingFax = async (id: string) => {
  return await axiosInstace.delete(`/outgoing-faxes/${id}`)
}

export const updateOutgoingFaxName = async (id: string, name: string) => {
  const res = await axiosInstace.put(`/outgoing-faxes/${id}/name`, {
    name,
  })
  return res.data
}
