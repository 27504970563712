import {axiosInstace} from '../common/axiosInstace'

export const removeTag = async (tagId: number) => {
  return await axiosInstace.delete(`/tags/${tagId}`)
}

export const getTagsByClinic = async () => {
  const res = await axiosInstace.get('/tags/get-by-clinic')
  return res.data
}

export const createTag = async (name: string) => {
  return await axiosInstace.post(`/tags`, {name})
}

export const updateTag = async (body: {id: number; name: string}) => {
  return await axiosInstace.put(`/tags/${body.id}`, {name: body.name})
}
