import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import Select from 'react-select'
import * as Yup from 'yup'
import {getAllOrganizations, getClinicById, updateClinic} from '../apis'
import {useCommonAlert} from '../common/CommonAlert'
import {Link, useNavigate, useParams} from 'react-router-dom'

const editClinicSchema = Yup.object().shape({
  name: Yup.string().required('Clinic name is required'),
  email: Yup.string().email('Wrong email format').required('Clinic email is required'),
  phone: Yup.string().required('Clinic phone is required'),
  organizations: Yup.array(),
  address: Yup.string().required('Clinic address line is required'),
  address1: Yup.string(),
})

export const EditClinicForm: FC = () => {
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()
  const {id} = useParams()

  const [organizationsOpts, setOrganizationsOpts] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [clinic, setClinic] = useState<any>({})

  const getDataCallback = useCallback(async () => {
    try {
      if (!id) return navigate('/clinics/list', {state: {showNotFoundNoti: true}})

      const data = await getAllOrganizations()
      setOrganizationsOpts(
        data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          }
        })
      )

      const clinicData = await getClinicById(id)
      const clinicOrgs = clinicData.organizations.map((item: any) => {
        return {
          value: item.id,
          label: item.name,
        }
      })
      setClinic({...clinicData, organizations: clinicOrgs})
    } catch (error) {
      return navigate('/clinics/list', {state: {showNotFoundNoti: true}})
    }
  }, [id, navigate])

  useEffect(() => {
    getDataCallback()
  }, [getDataCallback])

  const formik = useFormik({
    initialValues: {
      name: (clinic.name as string) || '',
      email: (clinic.email as string) || '',
      phone: (clinic.phone as string) || '',
      organizations: clinic.organizations || [],
      address: (clinic.address as string) || '',
      address1: (clinic.address1 as string) || '',
    },
    validationSchema: editClinicSchema,
    onSubmit: async (values, {setSubmitting}) => {
      if (!id) return navigate('/clinics/list', {state: {showNotFoundNoti: true}})
      setLoading(true)
      try {
        setLoading(false)
        await updateClinic(id, {
          ...values,
          organizations: values.organizations.map((item: any) => item.value),
        })
        return navigate('/clinics/list', {state: {showSavedNoti: true}})
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Edit Clinic</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          {/* Clinic detail */}
          <h4 className='card-title mb-3'>Clinic Detail</h4>
          <Row>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicNameInput'>
              <Form.Label>Clinic Name</Form.Label>
              <Form.Control
                placeholder='Clinic Name'
                {...formik.getFieldProps('name')}
                value={formik.values.name}
                className={clsx({'is-invalid': formik.touched.name && formik.errors.name})}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicEmailInput'>
              <Form.Label>Clinic Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Clinic Email'
                {...formik.getFieldProps('email')}
                className={clsx({'is-invalid': formik.touched.email && formik.errors.email})}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicPhoneInput'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder='Phone Number'
                {...formik.getFieldProps('phone')}
                className={clsx({'is-invalid': formik.touched.phone && formik.errors.phone})}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3'>
              <Form.Label>Select Organizations</Form.Label>
              <Select
                isMulti={true}
                options={organizationsOpts}
                placeholder='Select Organizations'
                value={formik.values.organizations}
                onChange={(values) => {
                  formik.setFieldValue('organizations', values)
                }}
              />
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicAddr1Input'>
              <Form.Label>Clinic Address Line 1</Form.Label>
              <Form.Control
                placeholder='Clinic Address Line 1'
                {...formik.getFieldProps('address')}
                className={clsx({'is-invalid': formik.touched.address && formik.errors.address})}
              />
              {formik.touched.address && formik.errors.address && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicAddr2Input'>
              <Form.Label>
                Clinic Address Line 2 <span className='text-muted'>(Optional)</span>
              </Form.Label>
              <Form.Control
                placeholder='Clinic Address Line 2'
                {...formik.getFieldProps('address1')}
                className={clsx({'is-invalid': formik.touched.address1 && formik.errors.address1})}
              />
              {formik.touched.address1 && formik.errors.address1 && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address1}</div>
                </div>
              )}
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end py-6 px-9'>
          <Link to='/clinics/list' className='btn btn-secondary me-5'>
            Cancel
          </Link>

          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}
