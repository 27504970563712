/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import {PatientDelegates} from '../../components/patient/PatientDelegates'

export const PatientDelegatesWrapper: FC = () => {
  const {currentPatient} = useAuth()

  return (
    <>
      <PageTitle breadcrumbs={[]}>Manage Delegates</PageTitle>
      {currentPatient?.id === currentPatient?.currentProfileId ? (
        <PatientDelegates />
      ) : (
        <h3 className='text-info'>You need to login as a patient to manage delegates</h3>
      )}
    </>
  )
}
