import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {OrganizationList} from '../../components/OrganizationList'

export const OrganizationListWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={[]}>Organization List</PageTitle>
    <OrganizationList />
  </>
)
