import {FC, useCallback, useEffect, useState} from 'react'
import {Pagination} from 'react-bootstrap'
import {getPageNumbersToShow} from '../../../common/helpers'
import {getPaginatedIhealthPatientsByClinic} from '../../../apis'
import {KTSVG, useDebounce} from '../../../../_metronic/helpers'

export const ClinicIhealthPatientList: FC = () => {
  const [patients, setPatients] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageNumbers, setPageNumbers] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)
  const [search, setSearch] = useState<string>('')

  const debounceSearch = useDebounce(search, 300)

  const getPatientsCallback = useCallback(async () => {
    try {
      const data = await getPaginatedIhealthPatientsByClinic(debounceSearch, currentPage)
      setPatients(data.data)
      setTotalPages(data.pagination.totalPages)
      setPageNumbers(getPageNumbersToShow(currentPage, data.pagination.totalPages))
    } catch (error) {
      console.log(error)
    }
  }, [currentPage, debounceSearch])

  useEffect(() => {
    getPatientsCallback()
  }, [getPatientsCallback])

  return (
    <div className='card'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Remote Patient Monitoring</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <form className='position-relative mb-4' autoComplete='off' style={{maxWidth: 400}}>
          <KTSVG
            path='/media/icons/duotune/general/gen021.svg'
            className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
          />

          <input
            className='form-control form-control-solid ps-15'
            placeholder='Search patient info...'
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              setCurrentPage(1)
            }}
          />
        </form>

        {/* begin::Table container */}
        <div className='table-responsive mb-5'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th>Full Name</th>
                <th>Phone</th>
                <th>DOB</th>
                <th>Ihealth Info</th>
                <th>Ihealth Weight</th>
                <th>Ihealth Blood Oxygen</th>
                <th>Ihealth Blood Pressure</th>
                <th>Ihealth Glucose</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {patients.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.fullName}</td>
                  <td>{item.phone}</td>
                  <td className='text-nowrap'>{item.dob}</td>
                  <td>
                    <ul>
                      {item.ihealth.userinfo &&
                        Object.entries(item.ihealth.userinfo).map(([key, value]: any) => (
                          <li key={key}>
                            <strong className='text-capitalize'>{key}: </strong>
                            {value}
                          </li>
                        ))}
                    </ul>
                  </td>
                  <td>
                    {item.ihealth.weight.totalCount.map((item: any) => (
                      <p className='mb-2' key={item.month}>
                        <strong>
                          Total records in {item.month}: {item.count} in {item.dayCount} days
                        </strong>
                      </p>
                    ))}
                    {item.ihealth.weight && Boolean(item.ihealth.weight.dataList.length) && (
                      <>
                        <p className='mb-2'>
                          <strong>Last 5 records:</strong>
                        </p>
                        <ul>
                          {item.ihealth.weight.dataList.map((item: any) => (
                            <li key={item.id}>
                              {item.time}
                              <ul>
                                <li>BMI: {item.BMI}</li>
                                <li>Weight: {item.weight}</li>
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </td>
                  <td>
                    {item.ihealth.spo2.totalCount.map((item: any) => (
                      <p className='mb-2' key={item.month}>
                        <strong>
                          Total records in {item.month}: {item.count} in {item.dayCount} days
                        </strong>
                      </p>
                    ))}
                    {item.ihealth.spo2 && Boolean(item.ihealth.spo2.dataList.length) && (
                      <>
                        <p className='mb-2'>
                          <strong>Last 5 records:</strong>
                        </p>
                        <ul>
                          {item.ihealth.spo2.dataList.map((item: any) => (
                            <li key={item.id}>
                              {item.time}
                              <ul>
                                <li>SpO2: {item.SpO2}</li>
                                <li>HR: {item.HR}</li>
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </td>
                  <td>
                    {item.ihealth.bp.totalCount.map((item: any) => (
                      <p className='mb-2' key={item.month}>
                        <strong>
                          Total records in {item.month}: {item.count} in {item.dayCount} days
                        </strong>
                      </p>
                    ))}
                    {Boolean(item.ihealth.bp.dataList.length) && (
                      <>
                        <p className='mb-2'>
                          <strong>Last 5 records:</strong>
                        </p>
                        <ul>
                          {item.ihealth.bp.dataList.map((item: any) => (
                            <li key={item.id}>
                              {item.time}
                              <ul>
                                <li>BPL: {item.BPL}</li>
                                <li>HP: {item.HP}</li>
                                <li>LP: {item.LP}</li>
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </td>
                  <td>
                    {item.ihealth.glucose.totalCount.map((item: any) => (
                      <p className='mb-2' key={item.month}>
                        <strong>
                          Total records in {item.month}: {item.count} in {item.dayCount} days
                        </strong>
                      </p>
                    ))}
                    {Boolean(item.ihealth.glucose.dataList.length) && (
                      <>
                        <p className='mb-2'>
                          <strong>Last 5 records:</strong>
                        </p>
                        <ul>
                          {item.ihealth.glucose.dataList.map((item: any) => (
                            <li key={item.id}>
                              {item.time}
                              <ul>
                                <li>BG: {item.BG}</li>
                              </ul>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* end::Table container */}

        {patients.length ? (
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {pageNumbers.map((pageNumber, index) => {
              const isEllipsis = pageNumber === '...'

              return isEllipsis ? (
                <Pagination.Ellipsis key={index} disabled />
              ) : (
                <Pagination.Item
                  key={index}
                  active={pageNumber === currentPage}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              )
            })}
            <Pagination.Next
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        ) : (
          <></>
        )}
      </div>
      {/* begin::Body */}
    </div>
  )
}
