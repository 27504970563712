import {Button, Modal} from 'react-bootstrap'

interface Props {
  show: boolean
  onClose: () => void
}

export const TermModal = (props: Props) => (
  <Modal size='lg' centered show={props.show} onHide={props.onClose}>
    <Modal.Header closeButton>
      <Modal.Title id='contained-modal-title-vcenter'>Terms and Conditions</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h5>Consent for Electronic Communications & Disclosure of Protected Health Information</h5>

      <h5>Purpose:</h5>
      <p>
        I grant permission to DocTalkLive to utilize and share my protected health information (PHI)
        for the purpose of providing medical treatment, securing payment, and conducting
        healthcare-related operations. This encompasses tasks such as sending appointment reminders,
        relaying test results, notifying about prescriptions, issuing invoices, and other
        communications pertinent to my healthcare.
      </p>

      <h5>Access:</h5>
      <p>
        I acknowledge my right to access my medical records and can request copies of my PHI by
        reaching out to DocTalkLive
      </p>

      <h5>Restrictions:</h5>
      <p>
        I understand that I am entitled to request additional limitations on the usage and
        disclosure of my PHI for treatment, payment, and operations. These requests must be made in
        writing.
      </p>

      <h5>Privacy Policy:</h5>
      <p>This consent aligns with the Privacy Policy. </p>

      <h5>Revocation:</h5>
      <p>
        I maintain the authority to withdraw this consent at any time by submitting a written
        request for revocation. A notice period of 30 days will be required for the revocation to
        take effect.
      </p>

      <h5>Communication:</h5>
      <p>
        I possess the right to request alternative methods or locations for the communication of my
        PHI. Changes to my contact information can be made by notifying DocTalkLive in writing.
      </p>

      <h5>Safeguards:</h5>
      <p>
        DocTalkLive employs administrative, technical, and physical safeguards to ensure the
        confidentiality and security of my PHI.
      </p>

      <h5>Breach Notification:</h5>
      <p>
        In accordance with HIPAA regulations, I will be promptly notified in the event of any breach
        involving unsecured PHI.
      </p>

      <h5>Third-Party Services:</h5>
      <p>
        I acknowledge that DocTalkLive may engage third-party service providers to assist in
        managing electronic communications and secure storage of my PHI. I understand that these
        providers will be bound by confidentiality agreements.
      </p>

      <h5>Emergency Contact:</h5>
      <p>
        I authorize DocTalkLive to use my provided emergency contact information in the event of a
        medical emergency or critical health situation.
      </p>

      <h5>Authorization for Treatment Coordination:</h5>
      <p>
        I authorize DocTalkLive to share my PHI with other healthcare providers involved in my
        treatment coordination, ensuring seamless and comprehensive care.
      </p>

      <h5>Research and Surveys:</h5>
      <p>
        I understand that DocTalkLive may request my participation in health-related research
        studies or surveys. Any PHI shared in these contexts will be anonymized and protected.
      </p>

      <h5>Authorization for Family Members:</h5>
      <p>
        I may designate specific family members or caregivers to access my PHI through the patient
        portal. I understand that I am solely responsible for providing and updating their access
        permissions.
      </p>

      <h5>Marketing and Communication Opt-Out:</h5>
      <p>
        I understand that DocTalkLive may send me non-essential communications such as promotional
        offers or newsletters. I have the option to opt out of receiving such communications.
      </p>

      <h5>Minors and Guardians:</h5>
      <p>
        If the patient is a minor, I, as the legal guardian, consent to the use and disclosure of
        the minor's PHI as described in this form.
      </p>

      <h5>Patient Responsibilities:</h5>
      <p>
        I acknowledge that it is my responsibility to promptly update my contact information and
        communication preferences with DocTalkLive to ensure accurate delivery of communications.
      </p>

      <h5>Record Retention Period:</h5>
      <p>
        I understand that DocTalkLive will retain my medical records and related communications for
        the legally required duration, even after my consent is revoked.
      </p>

      <h5>Future Communication Methods:</h5>
      <p>
        I understand that DocTalkLive may adopt new communication methods or technologies in the
        future, and my consent will extend to these methods as well.
      </p>

      <h5>Language and Format of Communications:</h5>
      <p>
        I acknowledge that communications from DocTalkLive may be in electronic format and may
        include text messages, emails, or secure portal messages.
      </p>

      <h5>Right to File Complaints:</h5>
      <p>
        I am aware that I have the right to file complaints regarding the use or disclosure of my
        PHI with the appropriate regulatory authorities.
      </p>

      <h5>Retention of Consent:</h5>
      <p>I understand that my consent will be retained by DocTalkLive. </p>

      <h5>SMS Fees:</h5>
      <p>
        I consent to potential fees associated with SMS text messages from DocTalkLive. This may
        include text message rates applied by my wireless carrier.
      </p>

      <p>
        By providing my full name, date of birth, checking the box below, and entering the one-time
        password sent to my mobile phone, I confirm that I have read, understood, and willingly
        agree to the terms and conditions governing the usage of DocTalkLive online patient portal
        and its related services.
      </p>

      <p>
        I agree with the utilization of my personal details for verification purposes, granting
        access to the patient portal and its services. I comprehend that this will enable me to view
        protected health information (PHI) related to medical services provided by DocTalkLive.{' '}
      </p>

      <p>
        I commit to keeping my login credentials confidential and not sharing them with others.
        Additionally, I acknowledge my responsibility for any fees linked to SMS, data usage, or
        other transmissions linked to my usage of the patient portal.
      </p>

      <p>
        By providing the requested information below and entering my one-time password, I confirm my
        legal acceptance of the disclaimer and consent terms detailed above.
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={props.onClose}>Close</Button>
    </Modal.Footer>
  </Modal>
)
