import {FC, useCallback, useEffect, useState} from 'react'
import {Navigate, useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getStaffClinicInfo} from '../../../apis'
import {FaxRecipientList} from '../../../components/staff/fax-recipient/FaxRecipientList'
import {useAuth} from '../../../modules/auth'

export const FaxRecipientListPageWrapper: FC = () => {
  const navigate = useNavigate()
  const {currentUser} = useAuth()

  const [clinic, setClinic] = useState<any>({})

  const getClinicCallback = useCallback(async () => {
    try {
      const clinicData = await getStaffClinicInfo()
      setClinic(clinicData)
    } catch (error) {
      return navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    getClinicCallback()
  }, [getClinicCallback])

  if (!currentUser?.isClinicAdmin) {
    return <Navigate to={'/'} />
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>{clinic.name || 'Staff'}</PageTitle>
      <FaxRecipientList />
    </>
  )
}
