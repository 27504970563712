import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import {useCommonAlert} from '../../../common/CommonAlert'
import {addSubClinic} from '../../../apis'

const addSubClinicSchema = Yup.object().shape({
  name: Yup.string().required('Clinic name is required'),
  email: Yup.string().email('Wrong email format').required('Clinic email is required'),
  phone: Yup.string().required('Clinic phone is required'),
  address: Yup.string().required('Clinic address line is required'),
  adminFirstName: Yup.string().required('Admin first name is required'),
  adminLastName: Yup.string().required('Admin last name is required'),
  adminEmail: Yup.string().email('Wrong email format').required('Admin email is required'),
  adminPhone: Yup.string().required('Admin phone is required'),
  adminPassword: Yup.string()
    .required('Admin password is required')
    .min(8, 'Minimum 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      'Must have at least 1 uppercase, 1 lowercase and 1 number'
    ),
  passwordConfirm: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('adminPassword'), null], 'Passwords must match'),
})

const initialValues = {
  name: '',
  email: '',
  phone: '',
  address: '',
  adminFirstName: '',
  adminLastName: '',
  adminEmail: '',
  adminPhone: '',
  adminPassword: '',
  passwordConfirm: '',
}

export const AddSubClinicForm: FC = () => {
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: addSubClinicSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        await addSubClinic(values)
        return navigate('/clinic/sub-clinics', {state: {showAddedNoti: true}})
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Add Clinic</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          {/* Clinic detail */}
          <h4 className='card-title mb-3'>Clinic Detail</h4>
          <Row>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicNameInput'>
              <Form.Label>Clinic Name</Form.Label>
              <Form.Control
                placeholder='Clinic Name'
                {...formik.getFieldProps('name')}
                className={clsx({'is-invalid': formik.touched.name && formik.errors.name})}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicEmailInput'>
              <Form.Label>Clinic Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Clinic Email'
                {...formik.getFieldProps('email')}
                className={clsx({'is-invalid': formik.touched.email && formik.errors.email})}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicPhoneInput'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder='Phone Number'
                {...formik.getFieldProps('phone')}
                className={clsx({'is-invalid': formik.touched.phone && formik.errors.phone})}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicAddr1Input'>
              <Form.Label>Clinic Address</Form.Label>
              <Form.Control
                placeholder='Clinic Address'
                {...formik.getFieldProps('address')}
                className={clsx({'is-invalid': formik.touched.address && formik.errors.address})}
              />
              {formik.touched.address && formik.errors.address && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address}</div>
                </div>
              )}
            </Form.Group>
          </Row>

          {/* Clinic admin */}
          <h4 className='card-title my-3'>Clinical Admin Detail</h4>
          <Row>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='adminFNameInput'>
              <Form.Label>Admin First Name</Form.Label>
              <Form.Control
                placeholder='Admin First Name'
                {...formik.getFieldProps('adminFirstName')}
                className={clsx({
                  'is-invalid': formik.touched.adminFirstName && formik.errors.adminFirstName,
                })}
              />
              {formik.touched.adminFirstName && formik.errors.adminFirstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.adminFirstName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='adminLNameInput'>
              <Form.Label>Admin Last Name</Form.Label>
              <Form.Control
                placeholder='Admin Last Name'
                {...formik.getFieldProps('adminLastName')}
                className={clsx({
                  'is-invalid': formik.touched.adminLastName && formik.errors.adminLastName,
                })}
              />
              {formik.touched.adminLastName && formik.errors.adminLastName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.adminLastName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='adminEmailInput'>
              <Form.Label>Admin Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Admin Email'
                {...formik.getFieldProps('adminEmail')}
                className={clsx({
                  'is-invalid': formik.touched.adminEmail && formik.errors.adminEmail,
                })}
              />
              {formik.touched.adminEmail && formik.errors.adminEmail && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.adminEmail}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='adminPhoneInput'>
              <Form.Label>Admin Phone Number</Form.Label>
              <Form.Control
                placeholder='Admin Phone Number'
                {...formik.getFieldProps('adminPhone')}
                autoComplete='off'
                className={clsx({
                  'is-invalid': formik.touched.adminPhone && formik.errors.adminPhone,
                })}
              />
              {formik.touched.adminPhone && formik.errors.adminPhone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.adminPhone}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='adminPassInput'>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Password'
                {...formik.getFieldProps('adminPassword')}
                autoComplete='new-password'
                className={clsx({
                  'is-invalid': formik.touched.adminPassword && formik.errors.adminPassword,
                })}
              />
              {formik.touched.adminPassword && formik.errors.adminPassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.adminPassword}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='adminPassConfirmInput'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm Password'
                {...formik.getFieldProps('passwordConfirm')}
                autoComplete='new-password'
                className={clsx({
                  'is-invalid': formik.touched.passwordConfirm && formik.errors.passwordConfirm,
                })}
              />
              {formik.touched.passwordConfirm && formik.errors.passwordConfirm && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.passwordConfirm}</div>
                </div>
              )}
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end py-6 px-9'>
          <Link to='/clinic/sub-clinics' className='btn btn-secondary me-5'>
            Cancel
          </Link>

          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}
