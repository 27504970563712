import {axiosInstace} from '../common/axiosInstace'

export const ihealthGetAllToken = async (code: string) => {
  const res = await axiosInstace.get('/ihealth/all-token', {
    params: {code},
  })
  return res.data
}

export const ihealthGetUserinfo = async () => {
  const res = await axiosInstace.get('/ihealth/userinfo')
  return res.data
}

export const ihealthGetWeight = async () => {
  const res = await axiosInstace.get('/ihealth/weight')
  return res.data
}

export const ihealthGetSpO2 = async () => {
  const res = await axiosInstace.get('/ihealth/spo2')
  return res.data
}

export const ihealthGetBp = async () => {
  const res = await axiosInstace.get('/ihealth/bp')
  return res.data
}

export const ihealthGetGlucose = async () => {
  const res = await axiosInstace.get('/ihealth/glucose')
  return res.data
}

export const ihealthUnbinding = async () => {
  const res = await axiosInstace.post('/ihealth/unbinding')
  return res.data
}
