import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {EditClinicForm} from '../../components/EditClinicForm'

const editClinicBreadCrumbs: Array<PageLink> = [
  {
    title: 'Clinics',
    path: '/clinics/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const EditClinicPageWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={editClinicBreadCrumbs}>Edit Clinic</PageTitle>
    <EditClinicForm />
  </>
)
