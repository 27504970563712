import {FC, createContext, useContext} from 'react'
import {io, Socket} from 'socket.io-client'
import {WithChildren} from '../../../../../_metronic/helpers'

type SocketContextProps = {
  socket: Socket
  twilioSocket: Socket
}

const SOCKET_URL =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:3333'

const initSocketContextPropsState: SocketContextProps = {
  socket: io(`${SOCKET_URL}`, {
    withCredentials: true,
    autoConnect: false,
    transports: ['websocket'],
  }),
  twilioSocket: io(`${SOCKET_URL}/twilio`, {
    withCredentials: true,
    autoConnect: false,
    transports: ['websocket'],
  }),
}

const SocketContext = createContext<SocketContextProps>(initSocketContextPropsState)

const useSocket = () => {
  return useContext(SocketContext)
}

const SocketProvider: FC<WithChildren> = ({children}) => {
  return (
    <SocketContext.Provider value={initSocketContextPropsState}>{children}</SocketContext.Provider>
  )
}

export {SocketProvider, useSocket}
