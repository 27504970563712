import clsx from 'clsx'
import {defaultAvatar} from '../../../../app/common/helpers'
import {useAuth} from '../../../../app/modules/auth'
import {HeaderUserMenu} from '../../../partials'

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'

const Navbar = () => {
  const {currentUser, currentPatient} = useAuth()

  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol symbol-circle', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={currentUser?.avatar || currentPatient?.avatar || defaultAvatar} alt='avatar' />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}
