import {Button, Image, Modal} from 'react-bootstrap'

interface Props {
  show: boolean
  fileName?: string
  tempUrl: string
  fileMimeType?: string
  onSubmit: () => void
  onHide: () => void
}

export const MediaPreviewModal: React.FC<Props> = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Send Media</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex'>
          <h5>File Name:</h5>
          <p className='ms-1'>{props.fileName}</p>
        </div>

        {props.fileMimeType && props.fileMimeType.startsWith('image') ? (
          <Image fluid src={props.tempUrl} />
        ) : (
          <video style={{width: '100%'}} controls>
            <source src={props.tempUrl} />
          </video>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onSubmit()}>Send</Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
