import {FC, useCallback, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../_metronic/helpers'
import {deleteOrganization, getAllOrganizations} from '../apis'
import {useCommonAlert} from '../common/CommonAlert'
import {OrganizationActionsCell} from './OrganizationActionsCell'

export const OrganizationList: FC = () => {
  const {showAlert} = useCommonAlert()
  const location = useLocation()
  const navigate = useNavigate()

  const [organizations, setOrganizations] = useState<any[]>([])

  const getDataCallback = useCallback(async () => {
    try {
      const data = await getAllOrganizations()
      setOrganizations(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getDataCallback()
  }, [getDataCallback])

  useEffect(() => {
    const state: any = location.state
    if (state) {
      if (state.showAddedNoti) {
        showAlert('success', 'Organization added successfully')
      } else if (state.showNotFoundNoti) {
        showAlert('error', 'Organization not found')
      } else if (state.showSavedNoti) {
        showAlert('success')
      }
      navigate('', {state: null, replace: true})
    }
  }, []) //eslint-disable-line

  const handleOrgnDelete = async (orgnId: number) => {
    try {
      await deleteOrganization(orgnId)
      setOrganizations(organizations.filter((item) => item.id !== orgnId))
      showAlert('success', 'Deleted successfully')
    } catch (err) {
      console.log(err)
      showAlert('error', 'Failed to delete')
    }
  }

  return (
    <div className='card'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Organization List</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Show all organizations</span>
        </h3>
        <div className='card-toolbar'>
          <Link to={'/organizations/add'} className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add organization
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-80px'>Organization Id</th>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-150px'>Description</th>
                <th className='min-w-120px'>Clinics</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='text-gray-600 fw-bold'>
              {organizations.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>{item.clinicCount}</td>
                  <td className='text-end'>
                    <OrganizationActionsCell id={item.id} onDelete={(id) => handleOrgnDelete(id)} />
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
