import {fabric} from 'fabric'
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import {Button, Card, FloatingLabel, Form, Modal, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {Document, Page, pdfjs} from 'react-pdf'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {API_URL} from '../../common/helpers'
import * as pdflib from 'pdf-lib'
import {
  deleteFax,
  getArrayBufferFaxFile,
  getFaxInfo,
  getNextFaxId,
  getPreviousFaxId,
  getUserSignature,
  sendFax,
  sendFaxManually,
  updateFaxNotes,
  updateFaxTags,
} from '../../apis'
import {FaxRecipientModal} from './modal/FaxRecipientModal'
import {useCommonAlert} from '../../common/CommonAlert'
import {ConfirmNavigateFaxModal} from './modal/ConfirmNavigateFaxModal'
import {useHotkeys} from 'react-hotkeys-hook'
import {EditTagsModal} from './modal/EditTagsModal'
import {EditFaxNameModal} from './modal/EditFaxNameModal'
import moment from 'moment'
import _ from 'lodash'
import '../../../assets/scss/fax-preview.scss'
import {useAuth} from '../../modules/auth'
import {Tag} from '../../common/types'
import {useCookies} from 'react-cookie'
import {PinCheckModal} from './modal/PinCheckModal'

interface Props {
  faxId: string
}

export const IncomingFaxPreview: FC<Props> = (props) => {
  const [cookies, setCookie] = useCookies(['pin-is-active'])

  const navigate = useNavigate()
  const location = useLocation()
  const {showAlert} = useCommonAlert()
  const {currentUser} = useAuth()

  const [pageCount, setPageCount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null)
  const [pageObjs, setPageObjs] = useState<any[]>([])
  const [edits, setEdits] = useState<any[]>([])
  const [showFaxModal, setShowFaxModal] = useState<boolean>(false)
  const [showNavigateModal, setShowNavigateModal] = useState<boolean>(false)
  const [isNext, setIsNext] = useState<boolean>(true)
  const [nextFaxId, setNextFaxId] = useState<any>('')
  const [previousFaxId, setPreviousFaxId] = useState<any>('')
  const [senderFax, setSenderFax] = useState<any>('')
  const [faxTags, setFaxTags] = useState<Tag[]>([])
  const [showEditTagsModal, setShowEditTagsModal] = useState<boolean>(false)
  const [showConfirmDelModal, setShowConfirmDelModal] = useState<boolean>(false)
  const [faxName, setFaxName] = useState<string>('')
  const [showEditFaxNameModal, setShowEditFaxNameModal] = useState<boolean>(false)
  const [showPinCheckModal, setShowPinCheckModal] = useState<boolean>(false)
  const [isHotkeyImg, setIsHotkeyImg] = useState(false)
  const [rotateAngle, setRotateAngle] = useState<number>(0)
  const [notes, setNotes] = useState<string>('')

  let mousePos = useRef({x: 0, y: 0})

  const getFaxIdsCallback = useCallback(async () => {
    const lState = location.state || {}
    try {
      const data = await getNextFaxId(props.faxId, lState)
      setNextFaxId(data)
    } catch (error) {
      setNextFaxId('')
    }

    try {
      const data = await getPreviousFaxId(props.faxId, lState)
      setPreviousFaxId(data)
    } catch (error) {
      setPreviousFaxId('')
    }
  }, [props.faxId, location.state])

  useEffect(() => {
    getFaxIdsCallback()
  }, [getFaxIdsCallback])

  const getFaxInfoCallback = useCallback(async () => {
    try {
      const data = await getFaxInfo(props.faxId)
      setSenderFax(data.callerId)
      setFaxTags(data.faxTags)
      setFaxName(data.fileName)
      setNotes(data.notes || '')
    } catch (error) {
      console.log(error)
    }
  }, [props.faxId])

  useEffect(() => {
    getFaxInfoCallback()
  }, [getFaxInfoCallback])

  useEffect(() => {
    if (canvas) {
      canvas.on(
        'mouse:move',
        _.debounce((e) => {
          mousePos.current = {...e.pointer}
        }, 200)
      )

      canvas.on(
        'mouse:wheel',
        _.debounce((e) => {
          mousePos.current = {...e.pointer}
        }, 200)
      )
    }
  }, [canvas])

  const imgInputRef = useRef<HTMLInputElement>(null)

  const initCanvas = (width: number, height: number) => {
    return new fabric.Canvas('canvas-main', {
      isDrawingMode: false,
      width: width,
      height: height,
      backgroundColor: 'rgba(0,0,0,0)',
    })
  }

  const deleteObj = () => {
    const activeObjs = canvas!.getActiveObjects()
    activeObjs.forEach((obj) => {
      canvas!.remove(obj)
    })
  }

  const addText = (isHotkey: boolean) => {
    let options: fabric.ITextboxOptions = {
      fontSize: 16,
      editable: true,
      lockScalingY: true,
      lockRotation: true,
    }
    if (isHotkey) {
      const compareHeight = canvas!.height ? canvas!.height - 50 : 0
      const compareWidth = canvas!.width ? canvas!.width - 50 : 0
      const currentPointer = {x: mousePos.current.x || 0, y: mousePos.current.y || 0}

      options.top = currentPointer.y < compareHeight ? currentPointer.y : compareHeight
      options.left = currentPointer.x < compareWidth ? currentPointer.x : compareWidth
    }
    const text = new fabric.Textbox('Type here', options)
    text.setControlsVisibility({
      mt: false,
      tl: false,
      tr: false,
      mb: false,
      bl: false,
      br: false,
      mtr: false,
    })
    canvas!.add(text).renderAll()
    canvas!.setActiveObject(text)
  }

  const addImage = (e: any) => {
    if (e.target?.files?.length) {
      var file = e.target.files[0]
      var reader = new FileReader()
      reader.onload = function (f: any) {
        var data = f.target.result
        fabric.Image.fromURL(data, function (img) {
          img.scaleToWidth(200)
          if (isHotkeyImg) {
            const imgHeight = img.height! * img.scaleX! || 100
            const compareHeight = canvas!.height ? canvas!.height - imgHeight - 40 : 0
            const compareWidth = canvas!.width ? canvas!.width - 220 : 0
            const currentPointer = {x: mousePos.current.x || 0, y: mousePos.current.y || 0}

            img.top = currentPointer.y < compareHeight ? currentPointer.y : compareHeight
            img.left = currentPointer.x < compareWidth ? currentPointer.x : compareWidth
            setIsHotkeyImg(false)
          }
          img.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            mtr: false,
          })
          img.lockScalingFlip = true
          canvas!.add(img).renderAll()
          canvas!.setActiveObject(img)
          const text = new fabric.Textbox(moment().format('MM/DD/YYYY'), {
            top: img.top! + img.height! * img.scaleY! + 5 || 0,
            left: img.left || 0,
            fontSize: 16,
            editable: true,
            lockScalingY: true,
            lockRotation: true,
          })
          text.setControlsVisibility({
            mt: false,
            tl: false,
            tr: false,
            mb: false,
            bl: false,
            br: false,
            mtr: false,
          })
          canvas!.add(text).renderAll()
        })
      }
      reader.readAsDataURL(file)
    }
    e.target.value = null
  }

  const addSignature = (isHotKey = false) => {
    if (!currentUser?.signature) {
      showAlert('error', 'You need to set your signature in edit profile page first')
      return false
    }
    if (!currentUser?.hasPin) {
      showAlert('error', 'You need to setup your pin code in edit profile page first')
      return false
    } else if (!cookies['pin-is-active']) {
      setShowPinCheckModal(true)
      return false
    }
    fabric.Image.fromURL(currentUser.signature, function (img) {
      img.scaleToWidth(200)
      if (isHotKey) {
        const imgHeight = img.height! * img.scaleX! || 100
        const compareHeight = canvas!.height ? canvas!.height - imgHeight - 40 : 0
        const compareWidth = canvas!.width ? canvas!.width - 220 : 0
        const currentPointer = {x: mousePos.current.x || 0, y: mousePos.current.y || 0}

        img.top = currentPointer.y < compareHeight ? currentPointer.y : compareHeight
        img.left = currentPointer.x < compareWidth ? currentPointer.x : compareWidth
      }
      img.setControlsVisibility({
        mt: false,
        mb: false,
        ml: false,
        mr: false,
        mtr: false,
      })
      img.lockScalingFlip = true
      canvas!.add(img).renderAll()
      canvas!.setActiveObject(img)
      const text = new fabric.Textbox(moment().format('MM/DD/YYYY'), {
        top: img.top! + img.height! * img.scaleY! + 5 || 0,
        left: img.left || 0,
        fontSize: 16,
        editable: true,
        lockScalingY: true,
        lockRotation: true,
      })
      text.setControlsVisibility({
        mt: false,
        tl: false,
        tr: false,
        mb: false,
        bl: false,
        br: false,
        mtr: false,
      })
      canvas!.add(text).renderAll()
    })
  }

  // Hotkeys
  useHotkeys(['delete', 'backspace'], deleteObj)
  useHotkeys('Shift + T', () => addText(true))
  useHotkeys(['Shift + I'], () => {
    setIsHotkeyImg(true)
    imgInputRef.current?.click()
  })
  useHotkeys('Shift + S', () => {
    addSignature(true)
  })

  const onRotate = () => {
    setRotateAngle(rotateAngle === 0 ? 180 : 0)
  }

  const onChangePage = (offset: number) => {
    edits[currentPage - 1] = canvas!.toObject()
    pageObjs[currentPage - 1] = canvas!.getObjects()
    setEdits(edits)
    setPageObjs(pageObjs)
    setCurrentPage((page) => page + offset)
    canvas!.clear()
    if (edits[currentPage + offset - 1]) {
      canvas!.loadFromJSON(edits[currentPage + offset - 1], () => {})
    }
    canvas!.renderAll()
  }

  const getRotatedPdfArrayBuffer = async () => {
    if (rotateAngle) {
      const pdfArrayBuffer = (await getArrayBufferFaxFile(props.faxId)).data
      const pdf = await pdflib.PDFDocument.load(pdfArrayBuffer)

      const pagesProcesses = pdf.getPages().map(async (page, index) => {
        page.setRotation(pdflib.degrees(rotateAngle))
      })
      await Promise.all(pagesProcesses)
      const pdfBytes = await pdf.save()
      return pdfBytes.buffer
    } else {
      return (await getArrayBufferFaxFile(props.faxId)).data
    }
  }

  const savePdf = async () => {
    const pdfArrayBuffer = await getRotatedPdfArrayBuffer()
    const pdf = await pdflib.PDFDocument.load(pdfArrayBuffer)

    edits[currentPage - 1] = canvas!.toObject()
    pageObjs[currentPage - 1] = canvas!.getObjects()
    setEdits(edits)
    setPageObjs(pageObjs)

    const pagesProcesses = pdf.getPages().map(async (page, index) => {
      if (pageObjs[index]?.length) {
        const pageHeight = page.getHeight()
        for (const obj of pageObjs[index]) {
          // Textbox
          if (obj.textLines) {
            let y = pageHeight - obj.top - Math.ceil(16 * 0.84)
            for (let i = 0; i < obj.textLines.length; i++) {
              page.drawText(obj.textLines[i], {
                font: await pdf.embedFont(pdflib.StandardFonts.TimesRoman),
                x: obj.left,
                y: y,
                size: 16,
                lineHeight: 1.16,
              })
              y = y - 16 * (1.16 + 0.16)
            }
          }

          // Image
          if (obj._element?.currentSrc) {
            try {
              const imgSrc: string = obj._element.currentSrc
              let img: any
              if (imgSrc.startsWith('data:image/jpeg')) {
                img = await pdf.embedJpg(imgSrc)
              } else if (imgSrc.startsWith('data:image/png')) {
                img = await pdf.embedPng(imgSrc)
              } else if (
                imgSrc.toLowerCase().endsWith('.jpg') ||
                imgSrc.toLowerCase().endsWith('.jpeg')
              ) {
                const buffer = await getUserSignature()
                img = await pdf.embedJpg(buffer)
              } else if (imgSrc.toLowerCase().endsWith('.png')) {
                const buffer = await getUserSignature()
                img = await pdf.embedPng(buffer)
              }

              if (img) {
                page.drawImage(img, {
                  x: Math.ceil(obj.left),
                  y: Math.ceil(pageHeight - obj.top - Math.round(obj.height * obj.scaleY)),
                  width: Math.round(obj.width * obj.scaleX),
                  height: Math.round(obj.height * obj.scaleY),
                })
              }
            } catch (error) {
              console.log(error)
            }
          }
        }
      }
    })

    await Promise.all(pagesProcesses)

    const pdfBytes = await pdf.save()
    return new Blob([pdfBytes], {type: 'application/pdf'})
  }

  const onForward = async (fax: any, type: 'id' | 'number') => {
    try {
      const blob = await savePdf()
      const b: any = blob
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
      b.lastModifiedDate = new Date()
      b.name = `${props.faxId}.pdf`

      //Cast to a File() type
      const frmData = new FormData()
      frmData.append('file', b, b.name)
      if (type === 'id') {
        frmData.append('faxId', fax.toString())
        await sendFax(frmData)
      } else {
        frmData.append('faxNumber', fax.toString())
        frmData.append('faxName', faxName)
        await sendFaxManually(frmData)
      }
      const faxResponse = await updateFaxTags(props.faxId, ['fax-sent'])
      setFaxTags(faxResponse.faxTags)
      showAlert('success', 'Fax has been sent successfully')
      setShowFaxModal(false)
    } catch (error) {
      showAlert('error', 'Failed to send, please try again later')
      console.log(error)
    }
  }

  const onDownload = async () => {
    const blob = await savePdf()
    const url = URL.createObjectURL(blob)

    const a = document.createElement('a')
    a.href = url
    a.download = `${props.faxId}.pdf`
    a.style.display = 'none'

    document.body.appendChild(a)
    a.click()

    // Clean up
    document.body.removeChild(a)
    URL.revokeObjectURL(url)
  }

  const deleteFaxFile = async () => {
    try {
      await deleteFax(props.faxId)
      setShowConfirmDelModal(false)
      navigate('../', {relative: 'path', state: {showDeletedNoti: true}})
    } catch (error) {
      console.log(error)
    }
  }

  const onUpdateNotes = async () => {
    try {
      await updateFaxNotes(props.faxId, notes)
      showAlert('success')
    } catch (error) {
      console.log(error)
      showAlert('error')
    }
  }

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  })

  return (
    <Card>
      <Card.Header className='min-h-50px'>
        <Card.Title className='m-0'>
          <h3 className='fw-bolder m-0'>Fax Preview</h3>
        </Card.Title>
      </Card.Header>

      <Card.Body className='pdf-editor-main-content'>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <Button
            variant='link'
            disabled={!previousFaxId}
            onClick={() => {
              setIsNext(false)
              setShowNavigateModal(true)
            }}
          >
            Go to Previous Fax
          </Button>
          <Button
            variant='link'
            disabled={!nextFaxId}
            onClick={() => {
              setIsNext(true)
              setShowNavigateModal(true)
            }}
          >
            Go to Next Fax
          </Button>
        </div>

        <div className='d-flex align-items-center'>
          <p className='fs-3 mb-0 me-6'>
            <strong>Name: </strong>
            {faxName}
          </p>
          <button
            className='btn btn-sm btn-icon btn-outline btn-active-light-primary'
            type='button'
            onClick={() => {
              setShowEditFaxNameModal(true)
            }}
          >
            <i className='fa-solid fa-pencil' />
          </button>
        </div>

        <div className='d-flex justify-content-between align-items-center mb-6'>
          <div>
            {faxTags.length ? (
              <span>
                <strong>Tags:</strong> {faxTags.map((item) => item.name).join(', ')}
              </span>
            ) : (
              <span>
                <strong>There are no tags for this fax</strong>
              </span>
            )}
          </div>
          <Button onClick={() => setShowEditTagsModal(true)}>Edit Tags</Button>
        </div>

        <div className='pdf-editor-navbar'>
          <input
            ref={imgInputRef}
            className='d-none'
            type='file'
            accept='image/*'
            onInput={(e) => addImage(e)}
          />
          <span className='border-end border-dark'>PDF Editor</span>
          <div className='pdf-editor-label'>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>
                  Add Signature(<strong>Shift + S</strong>)
                </Tooltip>
              }
            >
              <span onClick={() => addSignature(false)}>Signature</span>
            </OverlayTrigger>
            <span onClick={onRotate}>Rotate</span>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>
                  Delete(<strong>Delete</strong>/ <strong>Backspace</strong>)
                </Tooltip>
              }
            >
              <span onClick={deleteObj}>Delete</span>
            </OverlayTrigger>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>
                  Add text(<strong>Shift + T</strong>)
                </Tooltip>
              }
            >
              <span onClick={() => addText(false)}>Text</span>
            </OverlayTrigger>
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip>
                  Add image(<strong>Shift + I</strong>)
                </Tooltip>
              }
            >
              <span onClick={() => imgInputRef.current?.click()}>Image</span>
            </OverlayTrigger>
            {/* <span className=''>Draw</span> */}
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex flex-column justify-content-center flex-grow-1 me-6'>
            <div className='p-4 border border-dark rounded mb-6' style={{maxWidth: 300}}>
              <FloatingLabel controlId='floatingTextarea2' label='Fax Notes'>
                <Form.Control
                  as='textarea'
                  style={{height: '100px'}}
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className='mb-2'
                />
              </FloatingLabel>
              <Button className='' size='sm' onClick={() => onUpdateNotes()}>
                Save Notes
              </Button>
            </div>
            <button
              className={`change-page-button align-self-start ${
                currentPage > 1 ? 'opacity-100' : 'opacity-0'
              }`}
              onClick={() => {
                if (currentPage > 1) {
                  onChangePage(-1)
                }
              }}
            >
              &lt;
            </button>
          </div>
          <Document
            file={`${API_URL}/incoming-faxes/get-fax?faxId=${props.faxId}`}
            className='position-relative'
            options={{
              withCredentials: true,
            }}
            onLoadError={(error) => console.log('Load file error', error)}
            onLoadSuccess={async (pdf) => {
              setPageCount(pdf.numPages)
              const page = await pdf.getPage(1)
              setCanvas(initCanvas(Math.floor(page.view[2]), Math.floor(page.view[3])))
            }}
          >
            <div id='canvasWrapper' style={{visibility: 'visible'}}>
              <canvas id='canvas-main' />
            </div>
            <Page
              pageNumber={currentPage}
              renderTextLayer={false}
              rotate={rotateAngle}
              className='pdf-page'
            />
          </Document>
          <div className='d-flex flex-column justify-content-center flex-grow-1'>
            <button
              className={`change-page-button align-self-end ${
                currentPage < pageCount ? 'opacity-100' : 'opacity-0'
              }`}
              onClick={() => {
                if (currentPage < pageCount) {
                  onChangePage(1)
                }
              }}
            >
              &gt;
            </button>
          </div>
        </div>
      </Card.Body>

      <FaxRecipientModal
        onHide={() => setShowFaxModal(false)}
        initFaxNumber={senderFax}
        onSubmit={(fax, type) => onForward(fax, type)}
        show={showFaxModal}
      />

      <ConfirmNavigateFaxModal
        onConfirm={() => {
          navigate(`../${isNext ? nextFaxId : previousFaxId || ''}`, {
            relative: 'path',
            state: location.state,
          })
        }}
        isNext={isNext}
        show={showNavigateModal}
        onCancel={() => setShowNavigateModal(false)}
      />

      <EditTagsModal
        onCancel={() => setShowEditTagsModal(false)}
        faxId={props.faxId}
        tags={faxTags}
        onSubmit={(_, tags) => {
          setShowEditTagsModal(false)
          setFaxTags(tags)
        }}
        show={showEditTagsModal}
      />

      <EditFaxNameModal
        onHide={() => setShowEditFaxNameModal(false)}
        faxId={props.faxId}
        initFaxName={faxName}
        onSubmit={(_, name) => {
          setShowEditFaxNameModal(false)
          setFaxName(name)
        }}
        show={showEditFaxNameModal}
      />

      <PinCheckModal
        onHide={() => setShowPinCheckModal(false)}
        onSubmit={() => {
          setCookie('pin-is-active', true, {
            maxAge: 30 * 60, // 30 mins
          })
          setShowPinCheckModal(false)
        }}
        show={showPinCheckModal}
      />

      <Modal show={showConfirmDelModal} onHide={() => setShowConfirmDelModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to delete this fax?</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() => deleteFaxFile()}>
            Delete
          </Button>
          <Button variant='secondary' onClick={() => setShowConfirmDelModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>

      <Card.Footer className='d-flex justify-content-end py-6 px-9 gap-3'>
        <Button variant='danger' onClick={() => setShowConfirmDelModal(true)}>
          Delete
        </Button>
        <Button variant='success' onClick={() => setShowFaxModal(true)}>
          Fax
        </Button>
        <Button onClick={() => onDownload()}>Download</Button>
        <Link to='..' relative='path' className='btn btn-secondary me-5'>
          Cancel
        </Link>
      </Card.Footer>
    </Card>
  )
}
