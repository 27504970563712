import {axiosInstace} from '../common/axiosInstace'

export const getFaxRecipientsByClinic = async () => {
  const res = await axiosInstace.get('/fax-recipients/get-by-clinic')
  return res.data
}

export const deleteFaxRecipient = async (id: number) => {
  const res = await axiosInstace.delete(`/fax-recipients/${id}`)
  return res.data
}

export const addFaxRecipient = async (data: any) => {
  const res = await axiosInstace.post('/fax-recipients', data)
  return res.data
}

export const updateFaxRecipient = async (id: string, data: any) => {
  const res = await axiosInstace.put(`/fax-recipients/${id}`, data)
  return res.data
}
