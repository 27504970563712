/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Link} from 'react-router-dom'
import {defaultAvatar} from '../../../../app/common/helpers'
import {useAuth, UserStatus} from '../../../../app/modules/auth'

export const HeaderUserMenu: FC = () => {
  const {currentUser, currentPatient, userStatus} = useAuth()
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              alt='avatar'
              src={currentUser?.avatar || currentPatient?.avatar || defaultAvatar}
            />
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.fullName || currentPatient?.fullName}
            </div>
            <p className='fw-bold text-muted fs-7'>{currentUser?.email || currentPatient?.phone}</p>
          </div>
        </div>
      </div>

      <div className='separator my-2' />

      <div className='menu-item px-5'>
        {userStatus === UserStatus.ONLINE ? (
          <>
            {currentUser?.isClinicAdmin && (
              <Link to='/clinic/edit' className='menu-link px-5'>
                Edit Clinic
              </Link>
            )}
            <Link to='/account/personal' className='menu-link px-5'>
              Edit Profile
            </Link>
            <Link to='/account/security' className='menu-link px-5'>
              Change Password
            </Link>
          </>
        ) : (
          <>
            {currentPatient && currentPatient.id === currentPatient.currentProfileId && (
              <Link to='/patient/delegates' className='menu-link px-5'>
                Manage Delegates
              </Link>
            )}
            {currentPatient && currentPatient.profileCount >= 2 && (
              <Link to='/patient/choose-profile' className='menu-link px-5'>
                Choose Profile
              </Link>
            )}
            <Link to='/patient/profile' className='menu-link px-5'>
              View/Edit Profile
            </Link>
          </>
        )}

        <Link to='/logout' className='menu-link px-5'>
          Sign Out
        </Link>
      </div>
    </div>
  )
}
