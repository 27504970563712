import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import moment from 'moment'
import {useCommonAlert} from '../../common/CommonAlert'
import {createCallLog} from '../../apis/calllog'
import Select from 'react-select'
import {callLogLocationOptions, CallLogType, callLogTypeOptions} from '../../common/helpers'
import {getPaginatedPatientsByClinic} from '../../apis'
import {debounce} from 'lodash'
import {components} from 'react-select'
import AsyncSelect from 'react-select/async'

const addCallLogSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string()
    .required('Phone is required')
    .length(10)
    .matches(/^[0-9]*$/, 'Phone contains only digits'),
  dob: Yup.date().required('DOB is required'),
  type: Yup.string().required(),
  body: Yup.string().required('Request / Content is required'),
  note: Yup.string(),
  location: Yup.string(),
})

export const AddCallLogForm: FC = () => {
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [patient, setPatient] = useState<any>()
  const [patientList, setPatientList] = useState<any[]>([])
  const [timespents, setTimespents] = useState<any[]>([])

  const getClinicPatientsCallback = useCallback(async () => {
    try {
      const data = await getPaginatedPatientsByClinic('')
      setPatientList(data.data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getClinicPatientsCallback()
  }, [getClinicPatientsCallback])

  const _loadPeopleOptions = (query: string, callback: any) => {
    getPaginatedPatientsByClinic(query).then((resp) => callback(resp.data))
  }

  const loadPeopleOptions = debounce(_loadPeopleOptions, 300)

  const PeopleOption = (props: any) => (
    <components.Option {...props}>
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-40px symbol-circle me-2'>
          <>
            {props.data.avatar ? (
              <img src={props.data.avatar} alt='avatar' className='object-fit-cover' />
            ) : (
              <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                {props.data.fullName[0] || ''}
              </span>
            )}
          </>
        </div>
        <div className='info-section'>
          <p className='fs-6 fw-bolder text-gray-900' style={{lineHeight: 1.2, marginBottom: 3}}>
            {props.data.fullName}
          </p>
          <p className='text-gray-600 mb-0' style={{fontSize: '12px'}}>
            {`${props.data.phone} - ${props.data.dob}`}
          </p>
          {Boolean(props.data.email) && (
            <p className='text-gray-600 mb-0' style={{fontSize: '12px'}}>
              {props.data.email}
            </p>
          )}
        </div>
      </div>
    </components.Option>
  )

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      phone: '',
      dob: moment().format('YYYY-MM-DD'),
      type: CallLogType.Other,
      body: '',
      note: '',
      location: '',
    },
    validationSchema: addCallLogSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        await createCallLog({...values, timespents})
        return navigate(`/clinic/call-logs`, {state: {showAddedNoti: true}})
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Add Call Log</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          <h4 className='card-title my-3'>Call Log Detail</h4>
          <h5>Caller Info</h5>

          <Row>
            <Form.Group as={Col} md='12' lg='12' className='mb-3' controlId='patientInput'>
              <Form.Label>Select Patient (If available)</Form.Label>
              <AsyncSelect
                loadOptions={loadPeopleOptions}
                placeholder='Search patient for caller...'
                components={{Option: PeopleOption}}
                value={patient}
                onChange={(value) => {
                  setPatient(value)
                  formik.setFieldValue('firstName', value.firstName)
                  formik.setFieldValue('lastName', value.lastName)
                  formik.setFieldValue('dob', value.dob)
                  formik.setFieldValue('phone', value.phone)
                }}
                getOptionValue={(opt) => opt.id}
                getOptionLabel={(opt) => opt.fullName}
                defaultOptions={patientList}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: theme.colors.primary50,
                  },
                })}
              />
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='FNameInput'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder='First Name'
                {...formik.getFieldProps('firstName')}
                className={clsx({
                  'is-invalid': formik.touched.firstName && formik.errors.firstName,
                })}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.firstName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='LNameInput'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder='Last Name'
                {...formik.getFieldProps('lastName')}
                className={clsx({'is-invalid': formik.touched.lastName && formik.errors.lastName})}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.lastName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='PhoneInput'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder='Phone Number'
                {...formik.getFieldProps('phone')}
                autoComplete='off'
                className={clsx({'is-invalid': formik.touched.phone && formik.errors.phone})}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='dobInput'>
              <Form.Label>DOB</Form.Label>
              <Form.Control
                type='date'
                autoComplete='off'
                {...formik.getFieldProps('dob')}
                className={clsx('form-control bg-transparent', {
                  'is-invalid': formik.touched.dob && formik.errors.dob,
                })}
              />
            </Form.Group>

            <h5>Log Info</h5>

            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='LNameInput'>
              <Form.Label>Request / Content</Form.Label>
              <Form.Control
                placeholder='Request / Content'
                {...formik.getFieldProps('body')}
                className={clsx({'is-invalid': formik.touched.body && formik.errors.body})}
              />
              {formik.touched.body && formik.errors.body && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.body}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='LNameInput'>
              <Form.Label>Type</Form.Label>
              <Select
                name='type'
                options={callLogTypeOptions}
                value={callLogTypeOptions.find((item) => item.value === formik.values.type)}
                onChange={(val) => formik.setFieldValue('type', val?.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='NoteInput'>
              <Form.Label>Note</Form.Label>
              <Form.Control
                placeholder='Note'
                as='textarea'
                rows={3}
                {...formik.getFieldProps('note')}
                className={clsx({'is-invalid': formik.touched.note && formik.errors.note})}
              />
              {formik.touched.note && formik.errors.note && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.note}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='LocationInput'>
              <Form.Label>Location</Form.Label>
              <Select
                name='location'
                options={callLogLocationOptions}
                value={callLogLocationOptions.find((item) => item.value === formik.values.location)}
                onChange={(val) => formik.setFieldValue('location', val?.value)}
              />
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end py-6 px-9'>
          <Link to={`/clinic/call-logs`} className='btn btn-secondary me-5'>
            Cancel
          </Link>

          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}
