/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {AdminDashboard} from '../../components/AdminDashboard'
import {StaffDashboard} from '../../components/StaffDashboard'
import {useAuth} from '../../modules/auth'

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {currentUser?.role.title === 'super_admin' ? <AdminDashboard /> : <StaffDashboard />}
    </>
  )
}

export {DashboardWrapper}
