/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Dropdown, Modal} from 'react-bootstrap'
import {useCommonAlert} from '../../../common/CommonAlert'
import {deleteSubClinicPatient} from '../../../apis'

type Props = {
  patient: any
  getPatientsCallback: () => void
  currentPage: number
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
}

export const SubClinicPatientActionsCell: FC<Props> = (props) => {
  const [showIncompleteTasksModal, setShowIncompleteTasksModal] = useState(false)
  const [incompleteTasks, setIncompleteTasks] = useState<string[]>([])
  const [showModalConfirmDeletePatient, setShowModalConfirmDeletePatient] = useState(false)
  const [loading, setLoading] = useState(false)
  const {showAlert} = useCommonAlert()

  const generateIncompleteTasks = useCallback(() => {
    const tasks: string[] = []
    if (props.patient.data) {
      for (const [key, value] of Object.entries(props.patient.data)) {
        if (value === 'Non Compliant') {
          tasks.push(key)
        }
      }
      setIncompleteTasks(tasks)
    }
  }, [props.patient])

  useEffect(() => {
    generateIncompleteTasks()
  }, [props.patient, generateIncompleteTasks])

  const renderPatientIncompleteTasks = () => {
    if (incompleteTasks.length) {
      return (
        <ul>
          {incompleteTasks.map((task: any) => (
            <li key={task}>{task}</li>
          ))}
        </ul>
      )
    } else {
      return <li>Patient "{props.patient.fullName}" has finished all the tasks.</li>
    }
  }

  const funcDeletePatient = async (patientId: string) => {
    try {
      setLoading(true)
      await deleteSubClinicPatient({
        subClinicId: props.patient.clinic.id,
        patientId: props.patient.id,
      })
      if (props.currentPage !== 1) {
        props.setCurrentPage(1)
      } else {
        props.getPatientsCallback()
      }
      setLoading(false)
      showAlert('success', 'Patient has been deleted')
      setShowModalConfirmDeletePatient(false)
    } catch (error) {
      setLoading(false)
      showAlert('error', 'Failed to delete')
      console.log(error)
    }
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle className='btn-light btn-active-light-primary btn-sm' variant=''>
          Actions
        </Dropdown.Toggle>

        <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-3 m-0'>
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => setShowIncompleteTasksModal(true)}>
              View incomplete tasks
            </a>

            <Modal
              show={showIncompleteTasksModal}
              onHide={() => setShowIncompleteTasksModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Patient incomplete tasks</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>List incomplete tasks for patient "{props.patient.fullName}":</h5>
                <ul>{renderPatientIncompleteTasks()}</ul>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => setShowIncompleteTasksModal(false)}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className='menu-item px-3'>
            <a className='menu-link px-3' onClick={() => setShowModalConfirmDeletePatient(true)}>
              Delete patient
            </a>

            <Modal
              show={showModalConfirmDeletePatient}
              onHide={() => setShowModalConfirmDeletePatient(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title>Confirm Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <h5>Are you sure you want to delete this patient</h5>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={loading}
                  variant='danger'
                  onClick={() => funcDeletePatient(props.patient.id)}
                >
                  Delete
                </Button>
                <Button variant='secondary' onClick={() => setShowModalConfirmDeletePatient(false)}>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}
