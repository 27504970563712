/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useCommonAlert} from '../../../common/CommonAlert'

const verifyLoginSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').required('Email is required'),
  password: Yup.string().required('Password is required').min(8, 'Minimum 8 characters'),
  otp: Yup.string().required('OTP is required'),
})

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function VerifyLogin() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const {autoLogin} = useAuth()
  const {showAlert} = useCommonAlert()

  const initialValues = {
    email: location.state?.email || '',
    password: location.state?.password || '',
    otp: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: verifyLoginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await login(values.email, values.password, values.otp)
        autoLogin()
      } catch (error: any) {
        console.log(error)
        const statusCode = error?.response?.data?.statusCode || 500
        if (statusCode === 401) {
          setStatus('The login details are incorrect')
        } else if (statusCode === 400) {
          setStatus('Wrong OTP')
        } else {
          setStatus('Something went wrong')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    if (location.state?.showOtpSent) {
      showAlert('success', 'Otp sent to your email')
      navigate('', {state: {...location.state, showOtpSent: false}, replace: true})
    }
  }, [location.state?.showOtpSent, showAlert]) //eslint-disable-line

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <img className='img-fluid' src={toAbsoluteUrl('/media/logos/logo.png')} alt='Logo' />
        <div className='text-gray-500 fw-semibold fs-1'>Log in to go to your account.</div>
      </div>
      {/* begin::Heading */}

      {/* begin::Separator */}
      <div className='separator separator-content my-14'></div>
      {/* end::Separator */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email Address</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.email && formik.errors.email,
          })}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email.toString()}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.password && formik.errors.password,
          })}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password.toString()}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>OTP</label>
        <input
          placeholder='OTP'
          {...formik.getFieldProps('otp')}
          className={clsx('form-control bg-transparent')}
          name='otp'
          autoComplete='off'
        />
        {formik.touched.otp && formik.errors.otp && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.otp}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5 mt-2'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
        {/* end::Link */}

        {/* begin::Link */}
        <Link to='/patient/auth/login' className='link-primary'>
          Patient Login
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
