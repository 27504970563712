/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

export const SMSChatIndex: FC = () => {
  return (
    <div className='flex-column col-8 flex-lg-row-fluid ms-lg-7 ms-xl-10 ms-6'>
      <div className='card' id='kt_chat_messenger'>
        Select conversation to begin
      </div>
    </div>
  )
}
