import {FC, useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getStaffClinicInfo} from '../../../apis'
import {ClinicCallLog} from '../../../components/staff/ClinicCallLog'

export const ClinicCallLogPageWrapper: FC = () => {
  const navigate = useNavigate()
  const [clinic, setClinic] = useState<any>({})

  const getClinicCallback = useCallback(async () => {
    try {
      const data = await getStaffClinicInfo()
      setClinic(data)
    } catch (error) {
      return navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    getClinicCallback()
  }, [getClinicCallback])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{clinic.name || 'Call Logs'}</PageTitle>
      <ClinicCallLog />
    </>
  )
}
