import {LexicalComposer} from '@lexical/react/LexicalComposer'
import {RichTextPlugin} from '@lexical/react/LexicalRichTextPlugin'
import {ContentEditable} from '@lexical/react/LexicalContentEditable'
import {AutoFocusPlugin} from '@lexical/react/LexicalAutoFocusPlugin'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import {TabIndentationPlugin} from '@lexical/react/LexicalTabIndentationPlugin'
import {HeadingNode} from '@lexical/rich-text'
import {ListItemNode, ListNode} from '@lexical/list'
import {CheckListPlugin} from '@lexical/react/LexicalCheckListPlugin'
import {ListPlugin} from '@lexical/react/LexicalListPlugin'
import {HistoryPlugin} from '@lexical/react/LexicalHistoryPlugin'
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin'
import {$generateHtmlFromNodes} from '@lexical/html'

import moment from 'moment'
import {useRef, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import html2canvas from 'html2canvas'
import {EditorState, LexicalEditor} from 'lexical'

import {
  ExampleTheme,
  LexicalToolbarPlugin,
  ListMaxIndentLevelPlugin,
} from '../../../../common/lexical'
import {useAuth} from '../../../auth'
import {useCommonAlert} from '../../../../common/CommonAlert'

interface Props {
  patient: any
  show: boolean
  onSubmit: (canvas: HTMLCanvasElement) => void
  onHide: () => void
}

const editorConfig: any = {
  theme: ExampleTheme,
  onError(error: Error) {
    console.log(error)
  },
  nodes: [HeadingNode, ListNode, ListItemNode],
}

export const NoteModal: React.FC<Props> = (props) => {
  const {currentUser} = useAuth()
  const {showAlert} = useCommonAlert()

  const [html, setHtml] = useState<string>('')

  const editorRef = useRef<HTMLDivElement>(null)
  const pageRef = useRef<HTMLDivElement>(null)

  const onChange = (editorState: EditorState, editor: LexicalEditor) => {
    editorState.read(() => {
      setHtml($generateHtmlFromNodes(editor, null))
    })
  }

  const handleExportCanvas = () => {
    html2canvas(pageRef.current!, {
      scale: 2,
    })
      .then((pageCanvas) => {
        props.onSubmit(pageCanvas)
      })
      .catch((e) => {
        console.log(e)
        showAlert('error', 'Failed')
      })
  }

  return (
    <Modal className='note-modal' size='xl' show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Progress Notes for patient {props.patient?.fullName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='page' id='note-page' ref={pageRef}>
          <div className='patient-info'>
            <h1 className='mb-5'>Progress Notes</h1>
            <p className='mb-2'>
              <b>Patient Name: </b>
              {props.patient?.fullName}
            </p>
            <p className='mb-2'>
              <b>Date of Birth: </b>
              {props.patient?.dob ? moment(props.patient.dob).format('MMMM Do YYYY') : ''}
            </p>
            <div className='d-flex justify-content-between'>
              <p>
                <b>Patient Phone: </b>
                {props.patient?.phone}
              </p>
              <p>
                <b>Date: </b>
                {moment().format('MMMM Do YYYY')}
              </p>
            </div>
          </div>

          <div className='lexical-editor' ref={editorRef}>
            <LexicalComposer initialConfig={editorConfig}>
              <div className='editor-container'>
                <LexicalToolbarPlugin />
                <div className='editor-inner'>
                  <RichTextPlugin
                    contentEditable={<ContentEditable className='editor-input' />}
                    placeholder={
                      <div className='editor-placeholder'>Enter your content here...</div>
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                  />
                  <AutoFocusPlugin />
                  <ListPlugin />
                  <CheckListPlugin />
                  <ListMaxIndentLevelPlugin maxDepth={4} />
                  <TabIndentationPlugin />
                  <HistoryPlugin />
                  <OnChangePlugin onChange={onChange} ignoreSelectionChange />
                </div>
              </div>
            </LexicalComposer>
          </div>

          <div className='clinician-info mt-3'>
            <p className='mb-6'>
              <b>Clinician Name: </b>
              {currentUser?.fullName}
            </p>
            <div className='d-flex'>
              <b className='me-1'>Clinician Signature:</b>
              <div className='w-50 border-bottom border-dark' />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            if (editorRef.current && pageRef.current) {
              editorRef.current.style.margin = '0px'
              pageRef.current.style.height = '11in'
              pageRef.current.style.border = '0'
              editorRef.current.innerHTML = html
              handleExportCanvas()
            }
          }}
        >
          Sign
        </Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
