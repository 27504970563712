import {FC, useCallback, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {getStaffClinicInfo} from '../../../apis'
import {AddPatientForm} from '../../../components/staff/patient/AddPatientForm'

export const ClinicAddPatientPageWrapper: FC = () => {
  const navigate = useNavigate()

  const getClinicCallback = useCallback(async () => {
    try {
      await getStaffClinicInfo()
    } catch (error) {
      return navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    getClinicCallback()
  }, [getClinicCallback])

  const ClinicAddPatientBreadCrumbs: Array<PageLink> = [
    {
      title: 'Patient List',
      path: '/clinic/patients',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={ClinicAddPatientBreadCrumbs}>Add Patient</PageTitle>
      <AddPatientForm />
    </>
  )
}
