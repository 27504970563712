import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import * as Yup from 'yup'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {getStaffClinicInfo, updateStaffClinicInfo} from '../../apis'
import {useCommonAlert} from '../../common/CommonAlert'

const clinicAdminEditClinicSchema = Yup.object().shape({
  name: Yup.string().required('Clinic name is required'),
  email: Yup.string().email('Wrong email format').required('Clinic email is required'),
  phone: Yup.string().required('Clinic phone is required'),
  address: Yup.string().required('Clinic address line is required'),
  address1: Yup.string(),
  isFaxageEnabled: Yup.boolean(),
})

export const ClinicAdminEditClinicForm: FC = () => {
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const [clinic, setClinic] = useState<any>({})

  const getDataCallback = useCallback(async () => {
    try {
      const clinicData = await getStaffClinicInfo()
      setClinic(clinicData)
    } catch (error) {
      return navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    getDataCallback()
  }, [getDataCallback])

  useEffect(() => {
    if (location.state) {
      if (location.state.showFaxageUpdated) {
        showAlert('success', 'Faxage credentials updated successfully')
      }
      navigate('', {state: null, replace: true})
    }
  }, [location.state, navigate, showAlert])

  const formik = useFormik({
    initialValues: {
      name: (clinic.name as string) || '',
      email: (clinic.email as string) || '',
      phone: (clinic.phone as string) || '',
      address: (clinic.address as string) || '',
      address1: (clinic.address1 as string) || '',
      isFaxageEnabled: (clinic.isFaxageEnabled as boolean) || false,
    },
    validationSchema: clinicAdminEditClinicSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        await updateStaffClinicInfo(values)
        showAlert('success')
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  useEffect(() => {
    if (formik.values.isFaxageEnabled && !clinic.faxageUserName) {
      formik.setFieldError(
        'isFaxageEnabled',
        'You need to set Faxage credentials first to enable Faxage.'
      )
    } else {
      formik.setFieldError('isFaxageEnabled', undefined)
    }
  }, [formik.values.isFaxageEnabled, clinic.faxageUserName, formik])

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Edit Clinic</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          {/* Clinic detail */}
          <h4 className='card-title mb-3'>Clinic Detail</h4>

          <Row>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicNameInput'>
              <Form.Label>Clinic Name</Form.Label>
              <Form.Control
                placeholder='Clinic Name'
                {...formik.getFieldProps('name')}
                value={formik.values.name}
                className={clsx({'is-invalid': formik.touched.name && formik.errors.name})}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicEmailInput'>
              <Form.Label>Clinic Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Clinic Email'
                {...formik.getFieldProps('email')}
                className={clsx({'is-invalid': formik.touched.email && formik.errors.email})}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicPhoneInput'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder='Phone Number'
                {...formik.getFieldProps('phone')}
                className={clsx({'is-invalid': formik.touched.phone && formik.errors.phone})}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicAddr1Input'>
              <Form.Label>Clinic Address Line 1</Form.Label>
              <Form.Control
                placeholder='Clinic Address Line 1'
                {...formik.getFieldProps('address')}
                className={clsx({'is-invalid': formik.touched.address && formik.errors.address})}
              />
              {formik.touched.address && formik.errors.address && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address}</div>
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='clinicAddr2Input'>
              <Form.Label>
                Clinic Address Line 2 <span className='text-muted'>(Optional)</span>
              </Form.Label>
              <Form.Control
                placeholder='Clinic Address Line 2'
                {...formik.getFieldProps('address1')}
                className={clsx({'is-invalid': formik.touched.address1 && formik.errors.address1})}
              />
              {formik.touched.address1 && formik.errors.address1 && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address1}</div>
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} md='12' lg='6' className='mb-3'>
              <Form.Label>Enable Faxage For Clinic</Form.Label>
              <div className='d-flex'>
                <Form.Switch
                  className='mt-3'
                  checked={formik.values.isFaxageEnabled}
                  onChange={() => {
                    formik.setFieldValue('isFaxageEnabled', !formik.values.isFaxageEnabled)
                  }}
                />

                {formik.values.isFaxageEnabled && (
                  <Link className='btn btn-primary btn-sm' to='/clinic/edit/faxage'>
                    Update Faxage
                  </Link>
                )}
              </div>
              {formik.errors.isFaxageEnabled && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.isFaxageEnabled}</div>
                </div>
              )}
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end py-6 px-9'>
          <Link to='/' className='btn btn-secondary me-5'>
            Cancel
          </Link>

          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}
