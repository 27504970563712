import {FC, useCallback, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useCommonAlert} from '../../../common/CommonAlert'
import {deleteSubClinic, getAllSubClinics} from '../../../apis'
import {KTSVG} from '../../../../_metronic/helpers'
import {SubClinicActionsCell} from './SubClinicActionsCell'

export const SubClinicsList: FC = () => {
  const {showAlert} = useCommonAlert()
  const location = useLocation()
  const navigate = useNavigate()

  const [subClinics, setSubClinics] = useState<any[]>([])

  const getSubClinicsListCallback = useCallback(async () => {
    try {
      const data = await getAllSubClinics()
      setSubClinics(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getSubClinicsListCallback()
  }, [getSubClinicsListCallback])

  useEffect(() => {
    const state: any = location.state
    if (state) {
      if (state.showAddedNoti) {
        showAlert('success', 'Sub Clinic added successfully')
      } else if (state.showNotFoundNoti) {
        showAlert('error', 'Sub Clinic not found')
      } else if (state.showSavedNoti) {
        showAlert('success')
      }
      navigate('', {state: null, replace: true})
    }
  }, []) //eslint-disable-line

  const handleDeleteSubClinic = async (subClinicId: number) => {
    try {
      await deleteSubClinic(subClinicId)
      setSubClinics(subClinics.filter((item) => item.id !== subClinicId))
      showAlert('success', 'Deleted successfully')
    } catch (err) {
      console.log(err)
      showAlert('error', 'Failed to delete')
    }
  }

  return (
    <div className='card'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Sub Clinic List</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Show all sub clinics</span>
        </h3>
        <div className='card-toolbar'>
          <Link to={'/clinic/add-sub-clinic'} className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Sub Clinic
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-150px'>Address</th>
                <th className='min-w-120px'>Email</th>
                <th className='min-w-120px'>Phone Number</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='text-gray-600 fw-bold'>
              {subClinics.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.name}</td>
                  <td>{item.address}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td className='text-end'>
                    <SubClinicActionsCell
                      id={item.id}
                      onDelete={(id) => handleDeleteSubClinic(id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
