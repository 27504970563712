import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {PatientIHealthData} from '../../components/patient/PatientIHealthData'

export const PatientIhealthWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Ihealth Data</PageTitle>
      <PatientIHealthData />
    </>
  )
}
