import {FC, useCallback, useEffect, useState} from 'react'
import {Card} from 'react-bootstrap'
import {
  ihealthGetWeight,
  ihealthGetUserinfo,
  ihealthGetSpO2,
  ihealthGetGlucose,
  ihealthGetBp,
} from '../../apis'

export const PatientIHealthData: FC = () => {
  const [ihealthInfo, setIhealthInfo] = useState<any>()
  const [ihealthWeight, setIhealthWeight] = useState<any>()
  const [ihealthSpO2, setIhealthSpO2] = useState<any>()
  const [ihealthBp, setIhealthBp] = useState<any>()
  const [ihealthGlucose, setIhealthGlucose] = useState<any>()

  // TODO: Unique key when render

  const getIHealthInfoCallback = useCallback(async () => {
    try {
      const data = await ihealthGetUserinfo()
      setIhealthInfo(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getIHealthInfoCallback()
  }, [getIHealthInfoCallback])

  const getIHealthWeightCallback = useCallback(async () => {
    try {
      const data = await ihealthGetWeight()
      setIhealthWeight(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getIHealthWeightCallback()
  }, [getIHealthWeightCallback])

  const getIHealthSpO2Callback = useCallback(async () => {
    try {
      const data = await ihealthGetSpO2()
      setIhealthSpO2(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getIHealthSpO2Callback()
  }, [getIHealthSpO2Callback])

  const getIHealthBpCallback = useCallback(async () => {
    try {
      const data = await ihealthGetBp()
      setIhealthBp(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getIHealthBpCallback()
  }, [getIHealthBpCallback])

  const getIHealthGlucoseCallback = useCallback(async () => {
    try {
      const data = await ihealthGetGlucose()
      setIhealthGlucose(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getIHealthGlucoseCallback()
  }, [getIHealthGlucoseCallback])

  return (
    <div className='fs-5'>
      <Card className='mb-6'>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>User Info</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          <ul>
            {ihealthInfo &&
              Object.entries(ihealthInfo).map(([key, value]: any) => (
                <li key={key}>
                  <strong className='text-capitalize'>{key}: </strong>
                  {value}
                </li>
              ))}
          </ul>
        </Card.Body>
      </Card>

      <Card className='mb-6'>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Weight Info (Last 3 months)</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          {ihealthWeight?.totalCount.map((item: any) => (
            <p className='mb-2' key={item.month}>
              <strong>
                Total records in {item.month}: {item.count} in {item.dayCount} days
              </strong>
            </p>
          ))}
          {ihealthWeight && Boolean(ihealthWeight.dataList.length) && (
            <>
              <p className='mb-2'>
                <strong>Last 5 records:</strong>
              </p>
              <ul>
                {ihealthWeight.dataList.map((item: any) => (
                  <li key={item.id}>
                    {item.time}
                    <ul>
                      <li>BMI: {item.BMI}</li>
                      <li>Weight: {item.weight}</li>
                    </ul>
                  </li>
                ))}
              </ul>
            </>
          )}
        </Card.Body>
      </Card>

      <Card className='mb-6'>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Blood oxygen Info (Last 3 months)</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          {ihealthSpO2?.totalCount.map((item: any) => (
            <p className='mb-2' key={item.month}>
              <strong>
                Total records in {item.month}: {item.count} in {item.dayCount} days
              </strong>
            </p>
          ))}
          <p className='mb-2'>
            <strong>Last 5 records:</strong>
          </p>
          <ul>
            {ihealthSpO2 &&
              ihealthSpO2.dataList.map((item: any) => (
                <li key={item.DataID}>
                  {item.time}
                  <ul>
                    <li>SpO2: {item.SpO2}</li>
                    <li>HR: {item.HR}</li>
                  </ul>
                </li>
              ))}
          </ul>
        </Card.Body>
      </Card>

      <Card className='mb-6'>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Blood Pressure Info (Last 3 months)</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          {ihealthBp?.totalCount.map((item: any) => (
            <p className='mb-2' key={item.month}>
              <strong>
                Total records in {item.month}: {item.count} in {item.dayCount} days
              </strong>
            </p>
          ))}
          <p className='mb-2'>
            <strong>Last 5 records:</strong>
          </p>
          <ul>
            {ihealthBp &&
              ihealthBp.dataList.map((item: any) => (
                <li key={item.id}>
                  {item.time}
                  <ul>
                    <li>BPL: {item.BPL}</li>
                    <li>HP: {item.HP}</li>
                    <li>LP: {item.LP}</li>
                  </ul>
                </li>
              ))}
          </ul>
        </Card.Body>
      </Card>

      <Card className='mb-6'>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Blood Glucose Info (Last 3 months)</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          {ihealthGlucose?.totalCount.map((item: any) => (
            <p className='mb-2' key={item.month}>
              <strong>
                Total records in {item.month}: {item.count} in {item.dayCount} days
              </strong>
            </p>
          ))}
          <p className='mb-2'>
            <strong>Last 5 records:</strong>
          </p>
          <ul>
            {ihealthGlucose &&
              ihealthGlucose.dataList.map((item: any) => (
                <li key={item.id}>
                  {item.time}
                  <ul>
                    <li>BG: {item.BG}</li>
                  </ul>
                </li>
              ))}
          </ul>
        </Card.Body>
      </Card>
    </div>
  )
}
