import {useCallback, useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import Select from 'react-select'
import {getFaxRecipientsByClinic} from '../../../app/apis'

interface Props {
  show: boolean
  onSubmit: (faxId: number, selectedFile: any) => void
  onHide: () => void
}

export const FaxModal: React.FC<Props> = (props) => {
  const [faxList, setFaxList] = useState<any[]>([])
  const [selectedFaxRecip, setSelectedFaxRecip] = useState<any>()
  const [selectedFile, setSelectedFile] = useState<File>()

  const getFaxListCallback = useCallback(async () => {
    try {
      const data = await getFaxRecipientsByClinic()
      setFaxList(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getFaxListCallback()
  }, [getFaxListCallback])

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Send Fax Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Choose Fax Recipient</h5>
        <Select
          options={faxList}
          placeholder='Choose Fax Recipient'
          value={selectedFaxRecip}
          onChange={(value) => {
            setSelectedFaxRecip(value)
          }}
          getOptionValue={(opt) => opt.id}
          getOptionLabel={(opt) => `${opt.name} - ${opt.number}`}
        />

        <Form.Group controlId='formFile' className='mt-3'>
          <Form.Label>Select PDF file</Form.Label>
          <input
            type='file'
            className='form-control'
            accept='application/pdf'
            onChange={(e) => setSelectedFile(e.target.files![0])}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onSubmit(selectedFaxRecip.id, selectedFile)}>Send Fax</Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
