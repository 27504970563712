import {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {getTagsByClinic, updateFaxTags} from '../../../apis'
import {ReactTags, Tag as ReactTag} from 'react-tag-autocomplete'
import {useCommonAlert} from '../../../common/CommonAlert'
import {Tag} from '../../../common/types'

interface Props {
  show: boolean
  faxId: string
  tags: Tag[]
  onSubmit: (faxId: string, tags: Tag[]) => void
  onCancel: () => void
}

export const EditTagsModal = (props: Props) => {
  const {showAlert} = useCommonAlert()

  const [suggestions, setSuggestions] = useState([])
  const [tags, setTags] = useState<(ReactTag | string)[]>([])

  useEffect(() => {
    setTags(props.tags.map((t: Tag) => ({value: t.id, label: t.name})))
  }, [props.tags])

  const getFaxTagsCallback = useCallback(async () => {
    try {
      const data = await getTagsByClinic()
      setSuggestions(data.map((item: Tag) => ({value: item.id, label: item.name})))
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getFaxTagsCallback()
  }, [getFaxTagsCallback])

  const onUpdateTags = async () => {
    try {
      const fax = await updateFaxTags(props.faxId, tags)
      showAlert('success')
      props.onSubmit(props.faxId, fax.faxTags)
    } catch (error) {
      console.log(error)
      showAlert('error')
    }
  }

  return (
    <Modal show={props.show} onHide={props.onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Tags Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Note: Tag should only contains letters, numbers, underscore and dash</p>
        <ReactTags
          labelText='Add a tag'
          selected={tags.map((t) => (typeof t === 'string' ? {label: t, value: t} : t))}
          suggestions={suggestions}
          onAdd={(newTag) => {
            if (typeof newTag.value === 'string' && newTag.value === newTag.label) {
              setTags([...tags, newTag.value])
            } else {
              setTags([...tags, newTag])
            }
          }}
          onDelete={(tagIndex) => {
            const temp = [...tags]
            temp.splice(tagIndex, 1)
            setTags(temp)
          }}
          allowNew
          onValidate={(value) => {
            return /^[a-zA-Z0-9_-]*$/i.test(value)
          }}
          allowBackspace={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onUpdateTags}>Save</Button>

        <Button variant='secondary' onClick={props.onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
