import {useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import {SMSChatIndex} from '../../../components/staff/sms-chat/SMSChatIndex'
import {SMSConversationDetails} from '../../../components/staff/sms-chat/SMSConversationDetails'
import {SMSChatPageWrapper} from './SMSChatPageWrapper'

export const SMSChatPage = () => {
  const [activeConv, setActiveConv] = useState<number>(0)
  const [convEdit, setConvEdit] = useState<{id: string; name: string}>({
    id: '',
    name: '',
  })

  return (
    <Routes>
      <Route
        element={
          <SMSChatPageWrapper
            convEdit={convEdit}
            setConvEdit={setConvEdit}
            activeConv={activeConv}
          />
        }
      >
        <Route
          path='/:id'
          element={
            <SMSConversationDetails
              setConvEdit={setConvEdit}
              onInit={(id: string) => setActiveConv(parseInt(id))}
            />
          }
        />
        <Route index element={<SMSChatIndex />} />
      </Route>
    </Routes>
  )
}
