import {RefObject, useEffect, useState} from 'react'

export const useCreateMediaStream = (localVideoRef: RefObject<HTMLVideoElement>) => {
  const [userMediaStream, setUserMediaStream] = useState<MediaStream>()

  useEffect(() => {
    const createMediaStream = async () => {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: {exact: 640},
          height: {exact: 480},
          frameRate: {exact: 30},
          facingMode: 'user',
        },
        audio: true,
      })

      localVideoRef.current!.srcObject = stream

      setUserMediaStream(stream)
    }

    createMediaStream()
  }, [localVideoRef])

  return userMediaStream
}
