import {Socket} from 'socket.io-client'

const {RTCPeerConnection, RTCSessionDescription} = window

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

class PeerConnectionSession {
  public socket: Socket
  public _onConnected: any
  public _onDisconnected: any
  public _room: any
  public peerConnections: any = {}
  public senders: any[] = []
  public listeners: any = {}

  constructor(socket: Socket) {
    this.socket = socket
    this.onCallMade()
  }

  addPeerConnection(id: string, stream: MediaStream, callback: any) {
    this.peerConnections[id] = new RTCPeerConnection({
      iceServers: [
        {
          // urls: 'turn:54.202.53.142:3478?transport=udp', // ec2
          urls: 'turn:149.28.133.145:3478?transport=udp', // vultr
          username: 'coturn',
          credential: 'coturn123',
        },
        {
          urls: 'stun:stun.l.google.com:19302',
        },
      ],
      codecs: [{name: 'VP8', payloadType: 120}],
    } as any)

    stream.getTracks().forEach((track) => {
      this.senders.push(this.peerConnections[id].addTrack(track, stream))
    })

    this.listeners[id] = (event: any) => {
      const fn =
        this[
          ('_on' +
            capitalizeFirstLetter(
              this.peerConnections[id].connectionState
            )) as keyof PeerConnectionSession
        ]
      fn && fn(event, id)
    }

    this.peerConnections[id].addEventListener('connectionstatechange', this.listeners[id])

    this.peerConnections[id].ontrack = function ({streams: [stream]}: any) {
      callback(stream)
    }
  }

  removePeerConnection(id: string) {
    this.peerConnections[id].removeEventListener('connectionstatechange', this.listeners[id])
    delete this.peerConnections[id]
    delete this.listeners[id]
  }

  isAlreadyCalling = false

  async callUser(to: string) {
    if (this.peerConnections[to].iceConnectionState === 'new') {
      const offer = await this.peerConnections[to].createOffer()
      await this.peerConnections[to].setLocalDescription(new RTCSessionDescription(offer))

      this.socket.emit('call-user', {offer, to})
    }
  }

  onConnected(callback: any) {
    console.log('onConnected')
    this._onConnected = callback
  }

  onDisconnected(callback: any) {
    this._onDisconnected = callback
  }

  joinCall(room: string) {
    this._room = room
    this.socket.emit('joinCall', room)
  }

  onCallMade() {
    this.socket.on('call-made', async (data) => {
      await this.peerConnections[data.socket].setRemoteDescription(
        new RTCSessionDescription(data.offer)
      )
      const answer = await this.peerConnections[data.socket].createAnswer()
      await this.peerConnections[data.socket].setLocalDescription(new RTCSessionDescription(answer))

      this.socket.emit('make-answer', {
        answer,
        to: data.socket,
      })
    })
  }

  onAddUser(callback: any) {
    this.socket.on(`call-${this._room}-add-user`, async ({user}) => {
      this.socket.emit('test', {
        user: user,
        onAddUser: 'onAddUser',
      })
      callback(user)
    })
  }

  onRemoveUser(callback: any) {
    this.socket.on(`call-${this._room}-remove-user`, ({socketId}) => {
      callback(socketId)
    })
  }

  onUpdateUserList(callback: any) {
    this.socket.on(`call-${this._room}-update-user-list`, ({users, current}) => {
      this.socket.emit('test', {
        users,
        current,
        med: 'onUpdateUserList',
      })
      callback(users, current)
    })
  }

  onAnswerMade(callback: any) {
    this.socket.on('answer-made', async (data) => {
      await this.peerConnections[data.socket].setRemoteDescription(
        new RTCSessionDescription(data.answer)
      )
      callback(data.socket)
    })
  }

  clearConnections() {
    this.socket.close()
    this.senders = []
    Object.keys(this.peerConnections).forEach(this.removePeerConnection.bind(this))
  }
}

export const createPeerConnectionContext = (socket: Socket) => {
  return new PeerConnectionSession(socket)
}
