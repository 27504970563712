import {useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {checkPin} from '../../../apis'
import {useCommonAlert} from '../../../common/CommonAlert'

interface Props {
  show: boolean
  onSubmit: () => void
  onHide: () => void
}

export const PinCheckModal: React.FC<Props> = (props) => {
  const {showAlert} = useCommonAlert()

  const [code, setCode] = useState<string>('')

  const onSubmitCode = async () => {
    try {
      await checkPin(code)
      showAlert('success')
      props.onSubmit()
    } catch (error: any) {
      console.log(error?.response)
      if (error?.response?.status === 403) {
        showAlert('error', 'Wrong PIN code')
      } else {
        showAlert('error')
      }
    }
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Pin Code Checking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Please enter your Pin code:</h5>
        <Form.Control
          placeholder='PIN Code'
          value={code}
          required
          onChange={(e) => setCode(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onSubmitCode()}>Save</Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
