import {useCallback, useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'

interface Props {
  patient: any
  show: boolean
  onSubmit: (message: string) => void
  onHide: () => void
}

export const PatientsIncompleteTasksModal: React.FC<Props> = (props) => {
  const [message, setMessage] = useState<string>('')
  const [incompleteTasks, setIncompleteTasks] = useState<string[]>([])

  const generateData = useCallback(() => {
    const tasks: string[] = []
    if (props.patient.data) {
      for (const [key, value] of Object.entries(props.patient.data)) {
        if (value === 'Non Compliant') {
          tasks.push(key)
        }
      }
      setIncompleteTasks(tasks)
    }

    if (tasks.length) {
      setMessage(
        `Hello "${props.patient.fullName}", please complete checking these tasks: ${tasks.join(
          ', '
        )}.`
      )
    } else {
      setMessage(
        `Hello "${props.patient.fullName}", Well done. You've finished checking all of your tasks.`
      )
    }
  }, [props.patient])

  useEffect(() => {
    generateData()
  }, [props.patient, generateData])

  const renderPatientIncompleteTasks = () => {
    if (incompleteTasks.length) {
      return (
        <ul>
          {incompleteTasks.map((task: any) => (
            <li key={task}>{task}</li>
          ))}
        </ul>
      )
    } else {
      return <li>Patient "{props.patient.fullName}" has finished all the tasks.</li>
    }
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Patient incomplete tasks</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>List incomplete tasks for patient "{props.patient.fullName}":</h5>
        <ul>{renderPatientIncompleteTasks()}</ul>

        <h5>Message:</h5>
        <Form.Control
          as='textarea'
          rows={3}
          placeholder='Message send to chat'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onSubmit(message)}>Send</Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
