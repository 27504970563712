import {Route, Routes, Navigate, useLocation, Outlet} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {useAuth, UserStatus} from '../modules/auth'
import {AdminRoutes} from './AdminRoutes'
import {StaffRoutes} from './StaffRoutes'
import CallPage from '../modules/apps/chat/CallPage'
import {Profile} from '../pages/account/Profile'
import {ChangePassword} from '../pages/account/ChangePassword'
import {SetupPin} from '../pages/account/SetupPin'

const PrivateRoutes = () => {
  const {userStatus, currentUser} = useAuth()
  const location = useLocation() as any

  // TODO: Use this `from` feature
  const {from} = location.state || {from: {pathname: '/dashboard'}}

  if (userStatus === UserStatus.OFFLINE) {
    return <Navigate to={`/auth/login`} />
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route index element={<Navigate to='/dashboard' />} />
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={from} />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* account */}
        <Route path='account/personal' element={<Profile />} />
        {/* Change password */}
        <Route path='account/security' element={<ChangePassword />} />
        <Route path='account/pin' element={<SetupPin />} />

        {currentUser?.role.title === 'super_admin' ? (
          <Route path='*' element={<AdminRoutes />} />
        ) : (
          <Route path='*' element={<StaffRoutes />} />
        )}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>

      {/* Pages that don't use MasterLayout */}
      <Route element={<Outlet />}>
        <Route path='call/conversation/:id' element={<CallPage />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
