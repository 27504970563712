import {FC, useCallback, useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {getStaffClinicInfo} from '../../../apis'
import {ClinicPatientList} from '../../../components/staff/patient/ClinicPatientList'

export const ClinicPatientListPageWrapper: FC = () => {
  const navigate = useNavigate()
  const [clinic, setClinic] = useState<any>({})

  const getClinicCallback = useCallback(async () => {
    try {
      const data = await getStaffClinicInfo()
      setClinic(data)
    } catch (error) {
      return navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    getClinicCallback()
  }, [getClinicCallback])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{clinic.name || 'Patients'}</PageTitle>
      <ClinicPatientList />
    </>
  )
}
