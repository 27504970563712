import {axiosInstace} from '../common/axiosInstace'
import {ConversationType} from '../common/types'

export const getConversationsByUser = async (search: string) => {
  const res = await axiosInstace.get('/conversation/get-by-participant', {
    params: {search},
  })
  return res.data
}

export const getConversation = async (id: string) => {
  const res = await axiosInstace.get(`/conversation/${id}`)
  return res.data
}

export const getConversationMessages = async (id: any) => {
  const res = await axiosInstace.get(`/conversation/${id}/messages`)
  return res.data
}

export const searchPeopleOptionsToChat = async (search: string, type: ConversationType) => {
  const res = await axiosInstace.get(`/chat/search-people?search=${search}&search_type=${type}`)
  return res.data
}

export const searchPeopleToAddToConversation = async (search: string, convId: any) => {
  const res = await axiosInstace.get(
    `/chat/search-people-to-add-to-conversation/${convId}?search=${search}`
  )
  return res.data
}

export const sendFax = async (frmData: FormData) => {
  const res = await axiosInstace.post('/chat/send-fax', frmData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
  return res.data
}

export const getMediaUploadUrl = async (
  conversationId: string,
  fileName: string,
  fileMimeType: string
) => {
  const res = await axiosInstace.get('/chat/media-upload-url', {
    params: {
      conversationId,
      fileName,
      fileMimeType,
    },
  })
  return res.data
}
