import {FC} from 'react'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {AddSubClinicForm} from '../../../components/staff/sub-clinic/AddSubClinicForm'

const addClinicBreadCrumbs: Array<PageLink> = [
  {
    title: 'Sub Clinics',
    path: '/clinic/sub-clinics',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const ClinicAddSubClinicPageWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={addClinicBreadCrumbs}>Add Sub Clinic</PageTitle>
    <AddSubClinicForm />
  </>
)
