import {useState} from 'react'
import {Route, Routes} from 'react-router-dom'
import {Private} from './components/Private'
import {ChatIndex} from './components/ChatIndex'
import {ChatPageWrapper} from './pages/ChatPageWrapper'

const ChatPage = () => {
  const [activeConv, setActiveConv] = useState<number>(0)

  return (
    <Routes>
      <Route element={<ChatPageWrapper activeConv={activeConv} />}>
        <Route
          path='/:id'
          element={<Private onInit={(id: string) => setActiveConv(parseInt(id))} />}
        />
        <Route index element={<ChatIndex />} />
      </Route>
    </Routes>
  )
}

export default ChatPage
