import {debounce} from 'lodash'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Form, Modal, Pagination} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {KTSVG, useDebounce} from '../../../../_metronic/helpers'
import {
  addSubClinicPatient,
  getPaginatedPatientsByClinic,
  getPaginatedPatientsBySubClinic,
} from '../../../apis'
import {useCommonAlert} from '../../../common/CommonAlert'
import {getPageNumbersToShow} from '../../../common/helpers'
import Select, {components} from 'react-select'
import {SubClinicPatientActionsCell} from './SubClinicPatientActionsCell'
import AsyncSelect from 'react-select/async'

interface Props {
  clinicId: string
}

export const SubClinicPatientList: FC<Props> = (props) => {
  const location = useLocation()
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()

  const [patients, setPatients] = useState<any[]>([])
  const [dataKeys, setDataKeys] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageNumbers, setPageNumbers] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [dataSearch, setDataSearch] = useState<string>('')
  const [activeDataKey, setActiveDataKey] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [patientToChat, setPatientToChat] = useState<any>()
  const [clinicPatientList, setClinicPatientList] = useState<any[]>([])

  const debounceSearch = useDebounce(search, 300)
  const debounceDataSearch = useDebounce(dataSearch, 300)

  const handleSetDataKeys = (patients: any[]) => {
    let i = 0
    while (i < patients.length) {
      const patient = patients[i]
      const patientDataKeys = Object.keys(patient.data)
      if (patientDataKeys.length) {
        setDataKeys(patientDataKeys)
        break
      }
      i++
    }
  }

  const getSubClinicPatientsCallback = useCallback(async () => {
    try {
      const data = await getPaginatedPatientsBySubClinic(
        props.clinicId,
        debounceSearch,
        currentPage,
        20,
        activeDataKey,
        debounceDataSearch
      )
      setPatients(data.data)
      handleSetDataKeys(data.data)
      setTotalPages(data.pagination.totalPages)
      setPageNumbers(getPageNumbersToShow(currentPage, data.pagination.totalPages))
    } catch (error) {
      console.log(error)
    }
  }, [props.clinicId, debounceSearch, currentPage, activeDataKey, debounceDataSearch])

  useEffect(() => {
    getSubClinicPatientsCallback()
  }, [getSubClinicPatientsCallback])

  const getMainClinicPatientsCallback = useCallback(async () => {
    try {
      const data = await getPaginatedPatientsByClinic('', 1, 20, '', '', '', 'true')
      setClinicPatientList(data.data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getMainClinicPatientsCallback()
  }, [getMainClinicPatientsCallback])

  useEffect(() => {
    const state: any = location.state
    if (state) {
      if (state.showAddedNoti) {
        showAlert('success', 'Patient added successfully')
      } else if (state.showAddedDelegate) {
        showAlert('success', `Delegate for patient ${state.showAddedDelegate} added successfully`)
      } else if (state.showEditedPatient) {
        showAlert('success', `Patient ${state.showEditedPatient} updated successfully`)
      }

      navigate('', {state: null, replace: true})
    }
  }, [location.state, showAlert, navigate])

  const PeopleOption = (props: any) => (
    <components.Option {...props}>
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-40px symbol-circle me-2'>
          <>
            {props.data.avatar ? (
              <img src={props.data.avatar} alt='avatar' className='object-fit-cover' />
            ) : (
              <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                {props.data.fullName[0] || ''}
              </span>
            )}
          </>
        </div>
        <div className='info-section'>
          <p className='fs-6 fw-bolder text-gray-900' style={{lineHeight: 1.2, marginBottom: 3}}>
            {props.data.fullName}
          </p>
          <p className='text-gray-600 mb-0' style={{fontSize: '12px'}}>
            {`${props.data.phone} - ${props.data.dob}`}
          </p>
          {Boolean(props.data.email) && (
            <p className='text-gray-600 mb-0' style={{fontSize: '12px'}}>
              {props.data.email}
            </p>
          )}
        </div>
      </div>
    </components.Option>
  )

  const _loadPeopleOptions = (query: string, callback: any) => {
    getPaginatedPatientsByClinic(query).then((resp) => callback(resp.data))
  }

  const loadPeopleOptions = debounce(_loadPeopleOptions, 300)

  const handleAddSubClinicPatient = async () => {
    const patientData = {
      subClinicId: parseInt(props.clinicId) || 0,
      patientId: patientToChat?.id || 0,
    }
    try {
      await addSubClinicPatient(patientData)
      showAlert('success', 'Patient added')
      getSubClinicPatientsCallback()
      setShowModal(false)
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed to add patient')
    }
  }

  return (
    <div className='card'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Patient List</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Sub Clinic patient list</span>
        </h3>

        <div className='card-toolbar'>
          <Button onClick={() => setShowModal(true)}>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Patient
          </Button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='d-flex justify-content-between'>
          <form className='position-relative mb-4' autoComplete='off' style={{maxWidth: 400}}>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute mt-3 ms-5'
            />

            <input
              className='form-control form-control-solid ps-15 mb-3'
              placeholder='Search patient info...'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setCurrentPage(1)
              }}
            />

            <Form.Group className='mb-3'>
              <Form.Label>Search by Patient's data</Form.Label>
              <Select
                placeholder='Select data field'
                options={dataKeys.map((t) => ({value: t, label: t}))}
                onChange={(val) => setActiveDataKey(val?.value)}
                isClearable
              />
            </Form.Group>

            <input
              className='form-control form-control-solid mb-3'
              placeholder='Search patient data...'
              value={dataSearch}
              onChange={(e) => {
                setDataSearch(e.target.value)
                setCurrentPage(1)
              }}
            />
          </form>
        </div>

        {/* begin::Table container */}
        <div className='table-responsive mb-5' style={{minHeight: '300px'}}>
          <table className='table align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted border border-secondary-subtle'>
                <th>Actions</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone</th>
                <th>DOB</th>
                <th>Email</th>
                <th>Verified</th>
                {dataKeys.map((item: any) => (
                  <th key={item} className='min-w-200'>
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold border border-secondary-subtle'>
              {patients.map((item: any) => (
                <tr key={item.id}>
                  <td>
                    <SubClinicPatientActionsCell
                      patient={item}
                      getPatientsCallback={getSubClinicPatientsCallback}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </td>
                  <td>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.phone}</td>
                  <td className='text-nowrap'>{item.dob}</td>
                  <td>{item.email}</td>
                  <td>{item.isVerified ? 'Yes' : 'No'}</td>
                  {dataKeys.map((key: any) => (
                    <td key={key}>{item.data[key] || ''}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* end::Table container */}

        {patients.length ? (
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {pageNumbers.map((pageNumber, index) => {
              const isEllipsis = pageNumber === '...'

              return isEllipsis ? (
                <Pagination.Ellipsis key={index} disabled />
              ) : (
                <Pagination.Item
                  key={index}
                  active={pageNumber === currentPage}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              )
            })}
            <Pagination.Next
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        ) : (
          <></>
        )}
      </div>

      <Modal show={showModal} centered onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add SubClinic Patient</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='mt-3'>Select patient (from Main Clinic):</h4>
          <AsyncSelect
            loadOptions={loadPeopleOptions}
            placeholder='Search patient to add...'
            components={{Option: PeopleOption}}
            value={patientToChat}
            onChange={(value) => {
              setPatientToChat(value)
            }}
            getOptionValue={(opt) => opt.id}
            getOptionLabel={(opt) => opt.fullName}
            defaultOptions={clinicPatientList}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: theme.colors.primary50,
              },
            })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleAddSubClinicPatient}>
            Submit
          </Button>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
