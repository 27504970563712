/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {PatientChooseProfile} from '../../components/patient/PatientChooseProfile'

export const PatienChooseProfilePageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Choose Profile</PageTitle>
      <PatientChooseProfile />
    </>
  )
}
