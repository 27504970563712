import jsPDF from 'jspdf'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import SignatureCanvas from 'react-signature-canvas'
import {useCommonAlert} from '../../../../common/CommonAlert'

interface Props {
  show: boolean
  canvasEl?: HTMLCanvasElement
  onSubmit: (url: URL, pdfBlob: Blob) => void
  onHide: () => void
}

const letterSize = {
  width: 8.5,
  height: 11,
}

const signatureLocation = {
  dHeight: 0.33,
  x: 2.34375,
  y: 9.8958,
}

export const SignatureModal: FC<Props> = (props) => {
  const {showAlert} = useCommonAlert()

  const [sigCanvas, setSigCanvas] = useState<SignatureCanvas | null>()
  const [trimmedDataURL, setTrimmedDataURL] = useState<string>()

  const handleClear = useCallback(() => {
    if (sigCanvas) {
      sigCanvas.clear()
      setTrimmedDataURL(sigCanvas.getTrimmedCanvas().toDataURL())
    }
  }, [sigCanvas])

  useEffect(() => {
    handleClear()
  }, [props.show, handleClear])

  const handleTrim = () => {
    if (sigCanvas) {
      setTrimmedDataURL(sigCanvas.getTrimmedCanvas().toDataURL())
    }
  }

  const handleGeneratePdf = () => {
    try {
      if (props.canvasEl) {
        const ctx = props.canvasEl.getContext('2d')
        if (trimmedDataURL && ctx) {
          const dHeight = signatureLocation.dHeight
          const img = new Image()
          img.src = trimmedDataURL
          img.onload = () => {
            const aspectRatio = img.width / img.height
            const dWidth = dHeight * aspectRatio
            const notesImgData = ctx.canvas.toDataURL() // convert canvas to PNG image data
            const pdf = new jsPDF('p', 'in', 'letter') // create a new PDF document
            pdf.addImage({
              imageData: notesImgData,
              x: 0,
              y: 0,
              width: letterSize.width,
              height: letterSize.height,
              compression: 'FAST',
            })
            pdf.addImage({
              imageData: trimmedDataURL,
              x: signatureLocation.x,
              y: signatureLocation.y,
              width: dWidth,
              height: dHeight,
              compression: 'FAST',
            })
            props.onSubmit(pdf.output('bloburl'), pdf.output('blob'))
          }
        }
      } else {
        showAlert('error', 'Failed')
      }
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed')
    }
  }

  return (
    <Modal className='signature-modal' size='xl' show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Signature modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='sig-wrapper'>
          <div className='sig-container'>
            <SignatureCanvas
              // TODO: Responsive, CSS
              maxWidth={3.5}
              minWidth={1.5}
              velocityFilterWeight={1}
              canvasProps={{height: 300, width: 600}}
              ref={(ref) => {
                setSigCanvas(ref)
              }}
            />
          </div>
          <div>
            <button className='sig-btn' onClick={() => handleClear()}>
              Clear
            </button>
            <button className='sig-btn' onClick={() => handleTrim()}>
              Trim
            </button>
          </div>
          {trimmedDataURL ? (
            <img alt='signature-trimmed' className='sig-image' src={trimmedDataURL} />
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => handleGeneratePdf()}>Continue</Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
