/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import moment from 'moment'
import {TermModal} from './TermModal'
import {Button, Form} from 'react-bootstrap'
import {registerForClinic} from '../../../../apis'

const registerSchema = Yup.object().shape({
  phone: Yup.string()
    .required('Phone is required')
    .length(10)
    .matches(/^[0-9]*$/, 'Phone contains only digits'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  verify: Yup.boolean().oneOf([true], 'You must accept the terms and conditions'),
  dob: Yup.date().required('DOB is required'),
})

const initialValues = {
  phone: '',
  firstName: '',
  lastName: '',
  verify: false,
  dob: moment().format('YYYY-MM-DD'),
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function RegisterForClinic() {
  const navigate = useNavigate()
  const {id} = useParams()

  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: registerSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await registerForClinic(id, values)
        navigate('/patient/auth/login', {state: {...values, showRegistered: true}})
      } catch (error) {
        console.error(error)
        setStatus('The patient details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <img className='img-fluid' src={toAbsoluteUrl('/media/logos/logo.png')} alt='Logo' />
        <div className='text-gray-500 fw-semibold fs-1'>Patient register</div>
      </div>
      {/* begin::Heading */}

      {/* begin::Separator */}
      <div className='separator separator-content my-14'></div>
      {/* end::Separator */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fs-6 fw-bolder text-dark'>Phone Number</label>
        <input
          placeholder='Phone number'
          {...formik.getFieldProps('phone')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.phone && formik.errors.phone,
          })}
          autoComplete='off'
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>First Name</label>
        <input
          autoComplete='off'
          {...formik.getFieldProps('firstName')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.firstName && formik.errors.firstName,
          })}
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Last Name</label>
        <input
          autoComplete='off'
          {...formik.getFieldProps('lastName')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.lastName && formik.errors.lastName,
          })}
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastName}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>DOB</label>
        <input
          autoComplete='off'
          type='date'
          {...formik.getFieldProps('dob')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.dob && formik.errors.dob,
          })}
        />
      </div>
      {/* end::Form group */}

      <Button className='mb-2' onClick={() => setShowModal(true)}>
        Show Terms and Conditions
      </Button>

      <Form.Check // prettier-ignore
        type='checkbox'
        className='mt-2'
        value={formik.values.verify ? 'true' : ''}
        onChange={() => formik.setFieldValue('verify', !formik.values.verify)}
        name='verify'
        id='checkbox-verify'
        label='By agreeing to terms, you consent to receive automated SMS/MMS messages from DocTalkLive,
          including marketing messages. Message & data rates may apply.'
      />
      {formik.touched.verify && formik.errors.verify && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{formik.errors.verify}</span>
          </div>
        </div>
      )}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5 mt-2'>
        <div />

        {/* begin::Link */}
        <Link to='/patient/auth/login' className='link-primary'>
          Already verified? Go to login page
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

      <TermModal show={showModal} onClose={() => setShowModal(false)} />
    </form>
  )
}
