/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {setupPin} from '../apis'
import {useCommonAlert} from '../common/CommonAlert'
import {useAuth} from '../modules/auth'

const digitsOnly = (value: any) => /^\d+$/.test(value)

export const SetupPinForm: FC = () => {
  const {currentUser, setCurrentUser} = useAuth()
  const navigate = useNavigate()
  const {showAlert} = useCommonAlert()

  const [loading, setLoading] = useState(false)

  const {handleSubmit, getFieldProps, touched, errors, isSubmitting, isValid} = useFormik({
    initialValues: {
      pin: '',
      pinConfirm: '',
    },
    validationSchema: Yup.object({
      pin: Yup.string()
        .length(4, 'Pin has 4 characters')
        .test('Digits only', 'The field should have digits only', digitsOnly)
        .required('Pin is required'),
      pinConfirm: Yup.string()
        .required('Confirm Pin is required')
        .oneOf([Yup.ref('pin'), null], "Pins don't match."),
    }),
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        if (!currentUser?.id) return
        await setupPin({
          pin: values.pin,
          pinConfirm: values.pinConfirm,
        })
        setCurrentUser({...currentUser!, hasPin: true})
        showAlert('success')
        return navigate('/account/personal')
      } catch (error: any) {
        if (error.response) {
          const res = error.response

          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <>
      <div className='mx-4 d-flex justify-content-center shadow px-4 rounded security-page'>
        <div className='row w-100 mw-600px'>
          <div className='mb-5 title'>Setup Pin:</div>
          <Form onSubmit={handleSubmit} autoComplete='off'>
            <Form.Group className='mb-5'>
              <Form.Label className='fw-bold mb-0'>Pin</Form.Label>
              <p className='mb-1 fs-6 fw-light'>NOTE: Pin must be a 4 digits number (Ex: 1234)</p>
              <Form.Control
                type='text'
                placeholder='Enter your pin'
                autoComplete='off'
                {...getFieldProps('pin')}
                className={clsx({'is-invalid': touched.pin && errors.pin})}
              />
              {touched.pin && errors.pin && <div className='mt-3 text-danger'>{errors.pin}</div>}
            </Form.Group>

            <Form.Group className='mb-5'>
              <Form.Label className='fw-bold'>Confirm Pin</Form.Label>
              <Form.Control
                type='text'
                placeholder='Confirm pin'
                autoComplete='off'
                {...getFieldProps('pinConfirm')}
                className={clsx({'is-invalid': touched.pinConfirm && errors.pinConfirm})}
              />
              {touched.pinConfirm && errors.pinConfirm && (
                <div className='mt-3 text-danger'>{errors.pinConfirm}</div>
              )}
            </Form.Group>

            <Link to={`/account/personal`} className='btn btn-secondary me-5'>
              Cancel
            </Link>

            <Button type='submit' disabled={isSubmitting || !isValid}>
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              ) : (
                'Save Changes'
              )}
            </Button>
          </Form>
        </div>
      </div>
    </>
  )
}
