/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {Link, useNavigate} from 'react-router-dom'
import * as Yup from 'yup'
import {changePassword} from '../apis'
import {useCommonAlert} from '../common/CommonAlert'
import {useAuth} from '../modules/auth'

export const ChangePasswordForm: FC = () => {
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const {showAlert} = useCommonAlert()

  const [loading, setLoading] = useState(false)

  // TODO: Test and fix this page

  const {handleSubmit, getFieldProps, touched, errors, isSubmitting, isValid} = useFormik({
    initialValues: {
      currentPass: '',
      newPass: '',
      confirmPass: '',
    },
    validationSchema: Yup.object({
      currentPass: Yup.string().required('Current Password is required'),
      newPass: Yup.string()
        .min(8, 'Minimum 8 characters')
        .required('New Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
          'Must have at least 1 uppercase, 1 lowercase and 1 number'
        ),
      confirmPass: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('newPass'), null], "Passwords don't match."),
    }),
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        if (!currentUser?.id) return
        await changePassword({
          currentPassword: values.currentPass,
          newPassword: values.newPass,
        })
        return navigate('/account/personal')
      } catch (error: any) {
        if (error.response) {
          const res = error.response

          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <>
      <div className='mx-4 d-flex justify-content-center shadow px-4 rounded security-page'>
        <div className='row w-100 mw-600px'>
          <div className='mb-5 title'>Change Password:</div>
          {/* Change password */}
          <Form onSubmit={handleSubmit}>
            <Form.Group className='mb-5'>
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter current password'
                autoComplete='off'
                {...getFieldProps('currentPass')}
                className={clsx({'is-invalid': touched.currentPass && errors.currentPass})}
              />
              {touched.currentPass && errors.currentPass && (
                <div className='mt-3 text-danger'>{errors.currentPass}</div>
              )}
            </Form.Group>

            <Form.Group className='mb-5'>
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter new password'
                autoComplete='off'
                {...getFieldProps('newPass')}
                className={clsx({'is-invalid': touched.newPass && errors.newPass})}
              />
              {touched.newPass && errors.newPass && (
                <div className='mt-3 text-danger'>{errors.newPass}</div>
              )}
            </Form.Group>

            <Form.Group className='mb-5'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm new password'
                autoComplete='off'
                {...getFieldProps('confirmPass')}
                className={clsx({'is-invalid': touched.confirmPass && errors.confirmPass})}
              />
              {touched.confirmPass && errors.confirmPass && (
                <div className='mt-3 text-danger'>{errors.confirmPass}</div>
              )}
            </Form.Group>

            <Link to={`/account/personal`} className='btn btn-secondary me-5'>
              Cancel
            </Link>

            <Button type='submit' disabled={isSubmitting || !isValid}>
              {loading ? (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              ) : (
                'Save Changes'
              )}
            </Button>
          </Form>
        </div>
      </div>
    </>
  )
}
