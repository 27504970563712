/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import { EditProfileForm } from '../../components/EditProfileForm'
import { AccountNavbar } from '../../components/AccountNavbar'

const Profile: FC = () => {
  return (
    <>
      <div className='container-xl px-4 mt-4 account-page'>
        {/* Account page navigation*/}
        <AccountNavbar active='Profile'/>
        <hr className='mt-0 mb-4' />
        <EditProfileForm/>
      </div>
    </>
  )
}

export {Profile}
