import {debounce} from 'lodash'
import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {components} from 'react-select'
import AsyncSelect from 'react-select/async'
import {searchPeopleToAddToConversation} from '../../../../apis'

interface Props {
  show: boolean
  convId: any
  onSubmit: (participants: any[]) => void
  onHide: () => void
}

export const AddParticipantsToConversationModal: FC<Props> = (props) => {
  const [ptcp, setPtcp] = useState<any[]>([])

  const PeopleOption = (props: any) => (
    <components.Option {...props}>
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-40px symbol-circle me-2'>
          <>
            {props.data.avatar ? (
              <img src={props.data.avatar} alt='avatar' className='object-fit-cover' />
            ) : (
              <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                {props.data.fullName[0] || ''}
              </span>
            )}
          </>
        </div>
        <div className='info-section'>
          <p
            className='fs-6 fw-bolder text-gray-900 text-hover-primary'
            style={{lineHeight: 1.2, marginBottom: 4}}
          >
            {props.data.fullName}
          </p>
          <p className='text-gray-600 text-hover-primary mb-0' style={{fontSize: '12px'}}>
            {props.data.dob
              ? `${props.data.phone} - ${props.data.dob}`
              : props.data.role.description}
          </p>
        </div>
      </div>
    </components.Option>
  )

  const MultiPeopleValueLabel = (props: any) => (
    <components.MultiValueLabel {...props}>{props.data.fullName}</components.MultiValueLabel>
  )

  const _loadPeopleOptions = (query: string, callback: any) => {
    searchPeopleToAddToConversation(query, props.convId).then((resp) => callback(resp))
  }

  const loadPeopleOptions = debounce(_loadPeopleOptions, 300)
  return (
    <Modal show={props.show} size='lg' centered onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Create conversation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='my-3'>Choose participants</h4>
        <AsyncSelect
          isMulti
          loadOptions={loadPeopleOptions}
          placeholder='Search people to chat...'
          components={{Option: PeopleOption, MultiValueLabel: MultiPeopleValueLabel}}
          value={ptcp}
          onChange={(values) => {
            setPtcp([...values])
          }}
          getOptionValue={(opt) => opt.id}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          onClick={() => {
            props.onSubmit(ptcp)
            setPtcp([])
          }}
        >
          Submit
        </Button>
        <Button
          variant='secondary'
          onClick={() => {
            props.onHide()
            setPtcp([])
          }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
