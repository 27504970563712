import {axiosAuthInstace, axiosInstace} from '../common/axiosInstace'

export const verifyPatient = async (data: any) => {
  const res = await axiosInstace.post('/patient-auth/verify', data)
  return res.data
}

export const registerForClinic = async (clinicId: any, data: any) => {
  const res = await axiosInstace.post(`/patients/clinic/${clinicId}/register`, data)
  return res.data
}

export const patientGenOTP = async (data: any) => {
  const res = await axiosInstace.post('/patient-auth/create-otp', data)
  return res.data
}

export const patientLogin = async (data: any) => {
  const res = await axiosInstace.post('/patient-auth/login', data)
  return res.data
}

export const getPatientProfile = async () => {
  const res = await axiosAuthInstace.get('/patients/profile')
  return res.data
}

export const getPatientDashboard = async () => {
  const res = await axiosInstace.get('/patients/dashboard')
  return res.data
}

export const getPatientProfileList = async () => {
  const res = await axiosInstace.get('/patients/profile-list')
  return res.data
}

export const choosePatientProfile = async (profileId: number) => {
  const res = await axiosInstace.post(`/patient-auth/choose-profile/${profileId}`)
  return res.data
}

export const updatePatientProfile = async (data: any) => {
  const res = await axiosInstace.put('/patients/profile', data)
  return res.data
}

export const getPatientAvatarUploadConfig = async (data: any) => {
  const res = await axiosInstace.get('/patients/avatar-upload-config', {
    params: data,
  })
  return res.data
}

export const setPatientAvatarUrl = async (bucket: string, key: string) => {
  const res = await axiosInstace.put('/patients/update-avatar', {bucket, key})
  return res.data
}

export const createPatient = async (data: any) => {
  const res = await axiosInstace.post('/patients', data)
  return res.data
}

export const staffGetPatientProfile = async (id: any) => {
  const res = await axiosInstace.get(`/patients/${id}/staff-get-patient-profile`)
  return res.data
}

export const staffAddDelegate = async (patientId: any, data: any) => {
  const res = await axiosInstace.post(`/patients/${patientId}/staff-add-delegate`, data)
  return res.data
}

export const staffEditPatient = async (patientId: any, data: any) => {
  const res = await axiosInstace.put(`/patients/${patientId}/staff-edit-patient`, data)
  return res.data
}

export const getPatientDelegateRequests = async () => {
  const res = await axiosInstace.get('/patients/delegate-requests')
  return res.data
}

export const getPatientDelegates = async () => {
  const res = await axiosInstace.get('/patients/delegates')
  return res.data
}

export const patientAcceptDelegate = async (delegateId: any) => {
  const res = await axiosInstace.put(`/patients/accept-delegate/${delegateId}`)
  return res.data
}

export const patientDenyDelegate = async (delegateId: any) => {
  const res = await axiosInstace.put(`/patients/deny-delegate/${delegateId}`)
  return res.data
}

export const patientAddDelegate = async (data: any) => {
  const res = await axiosInstace.post(`/patients/add-delegate`, data)
  return res.data
}

export const deletePatient = async (patientId: any) => {
  const res = await axiosInstace.delete(`/patients/${patientId}`)

  return res.data
}
