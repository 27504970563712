import {Button, Modal} from 'react-bootstrap'

interface Props {
  show: boolean
  onConfirm: () => void
  onCancel: () => void
  isNext: boolean
}

export const ConfirmNavigateFaxModal = (props: Props) => {
  return (
    <Modal show={props.show} onHide={props.onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to navigate to {props.isNext ? 'next' : 'previous'} fax?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onConfirm}>Navigate</Button>

        <Button variant='secondary' onClick={props.onCancel}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
