/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {AccountNavbar} from '../../components/AccountNavbar'
import {useAuth} from '../../modules/auth'
import {ChangePinForm} from '../../components/ChangePinForm'
import {SetupPinForm} from '../../components/SetupPinForm'

export const SetupPin: FC = () => {
  const {currentUser} = useAuth()

  return (
    <>
      <div className='container-xl px-4 mt-4 account-page'>
        {/* Account page navigation*/}
        <AccountNavbar active='pin' />
        <hr className='mt-0 mb-4' />
        {currentUser?.hasPin ? <ChangePinForm /> : <SetupPinForm />}
      </div>
    </>
  )
}
