import {FC, useCallback, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {getStaffClinicInfo} from '../../../apis'
import {IncomingFaxPreview} from '../../../components/staff/IncomingFaxPreview'

export const IncomingFaxPreviewPageWrapper: FC = () => {
  const navigate = useNavigate()
  const {faxId} = useParams()

  const getClinicCallback = useCallback(async () => {
    try {
      await getStaffClinicInfo()
    } catch (error) {
      return navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    getClinicCallback()
  }, [getClinicCallback])

  const FaxPreviewBreadCrumbs: Array<PageLink> = [
    {
      title: 'Incoming faxes',
      path: '/clinic/incoming-faxes',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={FaxPreviewBreadCrumbs}>Fax Preview</PageTitle>
      <IncomingFaxPreview key={faxId} faxId={faxId || ''} />
    </>
  )
}
