import {useEffect, useMemo, useState} from 'react'
import {createPeerConnectionContext} from '../modules/apps/chat/core/PeerConnectionSession'
import {useSocket} from '../modules/apps/chat/core/Socket'

export const useStartPeerSession = (room: string, userMediaStream: MediaStream) => {
  const {socket} = useSocket()
  const peerVideoConnection = useMemo(() => createPeerConnectionContext(socket), [socket])

  const [connectedUsers, setConnectedUsers] = useState<string[]>([])

  useEffect(() => {
    if (userMediaStream) {
      peerVideoConnection.joinCall(room)
      peerVideoConnection.onAddUser((user: string) => {
        setConnectedUsers((users: string[]) => [...users, user])
        peerVideoConnection.addPeerConnection(`${user}`, userMediaStream, (_stream: any) => {
          const video = document.getElementById(user) as HTMLVideoElement
          video.srcObject = _stream
        })
        peerVideoConnection.callUser(user)
      })

      peerVideoConnection.onRemoveUser((socketId: string) => {
        setConnectedUsers((users) => users.filter((user) => user !== socketId))
        peerVideoConnection.removePeerConnection(socketId)
      })

      peerVideoConnection.onUpdateUserList(async (users: string[]) => {
        setConnectedUsers(users)
        for (const user of users) {
          peerVideoConnection.addPeerConnection(`${user}`, userMediaStream, (_stream: any) => {
            const video = document.getElementById(user) as HTMLVideoElement
            video.srcObject = _stream
          })
        }
      })

      peerVideoConnection.onAnswerMade((socketId: string) => peerVideoConnection.callUser(socketId))
    }

    return () => {
      if (userMediaStream) {
        peerVideoConnection.clearConnections()
        userMediaStream?.getTracks()?.forEach((track) => track.stop())
      }
    }
  }, [room, userMediaStream, peerVideoConnection])

  return connectedUsers
}
