import {FC, useCallback, useEffect, useState} from 'react'
import {Card} from 'react-bootstrap'
import {
  getPatientDelegateRequests,
  getPatientDelegates,
  patientAcceptDelegate,
  patientDenyDelegate,
} from '../../apis'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {useCommonAlert} from '../../common/CommonAlert'

export const PatientDelegates: FC = () => {
  const {showAlert} = useCommonAlert()
  const location = useLocation()
  const navigate = useNavigate()

  const [delegateRequests, setDelegateRequests] = useState<any[]>([])
  const [delegates, setDelegates] = useState<any[]>([])

  useEffect(() => {
    const state: any = location.state
    if (state) {
      if (state.showAddedNoti) {
        showAlert('success', 'Delegate added successfully')
      }

      navigate('', {state: null, replace: true})
    }
  }, [location.state, showAlert, navigate])

  const getDelegatesCallback = useCallback(async () => {
    try {
      const reqs = await getPatientDelegateRequests()
      const dels = await getPatientDelegates()
      setDelegateRequests(reqs)
      setDelegates(dels)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getDelegatesCallback()
  }, [getDelegatesCallback])

  const acceptDelegate = async (relationship: any) => {
    try {
      await patientAcceptDelegate(relationship.delegate.id)
      await getDelegatesCallback()
      showAlert('success', 'Accepted successfully')
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed to accept')
    }
  }

  const denyDelegate = async (relationship: any) => {
    try {
      await patientDenyDelegate(relationship.delegate.id)
      setDelegateRequests(delegateRequests.filter((item) => item.id !== relationship.id))
      showAlert('success', 'Denied successfully')
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed to deny')
    }
  }

  return (
    <>
      <Card className='mb-6'>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>List of pending delegate requests</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          {delegateRequests.length ? (
            <div className='table-responsive mb-5'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Full Name</th>
                    <th>Phone</th>
                    <th>DOB</th>
                    <th>Patient Relationship Name</th>
                    <th>Delegate Relationship Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold'>
                  {delegateRequests.map((item: any) => (
                    <tr key={item.id}>
                      <td>{item.delegate.fullName}</td>
                      <td>{item.delegate.phone}</td>
                      <td className='text-nowrap'>{item.delegate.dob}</td>
                      <td>{item.patientRelationshipName}</td>
                      <td>{item.delegateRelationshipName}</td>
                      <td>
                        <>
                          <span
                            className='btn btn-light btn-active-light-primary btn-sm'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                          >
                            Actions
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr072.svg'
                              className='svg-icon-5 m-0'
                            />
                          </span>
                          <div
                            className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-3'
                            data-kt-menu='true'
                          >
                            <div className='menu-item px-3'>
                              <span className='menu-link px-3' onClick={() => acceptDelegate(item)}>
                                Accept
                              </span>
                            </div>
                            <div className='menu-item px-3'>
                              <span className='menu-link px-3' onClick={() => denyDelegate(item)}>
                                Deny
                              </span>
                            </div>
                          </div>
                        </>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>There are no pending delegate requests</p>
          )}
        </Card.Body>
      </Card>

      <Card className='mb-6'>
        <Card.Header className='py-5'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>List of accepted delegates</h3>
          </Card.Title>

          <div className='card-toolbar'>
            <Link to='/patient/add-delegate' className='btn btn-primary'>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Add Delegate
            </Link>
          </div>
        </Card.Header>

        <Card.Body className='pt-3'>
          {delegates.length ? (
            <div className='table-responsive mb-5'>
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th>Full Name</th>
                    <th>Phone</th>
                    <th>DOB</th>
                    <th>Patient Relationship Name</th>
                    <th>Delegate Relationship Name</th>
                  </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold'>
                  {delegates.map((item: any) => (
                    <tr key={item.id}>
                      <td>{item.delegate.fullName}</td>
                      <td>{item.delegate.phone}</td>
                      <td className='text-nowrap'>{item.delegate.dob}</td>
                      <td>{item.patientRelationshipName}</td>
                      <td>{item.delegateRelationshipName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>There are no accepted delegates</p>
          )}
        </Card.Body>
      </Card>
    </>
  )
}
