import {FC, useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {staffGetPatientProfile} from '../../../apis'
import {ClinicEditPatientForm} from '../../../components/staff/patient/ClinicEditPatientForm'

export const ClinicEditPatientPageWrapper: FC = () => {
  const navigate = useNavigate()
  const {id} = useParams()

  const [patientData, setPatientData] = useState<any>()

  const getPatientProfileCallback = useCallback(async () => {
    try {
      const data = await staffGetPatientProfile(id)
      setPatientData(data)
    } catch (error) {
      return navigate('/clinic/patients')
    }
  }, [navigate, id])

  useEffect(() => {
    getPatientProfileCallback()
  }, [getPatientProfileCallback])

  const ClinicEditPatientBreadCrumbs: Array<PageLink> = [
    {
      title: 'Patient List',
      path: '/clinic/patients',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: patientData?.fullName,
      path: '/clinic/patients',
      isSeparator: false,
      isActive: true,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={ClinicEditPatientBreadCrumbs}>Edit Patient</PageTitle>
      <ClinicEditPatientForm patient={patientData} />
    </>
  )
}
