import {Route, Routes} from 'react-router-dom'
import {AuthLayout} from '../AuthLayout'
import {Login} from './components/Login'
import {Register} from './components/Register'
import {VerifyLogin} from './components/VerifyLogin'
import {RegisterForClinic} from './components/RegisterForClinic'

export const PatientAuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='clinic/:id/register' element={<RegisterForClinic />} />
      <Route path='register' element={<Register />} />
      <Route path='login' element={<Login />} />
      <Route path='login/verify' element={<VerifyLogin />} />
      <Route index element={<Register />} />
    </Route>
  </Routes>
)
