import moment from 'moment'
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {Link, Outlet, useNavigate} from 'react-router-dom'
import {useCommonAlert} from '../../../common/CommonAlert'
import {UserStatus, useAuth} from '../../../modules/auth'
import {useSocket} from '../../../modules/apps/chat/core/Socket'
import {Conversation} from '../../../common/types'
import {KTSVG, useDebounce} from '../../../../_metronic/helpers'
import {SMSConversationBody, createSMSConversation, getSMSConversationList} from '../../../apis'
import {Button, Form, Modal} from 'react-bootstrap'
import clsx from 'clsx'

interface Props {
  activeConv: number
  convEdit: {id: string; name: string}
  setConvEdit: any
}

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '1m',
    ss: '1m',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    w: '1w',
    ww: '%dw',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
})

export const SMSChatLayout: FC<Props> = (props) => {
  const {twilioSocket} = useSocket()
  const {showAlert} = useCommonAlert()
  const {unreadSMSMessages, userStatus} = useAuth()
  const [smsConversations, setSMSConversations] = useState<Conversation[]>([])
  const [convSearchStr, setConvSearchStr] = useState<string>('')
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [smsCreate, setSmsCreate] = useState<SMSConversationBody>({
    name: '',
    phone: '',
    message: '',
  })
  const [error, setError] = useState<SMSConversationBody>({
    phone: '',
    message: '',
  })
  const navigate = useNavigate()

  const getConversationsCallback = useCallback(async () => {
    try {
      const data = await getSMSConversationList()
      setSMSConversations(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getConversationsCallback()
  }, [getConversationsCallback])

  useEffect(() => {
    twilioSocket.on('sms-new-conv', async (conv: Conversation) => {
      setSMSConversations((convList) => [conv, ...convList])
    })

    return () => {
      twilioSocket.off('sms-new-conv')
    }
  }, [twilioSocket])

  useEffect(() => {
    twilioSocket.on('exception', async (err) => {
      showAlert('error', err.message)
    })
  }, [twilioSocket, showAlert])

  useEffect(() => {
    twilioSocket.on('sms-new-msg', (msgData: {convId: number}) => {
      const index = smsConversations.findIndex(({id}) => id === msgData.convId)
      if (index !== -1) {
        const temp = [...smsConversations]
        temp.unshift(...temp.splice(index, 1))
        temp[0].updatedAt = new Date()

        setSMSConversations(temp)
      }
    })

    return () => {
      twilioSocket.off('sms-new-msg')
    }
  }, [twilioSocket, smsConversations])

  useEffect(() => {
    if (props.convEdit.id) {
      const arr = [...smsConversations]
      const index = arr.findIndex(
        (sc) => sc.id === props.activeConv && props.activeConv === Number(props.convEdit.id)
      )
      if (index !== -1) {
        arr.splice(index, 1, {
          ...arr[index],
          name: props.convEdit.name,
        })
      }
      setSMSConversations(arr)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.activeConv, props.convEdit.id, props.convEdit.name])

  const debouncedConvSearchStr = useDebounce(convSearchStr, 300)

  const smsConversationsFinal = useMemo(() => {
    const list = [...smsConversations]
    if (debouncedConvSearchStr) {
      const filterSmsConversation = list.filter(
        (smsConv) =>
          smsConv.name?.toLowerCase().includes(debouncedConvSearchStr.toLowerCase()) ||
          smsConv.phone?.toLowerCase().includes(debouncedConvSearchStr.toLowerCase())
      )
      return filterSmsConversation
    }
    return list
  }, [debouncedConvSearchStr, smsConversations])

  const handleCloseModal = () => {
    setShowModal(false)
    handleResetModal()
  }

  const handleResetModal = useCallback(() => {
    setSmsCreate({
      name: '',
      phone: '',
      message: '',
    })
    setError({
      phone: '',
      message: '',
    })
  }, [])

  // TODO: Use Twilio Socket
  const handleCreateSmsConversation = useCallback(async () => {
    try {
      const name = smsCreate.name?.trim()
      const phone = smsCreate.phone.trim()
      const message = smsCreate.message.trim()
      if (!phone || !message || (phone && phone.length !== 10)) {
        const temp = {...error}
        if (!phone) {
          temp.phone = 'Phone is required'
        }
        if (phone && phone.length !== 10) {
          temp.phone = 'Phone must be exactly 10 characters'
        }
        if (!message) {
          temp.message = 'Message is required'
        }
        setError(temp)
        return
      }
      setLoading(true)
      const checkAlready = smsConversationsFinal.find((sc) => sc.phone === '+1' + phone)
      if (checkAlready) {
        navigate('/sms-chat/' + checkAlready.id)
        showAlert('success', 'SMS conversation has already created')
      } else {
        const data = await createSMSConversation({
          name: name || undefined,
          phone: '+1' + phone,
          message,
        })
        await getConversationsCallback()
        navigate('/sms-chat/' + data.id)
        showAlert('success', 'New SMS conversation created')
      }
      setShowModal(false)
      setLoading(false)
      handleResetModal()
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }, [
    error,
    getConversationsCallback,
    handleResetModal,
    navigate,
    showAlert,
    smsConversationsFinal,
    smsCreate.message,
    smsCreate.name,
    smsCreate.phone,
  ])

  const validate = useCallback(
    (item: SMSConversationBody) => {
      if (item) {
        const temp = {...error}
        temp.phone = item.phone?.toString() ? '' : 'Phone is required'
        temp.phone = item.phone?.toString()
          ? item.phone?.toString().length !== 10
            ? 'Phone must be exactly 10 characters'
            : ''
          : 'Phone is required'
        temp.message = item.message?.toString() ? '' : 'Message is required'
        setError(temp)
      }
    },
    [error]
  )

  return (
    <div className='chat-page'>
      <div className='d-flex flex-row'>
        <div className='flex-column col flex-lg-row-auto w-100px w-lg-300px w-xl-400px mb-lg-0'>
          <div className='card card-conv-list'>
            <div className='card-header d-none d-md-block' id='kt_chat_contacts_header'>
              <form className='w-100 position-relative' autoComplete='off'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                />

                <input
                  type='text'
                  className='form-control form-control-solid px-15'
                  placeholder='Search conversations...'
                  value={convSearchStr}
                  onChange={(e) => setConvSearchStr(e.target.value)}
                />
              </form>
            </div>

            {/* TODO: make new component */}
            <div
              className={`card-body${userStatus === UserStatus.ONLINE ? ' staff-chat' : ''}`}
              id='kt_chat_contacts_body'
            >
              <div
                className='scroll-y h-100'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                data-kt-scroll-offset='0px'
              >
                {smsConversationsFinal.map((item: Conversation) => (
                  <div className='conversation-wrapper' key={item.id}>
                    <div
                      className={`conversation-item${
                        props.activeConv === item.id ? ' active' : ''
                      }`}
                    >
                      {/* TODO: fix UI */}
                      <div className='conv-info'>
                        <div className='d-flex align-items-center flex-grow-1'>
                          <div className='symbol symbol-45px symbol-circle'>
                            <span className='symbol-label bg-light-danger fs-6 fw-bolder'>
                              <i className='fa-solid fa-user-group text-danger'></i>
                            </span>
                          </div>

                          <Link
                            to={`/sms-chat/${item.id}`}
                            className='ms-5 fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 d-block text-truncate'
                            onClick={() => {
                              if (props.activeConv !== Number(props.convEdit.id)) {
                                props.setConvEdit({
                                  id: '',
                                  name: '',
                                })
                              }
                            }}
                          >
                            {item.name || item.phone}
                          </Link>
                        </div>

                        {Boolean(
                          unreadSMSMessages.find((userConv) => userConv.conversationId === item.id)
                            ?.unreadCount
                        ) && (
                          <span className='py-2 px-3 rounded-circle fs-8 text-danger bg-light-danger'>
                            {
                              unreadSMSMessages.find(
                                (userConv) => userConv.conversationId === item.id
                              )?.unreadCount
                            }
                          </span>
                        )}
                      </div>

                      <div className='ms-2 d-none d-md-block'>
                        <span className='text-muted fs-7 mb-1'>
                          {moment(item.updatedAt).fromNow()}
                        </span>
                      </div>
                    </div>

                    <div className='separator separator-dashed d-none'></div>
                  </div>
                ))}
              </div>
            </div>
            {userStatus === UserStatus.ONLINE && (
              <Button className='mx-7 mb-7 d-none d-md-block' onClick={() => setShowModal(true)}>
                + Add SMS Conversation
              </Button>
            )}
          </div>
        </div>

        <Outlet />
      </div>

      <Modal show={showModal} size='lg' centered onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create SMS conversation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId='formName' className='mt-3'>
            <Form.Label>Name:</Form.Label>
            <input
              className='form-control'
              onChange={(e) => {
                setSmsCreate({
                  ...smsCreate,
                  name: e.target.value,
                })
              }}
              value={smsCreate.name}
            />
          </Form.Group>
          <Form.Group controlId='formPhone' className='mt-3'>
            <Form.Label>Phone:</Form.Label>
            <input
              className={clsx('form-control', {'is-invalid': error.phone})}
              value={smsCreate.phone}
              onChange={(e) => {
                const regex = new RegExp(/^[0-9]*$/)
                if (
                  (smsCreate.phone.length === 10 && e.target.value.length > 10) ||
                  !regex.test(e.target.value)
                ) {
                  return
                }
                setSmsCreate({
                  ...smsCreate,
                  phone: e.target.value,
                })
                validate({
                  ...smsCreate,
                  phone: e.target.value,
                })
              }}
            />
            {error.phone && <div className='mt-3 text-danger'>{error.phone}</div>}
          </Form.Group>
          <Form.Group controlId='formMessage' className='mt-3'>
            <Form.Label>Message:</Form.Label>
            <input
              className={clsx('form-control', {'is-invalid': error.message})}
              value={smsCreate.message}
              onChange={(e) => {
                setSmsCreate({
                  ...smsCreate,
                  message: e.target.value,
                })
                validate({
                  ...smsCreate,
                  message: e.target.value,
                })
              }}
            />
            {error.message && <div className='mt-3 text-danger'>{error.message}</div>}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' disabled={loading} onClick={handleCreateSmsConversation}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Submit'
            )}
          </Button>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
