import {FC, useCallback, useEffect, useState} from 'react'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useCommonAlert} from '../../../common/CommonAlert'
import {deleteSubClinicStaff, getSubClinicStaffs} from '../../../apis'
import {SubClinicStaffActionsCell} from './SubClinicStaffActionsCell'
import {KTSVG} from '../../../../_metronic/helpers'

interface Props {
  clinicId: string
}

export const SubClinicStaffList: FC<Props> = (props) => {
  const {showAlert} = useCommonAlert()
  const location = useLocation()
  const navigate = useNavigate()

  const [staffs, setStaffs] = useState<any[]>([])

  const getStaffListCallback = useCallback(async () => {
    try {
      const data = await getSubClinicStaffs(props.clinicId)
      setStaffs(data)
    } catch (error) {
      console.log(error)
    }
  }, [props.clinicId])

  useEffect(() => {
    getStaffListCallback()
  }, [getStaffListCallback])

  useEffect(() => {
    const state: any = location.state
    if (state) {
      if (state.showAddedNoti) {
        showAlert('success', 'Staff added successfully')
      } else if (state.showNotFoundNoti) {
        showAlert('error', 'Staff not found')
      }
      navigate('', {state: null, replace: true})
    }
  }, []) //eslint-disable-line

  const handleStaffDelete = async (staffId: number) => {
    try {
      await deleteSubClinicStaff(staffId)
      setStaffs(staffs.filter((item) => item.id !== staffId))
      showAlert('success', 'Deleted successfully')
    } catch (err) {
      console.log(err)
      showAlert('error', 'Failed to delete')
    }
  }

  return (
    <div className='card'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Sub Clinic Staff List</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Show all staff</span>
        </h3>
        <div className='card-toolbar'>
          <Link to={`/clinic/sub-clinics/${props.clinicId}/add-staff`} className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Staff
          </Link>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-140px'>Name</th>
                <th className='min-w-120px'>Email</th>
                <th className='min-w-120px'>Phone Number</th>
                <th className='min-w-120px'>User Type</th>
                <th className='min-w-120px'>Role</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='text-gray-600 fw-bold'>
              {staffs.map((item: any) => (
                <tr key={item.id}>
                  <td>
                    {item.firstName} {item.lastName}
                  </td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td>{item.isClinicAdmin ? 'Clinic Admin' : 'Other Staff'}</td>
                  <td>{item.role.description}</td>
                  <td className='text-end'>
                    <SubClinicStaffActionsCell
                      staffId={item.id}
                      onDelete={(staffId) => handleStaffDelete(staffId)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}
