/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import _ from 'lodash'
import {useSocket} from '../../../modules/apps/chat/core/Socket'
import {useAuth} from '../../../modules/auth'
import {getSMSConversation, udpateSMSConversationName} from '../../../apis'
import {SMSChatInner} from './SMSChatInner'
import {useCommonAlert} from '../../../common/CommonAlert'
import {Button, Modal} from 'react-bootstrap'

interface Props {
  onInit: (id: string) => void
  setConvEdit: any
}

export const SMSConversationDetails: FC<Props> = (props) => {
  const {twilioSocket} = useSocket()
  const {showAlert} = useCommonAlert()
  const {id} = useParams()
  const {unreadSMSMessages} = useAuth()

  const [showModal, setShowModal] = useState(false)
  const [currConvName, setCurrConvName] = useState('')

  const [conversationData, setConversationData] = useState<any>({})

  const getConversationCallback = useCallback(async () => {
    try {
      const data = await getSMSConversation(id!)
      setConversationData(data)
      setCurrConvName(data.name)
    } catch (error) {
      console.log(error)
    }
  }, [id])

  useEffect(() => {
    getConversationCallback()
  }, [getConversationCallback])

  useEffect(() => {
    props.onInit(id || '')
  }, [props, id])

  const currConvUnreadCount = useMemo(
    () =>
      unreadSMSMessages.find((item) => item.conversationId === parseInt(id || '0'))?.unreadCount ||
      0,
    [unreadSMSMessages, id]
  )

  const readMsgCallback = useCallback(() => {
    if (currConvUnreadCount) {
      twilioSocket.emit('read-msg', id)
    }
  }, [currConvUnreadCount, twilioSocket, id])

  const debouncedReadMsg = _.debounce(readMsgCallback, 1000, {
    leading: true,
    trailing: false,
  })

  useEffect(() => {
    debouncedReadMsg()
  }, [debouncedReadMsg])

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      debouncedReadMsg()
    }
  }

  const handleUserActivity = () => {
    debouncedReadMsg()
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    document.addEventListener('mousemove', handleUserActivity)
    document.addEventListener('keydown', handleUserActivity)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      document.removeEventListener('mousemove', handleUserActivity)
      document.removeEventListener('keydown', handleUserActivity)
    }
  }, []) //eslint-disable-line

  const sendInviteMessage = () => {
    try {
      twilioSocket.emit('send-invite-link', id)
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed to send invite, please try again later')
    }
  }

  const handleChangeName = async () => {
    try {
      const convData = await udpateSMSConversationName(id || '', currConvName)
      setConversationData({...convData})
      props.setConvEdit({
        id: convData.id,
        name: convData.name,
      })
      showAlert('success')
      setShowModal(false)
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed to change')
    }
  }

  return (
    <div className='flex-column col-8 flex-lg-row-fluid ms-lg-7 ms-xl-10 ms-6'>
      <div className='card' id='kt_chat_messenger'>
        <div className='card-header align-items-center' id='kt_chat_messenger_header'>
          <div className='card-title align-items-center w-75 text-start'>
            <div className='symbol-group symbol-hover'></div>
            <div className='d-flex justify-content-center flex-column me-3 w-100'>
              <p className='fs-4 fw-bolder text-gray-900 me-1 mb-2 lh-1 text-truncate'>
                {conversationData.name || conversationData.phone}
              </p>
            </div>
          </div>

          <div className='card-toolbar'>
            {/* Dropdown button */}
            <div className='me-n3 ms-2'>
              <button
                className='btn btn-sm btn-icon btn-active-light-primary'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <i className='bi bi-three-dots fs-2'></i>
              </button>
              <div
                className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-250px py-3'
                data-kt-menu='true'
              >
                <div className='menu-item px-3'>
                  <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                    SMS Chat Actions
                  </div>
                </div>

                <div className='separator mb-3 opacity-75' />

                <div className='menu-item px-3'>
                  <span className='menu-link px-3' onClick={() => setShowModal(true)}>
                    Change SMS conversation name
                  </span>
                </div>

                <div className='menu-item px-3'>
                  <span className='menu-link px-3' onClick={() => sendInviteMessage()}>
                    Send this patient register link
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <SMSChatInner conversationId={id!} />
      </div>

      {/* Change name modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change Name Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>SMS Conversation Name</h5>
          <input
            className='form-control form-control-solid'
            placeholder='SMS Conversation Name'
            value={currConvName}
            onChange={(e) => {
              setCurrConvName(e.target.value)
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!currConvName} onClick={handleChangeName}>
            Save
          </Button>

          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
