import {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {components} from 'react-select'
import AsyncSelect from 'react-select/async'
import {getContactsByClinic} from '../../../app/apis'
import {debounce} from 'lodash'

interface Props {
  show: boolean
  onSubmit: (selectedContact: any) => void
  onHide: () => void
}

export const SelectContactModal: React.FC<Props> = (props) => {
  const [selectedContact, setSelectedContact] = useState<any>()
  const [contactList, setContactList] = useState<any[]>([])

  const getClinicContactsCallback = useCallback(async () => {
    try {
      const data = await getContactsByClinic('')
      setContactList(data.data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getClinicContactsCallback()
  }, [getClinicContactsCallback])

  const _loadContactsOptions = (query: string, callback: any) => {
    getContactsByClinic(query).then((resp) => callback(resp.data))
  }

  const loadContactsOptions = debounce(_loadContactsOptions, 300)

  const ContactOption = (props: any) => (
    <components.Option {...props}>
      <p className='mb-0'>{`${props.data?.practiceName ? `${props.data?.practiceName} - ` : ''}${
        props.data?.fullName
      } - ${props.data?.specialty} - ${props.data?.phone}`}</p>
    </components.Option>
  )

  return (
    <Modal show={props.show} size='lg' centered onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Send contact information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Select contact to send</h4>
        <AsyncSelect
          loadOptions={loadContactsOptions}
          placeholder='Search contact...'
          components={{Option: ContactOption}}
          value={selectedContact}
          onChange={(value) => {
            setSelectedContact(value)
          }}
          getOptionValue={(opt) => opt.id}
          getOptionLabel={(opt) =>
            `${opt.practiceName ? `${opt.practiceName} - ` : ''}${opt.fullName}`
          }
          defaultOptions={contactList}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: theme.colors.primary50,
            },
          })}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='primary'
          onClick={() => {
            props.onSubmit(selectedContact)
          }}
        >
          Submit
        </Button>
        <Button variant='secondary' onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
