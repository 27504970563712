import {axiosInstace} from '../common/axiosInstace'

export const getUsersCount = async () => {
  const res = await axiosInstace.get('/users/count')
  return res.data
}

export const deleteUser = async (id: number) => {
  const res = await axiosInstace.delete(`/users/${id}`)
  return res.data
}

export const addUser = async (data: any) => {
  const res = await axiosInstace.post('/users', data)
  return res.data
}

export const editUser = async (id: string, data: any) => {
  const res = await axiosInstace.put(`/users/${id}`, data)
  return res.data
}

export const updateUserPass = async (id: number, data: any) => {
  const res = await axiosInstace.post(`/users/${id}/update-password`, data)
  return res.data
}

export const getUser = async (id: string) => {
  const res = await axiosInstace.get(`/users/${id}`)
  return res.data
}

export const clinicAdminAddStaff = async (data: any) => {
  const res = await axiosInstace.post('/users/staff-clinic/staff', data)
  return res.data
}

export const clinicAdminUpdateStaff = async (staffId: string, data: any) => {
  const res = await axiosInstace.put(`/users/staff-clinic/staff/${staffId}`, data)
  return res.data
}

export const clinicAdminUpdateStaffPass = async (staffId: number, data: any) => {
  const res = await axiosInstace.put(`/users/staff-clinic/staff/${staffId}/update-password`, data)
  return res.data
}

export const clinicAdminDeleteStaff = async (staffId: number) => {
  const res = await axiosInstace.delete(`/users/staff-clinic/staff/${staffId}`)
  return res.data
}

export const getUserSignature = async () => {
  const res = await axiosInstace.get(`/users/signature-array-buffer`, {
    responseType: 'arraybuffer',
  })
  return res.data
}

export const checkPin = async (pin: string) => {
  const res = await axiosInstace.post(`/users/check-pin`, {pin})
  return res.data
}
