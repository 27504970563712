import {axiosInstace} from '../common/axiosInstace'
import {
  ChangePasswordDto,
  ChangePinDto,
  GetAvatarUploadUrlDto,
  SetupPinDto,
  UpdateProfileDto,
} from '../types'

export const getAvatarUploadConfig = async (data: GetAvatarUploadUrlDto) => {
  const res = await axiosInstace.get('/users/avatar-upload-config', {
    params: data,
  })
  return res.data
}

export const getSignatureUploadConfig = async (data: GetAvatarUploadUrlDto) => {
  const res = await axiosInstace.get('/users/signature-upload-config', {
    params: data,
  })
  return res.data
}

export const setSignatureUrl = async (bucket: string, key: string) => {
  const res = await axiosInstace.put('/users/update-signature', {bucket, key})
  return res.data
}

export const setAvatarUrl = async (bucket: string, key: string) => {
  const res = await axiosInstace.put('/users/update-avatar', {bucket, key})
  return res.data
}

export const updateProfile = async (data: UpdateProfileDto) => {
  const res = await axiosInstace.put(`/users/profile`, data)
  return res.data
}

export const changePassword = async (data: ChangePasswordDto) => {
  const res = await axiosInstace.post(`/users/change-password`, data)
  return res.data
}

export const changePin = async (data: ChangePinDto) => {
  const res = await axiosInstace.post(`/users/change-pin`, data)
  return res.data
}

export const setupPin = async (data: SetupPinDto) => {
  const res = await axiosInstace.post(`/users/setup-pin`, data)
  return res.data
}
