/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import moment from 'moment'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {patientGenOTP} from '../../../../apis'
import {toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {useCommonAlert} from '../../../../common/CommonAlert'

const loginSchema = Yup.object().shape({
  phone: Yup.string().required('Phone number is required'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  dob: Yup.date().required('DOB is required'),
})

export function Login() {
  const navigate = useNavigate()
  const location = useLocation()
  const {showAlert} = useCommonAlert()

  const initialValues = {
    phone: location.state?.phone || '',
    firstName: location.state?.firstName || '',
    lastName: location.state?.lastName || '',
    dob: location.state?.dob || moment().format('YYYY-MM-DD'),
  }

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        await patientGenOTP(values)
        navigate('./verify', {state: {...values, showOtpSent: true}})
      } catch (error) {
        console.error(error)
        setStatus('The patient details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    if (location.state?.showRegistered) {
      showAlert('success', 'Patient registered successfully')
      navigate('', {state: {...location.state, showRegistered: false}, replace: true})
    }
  }, [location.state?.showRegistered, showAlert]) //eslint-disable-line

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <img className='img-fluid' src={toAbsoluteUrl('/media/logos/logo.png')} alt='Logo' />
        <div className='text-gray-500 fw-semibold fs-1'>Login as a patient</div>
      </div>
      {/* begin::Heading */}

      {/* begin::Separator */}
      <div className='separator separator-content my-14'></div>
      {/* end::Separator */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fs-6 fw-bolder text-dark'>Phone Number</label>
        <input
          placeholder='Phone number'
          {...formik.getFieldProps('phone')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.phone && formik.errors.phone,
          })}
          autoComplete='off'
        />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone.toString()}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>First Name</label>
        <input
          autoComplete='off'
          {...formik.getFieldProps('firstName')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.firstName && formik.errors.firstName,
          })}
        />
        {formik.touched.firstName && formik.errors.firstName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstName.toString()}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Last Name</label>
        <input
          autoComplete='off'
          {...formik.getFieldProps('lastName')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.lastName && formik.errors.lastName,
          })}
        />
        {formik.touched.lastName && formik.errors.lastName && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastName.toString()}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>DOB</label>
        <input
          autoComplete='off'
          type='date'
          {...formik.getFieldProps('dob')}
          className={clsx('form-control bg-transparent', {
            'is-invalid': formik.touched.dob && formik.errors.dob,
          })}
        />
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5 mt-2'>
        <Link to='/auth/login' className='link-primary'>
          Staff login
        </Link>

        <Link to='/patient/auth/register' className='link-primary'>
          Register
        </Link>
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Generate OTP</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
