import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {AddClinicForm} from '../../components/AddClinicForm'

const addClinicBreadCrumbs: Array<PageLink> = [
  {
    title: 'Clinics',
    path: '/clinics/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const AddClinicsPageWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={addClinicBreadCrumbs}>Add Clinic</PageTitle>
    <AddClinicForm />
  </>
)
