import {debounce} from 'lodash'
import moment from 'moment'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {Link, Outlet, useNavigate} from 'react-router-dom'
import Select, {components} from 'react-select'
import AsyncSelect from 'react-select/async'
import {KTSVG, useDebounce} from '../../../../../_metronic/helpers'
import {
  getConversationsByUser,
  getPaginatedPatientsByClinic,
  getStaffClinicInfo,
} from '../../../../apis'
import {useCommonAlert} from '../../../../common/CommonAlert'
import {Conversation, ConversationType} from '../../../../common/types'
import {UnreadMessages, useAuth, UserStatus} from '../../../auth'
import {useSocket} from '../core/Socket'

interface Props {
  activeConv: number
}

moment.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s',
    s: '1m',
    ss: '1m',
    m: '1m',
    mm: '%dm',
    h: '1h',
    hh: '%dh',
    d: '1d',
    dd: '%dd',
    w: '1w',
    ww: '%dw',
    M: '1mo',
    MM: '%dmo',
    y: '1y',
    yy: '%dy',
  },
})

export const ChatLayout: FC<Props> = (props) => {
  const {socket} = useSocket()
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()
  const {userStatus, unreadMessages, setUnreadMessages, currentUser} = useAuth()

  const [conversations, setConversations] = useState<Conversation[]>([])
  const [convSearchStr, setConvSearchStr] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const debouncedConvSearchStr = useDebounce(convSearchStr, 300)
  const [convType, setConvType] = useState<ConversationType>(ConversationType.External)
  const [patientToChat, setPatientToChat] = useState<any>()
  const [clinicPatientList, setClinicPatientList] = useState<any>()
  const [selectedOrg, setSelectedOrg] = useState<any>()
  const [organizationList, setOrganizationList] = useState<any[]>([])

  const getOrganizationListCallback = useCallback(async () => {
    try {
      if (userStatus === UserStatus.ONLINE) {
        const data = await getStaffClinicInfo()
        setOrganizationList(data.organizations)
      }
    } catch (error) {
      console.log(error)
    }
  }, [userStatus])

  useEffect(() => {
    getOrganizationListCallback()
  }, [getOrganizationListCallback])

  useEffect(() => {
    const temp = [...conversations]
    if (selectedOrg && selectedOrg.id) {
      temp.forEach((item) => {
        if (item.organization && item.organization.id === selectedOrg.id) {
          item.show = true
        } else {
          item.show = false
        }
      })
    } else {
      temp.forEach((item) => {
        item.show = true
      })
    }
    setConversations(temp)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrg])

  const getClinicPatientsCallback = useCallback(async () => {
    try {
      if (userStatus === UserStatus.ONLINE) {
        const data = await getPaginatedPatientsByClinic('')
        setClinicPatientList(data.data)
      }
    } catch (error) {
      console.log(error)
    }
  }, [userStatus])

  useEffect(() => {
    getClinicPatientsCallback()
  }, [getClinicPatientsCallback])

  const getConversationsCallback = useCallback(async () => {
    try {
      const data = await getConversationsByUser(debouncedConvSearchStr)
      setConversations(data)
    } catch (error) {
      console.log(error)
    }
  }, [debouncedConvSearchStr])

  useEffect(() => {
    getConversationsCallback()
  }, [getConversationsCallback])

  useEffect(() => {
    socket.on('conversation-created', async (conv: Conversation) => {
      socket.emit('join-conv', conv.id)
      setUnreadMessages((prevUnreadMessages) => {
        const newConv: UnreadMessages = {
          conversationId: conv.id,
          unreadCount: 0,
        }

        return [newConv, ...prevUnreadMessages]
      })
      setConversations((convList) => [conv, ...convList])
      navigate(`${userStatus === UserStatus.PATIENT ? '/patient' : ''}/chat/${conv.id}`)
    })

    return () => {
      socket.off('conversation-created')
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    socket.on('conversation-found', async (conv: Conversation) => {
      if (!conversations.find((item) => item.id === conv.id)) {
        socket.emit('join-conv', conv.id)
        setUnreadMessages((prevUnreadMessages) => {
          const newConv: UnreadMessages = {
            conversationId: conv.id,
            unreadCount: 0,
          }

          return [newConv, ...prevUnreadMessages]
        })
        setConversations((convList) => [conv, ...convList])
      }
      navigate(`${userStatus === UserStatus.PATIENT ? '/patient' : ''}/chat/${conv.id}`)
    })

    return () => {
      socket.off('conversation-found')
    }
  }, [conversations]) //eslint-disable-line

  useEffect(() => {
    socket.on('new-conversation', async (conv: Conversation) => {
      setConversations((convList) => [conv, ...convList])
    })

    return () => {
      socket.off('new-conversation')
    }
  }, []) //eslint-disable-line

  useEffect(() => {
    socket.on('exception', async (err) => {
      showAlert('error', err.message)
    })
  }, []) //eslint-disable-line

  useEffect(() => {
    socket.on('conversation-new-msg', (conv: Conversation) => {
      const index = conversations.findIndex(({id}) => id === conv.id)
      if (index !== -1) {
        const temp = [...conversations]
        temp.unshift(...temp.splice(index, 1))
        temp[0].lastMessage = conv.lastMessage
        temp[0].updatedAt = conv.updatedAt

        setConversations(temp)
      }
    })

    return () => {
      socket.off('conversation-new-msg')
    }
  }, [socket, conversations])

  useEffect(() => {
    socket.on('conversation-new-ptcp', (conv: Conversation) => {
      const index = conversations.findIndex(({id}) => id === conv.id)
      if (index !== -1) {
        const temp = [...conversations]
        temp.unshift(...temp.splice(index, 1))
        temp[0].participantCount = conv.participantCount
        temp[0].name = conv.name
        temp[0].updatedAt = conv.updatedAt

        setConversations(temp)
      }
    })

    return () => {
      socket.off('conversation-new-ptcp')
    }
  }, [socket, conversations])

  // Create conversation
  const handleCloseModal = () => setShowModal(false)

  const handleCreateConversation = () => {
    const data = {
      type: convType,
      patientId: convType === ConversationType.External ? patientToChat?.id || 0 : 0,
    }

    try {
      socket.emit('createConversation', data)
    } catch (error) {
      console.log(error)
    }

    handleCloseModal()
  }

  const PeopleOption = (props: any) => (
    <components.Option {...props}>
      <div className='d-flex align-items-center'>
        <div className='symbol symbol-40px symbol-circle me-2'>
          <>
            {props.data.avatar ? (
              <img src={props.data.avatar} alt='avatar' className='object-fit-cover' />
            ) : (
              <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                {props.data.fullName[0] || ''}
              </span>
            )}
          </>
        </div>
        <div className='info-section'>
          <p className='fs-6 fw-bolder text-gray-900' style={{lineHeight: 1.2, marginBottom: 3}}>
            {props.data.fullName}
          </p>
          <p className='text-gray-600 mb-0' style={{fontSize: '12px'}}>
            {`${props.data.phone} - ${props.data.dob}`}
          </p>
          {Boolean(props.data.email) && (
            <p className='text-gray-600 mb-0' style={{fontSize: '12px'}}>
              {props.data.email}
            </p>
          )}
        </div>
      </div>
    </components.Option>
  )

  const _loadPeopleOptions = (query: string, callback: any) => {
    if (userStatus === UserStatus.ONLINE) {
      getPaginatedPatientsByClinic(query).then((resp) => callback(resp.data))
    } else {
      callback([])
    }
  }

  const loadPeopleOptions = debounce(_loadPeopleOptions, 300)

  return (
    <div className='chat-page'>
      <div className='d-flex flex-row'>
        <div className='flex-column col flex-lg-row-auto w-100px w-lg-300px w-xl-400px mb-lg-0'>
          <div className='card card-conv-list'>
            <div className='card-header d-none d-md-block' id='kt_chat_contacts_header'>
              <form className='w-100 position-relative' autoComplete='off'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                />

                <input
                  type='text'
                  className='form-control form-control-solid px-15'
                  placeholder='Search conversations...'
                  value={convSearchStr}
                  onChange={(e) => setConvSearchStr(e.target.value)}
                />
              </form>
            </div>

            {userStatus === UserStatus.ONLINE && !currentUser?.clinic?.parentClinic && (
              <div className='p-4'>
                <Select
                  isClearable
                  options={organizationList}
                  placeholder='Choose Organization'
                  value={selectedOrg}
                  onChange={(value) => {
                    setSelectedOrg(value)
                  }}
                  getOptionValue={(opt) => opt.id}
                  getOptionLabel={(opt) => opt.name}
                />
              </div>
            )}

            {/* TODO: make new component */}
            <div
              className={`card-body${userStatus === UserStatus.ONLINE ? ' staff-chat' : ''}`}
              id='kt_chat_contacts_body'
            >
              <div
                className='scroll-y h-100'
                data-kt-scroll='true'
                data-kt-scroll-activate='{default: true}'
                data-kt-scroll-max-height='auto'
                data-kt-scroll-dependencies='#kt_header, #kt_toolbar, #kt_footer, #kt_chat_contacts_header'
                data-kt-scroll-wrappers='#kt_content, #kt_chat_contacts_body'
                data-kt-scroll-offset='0px'
              >
                {conversations.map((item: Conversation) => (
                  <div className='conversation-wrapper' key={item.id}>
                    <div
                      className={`conversation-item${
                        props.activeConv === item.id ? ' active' : ''
                      }${item.show === false ? ' d-none' : ''}`}
                    >
                      {/* TODO: fix UI */}
                      <div className='conv-info'>
                        <div className='d-flex align-items-center flex-grow-1'>
                          <div className='symbol symbol-45px symbol-circle'>
                            {item.participantCount === 2 ? (
                              <>
                                {item.image ? (
                                  <img src={item.image} alt='avatar' className='object-fit-cover' />
                                ) : (
                                  <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                                    {item.name[0]}
                                  </span>
                                )}
                              </>
                            ) : (
                              <span className='symbol-label bg-light-danger fs-6 fw-bolder'>
                                <i className='fa-solid fa-user-group text-danger'></i>
                              </span>
                            )}
                          </div>

                          <Link
                            to={`${userStatus === UserStatus.PATIENT ? '/patient' : ''}/chat/${
                              item.id
                            }`}
                            className='ms-5 fs-5 fw-bolder text-gray-900 text-hover-primary mb-2 d-block text-truncate'
                          >
                            {item.primaryPatient && item.primaryPatient.isVerified ? (
                              <i className='fa-regular fa-circle-check' />
                            ) : (
                              <></>
                            )}{' '}
                            {item.name}
                          </Link>
                        </div>

                        {Boolean(
                          unreadMessages.find((userConv) => userConv.conversationId === item.id)
                            ?.unreadCount
                        ) && (
                          <span className='py-2 px-3 rounded-circle fs-8 text-danger bg-light-danger'>
                            {
                              unreadMessages.find((userConv) => userConv.conversationId === item.id)
                                ?.unreadCount
                            }
                          </span>
                        )}
                      </div>

                      <div className='ms-2 d-none d-md-block'>
                        <span className='text-muted fs-7 mb-1'>
                          {moment(item.updatedAt).fromNow()}
                        </span>
                      </div>
                    </div>

                    <div className='separator separator-dashed d-none'></div>
                  </div>
                ))}
              </div>
            </div>

            {userStatus === UserStatus.ONLINE && (
              <Button className='mx-7 mb-7 d-none d-md-block' onClick={() => setShowModal(true)}>
                + Add Conversation
              </Button>
            )}
          </div>
        </div>

        <Outlet />
      </div>

      {/* TODO: New component for this modal */}
      <Modal show={showModal} size='lg' centered onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create conversation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='mb-3'>Select conversation type</h4>
          <Form.Check
            inline
            label='External (With patient)'
            type='radio'
            checked={convType === ConversationType.External}
            onChange={() => {
              setConvType(ConversationType.External)
            }}
            id={ConversationType.External}
          />
          <Form.Check
            inline
            label='Internal (Between staffs only)'
            type='radio'
            checked={convType === ConversationType.Internal}
            onChange={() => {
              setConvType(ConversationType.Internal)
            }}
            id={ConversationType.Internal}
          />

          {convType === ConversationType.External && (
            <>
              <h4 className='mt-3'>Select patient to chat</h4>
              <AsyncSelect
                loadOptions={loadPeopleOptions}
                placeholder='Search patient to chat...'
                components={{Option: PeopleOption}}
                value={patientToChat}
                onChange={(value) => {
                  setPatientToChat(value)
                }}
                getOptionValue={(opt) => opt.id}
                getOptionLabel={(opt) => opt.fullName}
                defaultOptions={clinicPatientList}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: theme.colors.primary50,
                  },
                })}
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleCreateConversation}>
            Submit
          </Button>
          <Button variant='secondary' onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
