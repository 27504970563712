import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import {useCommonAlert} from '../../../common/CommonAlert'
import {getStaffClinicInfo, staffEditPatient} from '../../../apis'
import Select from 'react-select'

const editProfileSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Wrong email format').optional(),
  phone: Yup.string()
    .required('Phone is required')
    .length(10)
    .matches(/^[0-9]*$/, 'Phone contains only digits'),
  dob: Yup.date().required('DOB is required'),
})

interface Props {
  patient: any
}

export const ClinicEditPatientForm: FC<Props> = (props) => {
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [organizationsOpts, setOrganizationsOpts] = useState<any>([])

  const getDataSelect = useCallback(async () => {
    try {
      const data = await getStaffClinicInfo()
      const organizations = data?.organizations
      if (organizations) {
        setOrganizationsOpts(
          organizations.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            }
          })
        )
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getDataSelect()
  }, [getDataSelect])

  const formik = useFormik({
    initialValues: {
      firstName: props.patient?.firstName || '',
      lastName: props.patient?.lastName || '',
      email: (props.patient?.email as string) || '',
      phone: (props.patient?.phone as string) || '',
      dob: props.patient?.dob || '',
      organization: props.patient?.organization
        ? {value: props.patient.organization.id, label: props.patient.organization.name}
        : null,
    },
    validationSchema: editProfileSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        const payload = {...values}
        if (payload.organization) {
          payload.organization = payload.organization?.value
        }
        await staffEditPatient(props.patient.id, payload)
        return navigate(`/clinic/patients`, {state: {showEditedPatient: props.patient.fullName}})
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Edit Patient</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          <h4 className='card-title my-3'>Patient Detail</h4>
          <Row>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='FNameInput'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder='First Name'
                {...formik.getFieldProps('firstName')}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='LNameInput'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder='Last Name'
                {...formik.getFieldProps('lastName')}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='EmailInput'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Email'
                {...formik.getFieldProps('email')}
                autoComplete='new-email'
                className={clsx({'is-invalid': Boolean(formik.errors.email)})}
              />
              {Boolean(formik.errors.email) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='PhoneInput'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder='Ex: 9123456789'
                {...formik.getFieldProps('phone')}
                autoComplete='off'
                className={clsx({'is-invalid': Boolean(formik.errors.phone)})}
              />
              {Boolean(formik.errors.phone) && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='dobInput'>
              <Form.Label>DOB</Form.Label>
              <Form.Control
                type='date'
                autoComplete='off'
                {...formik.getFieldProps('dob')}
                disabled
              />
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3'>
              <Form.Label>Select Organization</Form.Label>
              <Select
                isClearable
                options={organizationsOpts}
                placeholder='Select Organization'
                value={formik.values.organization}
                onChange={(value) => {
                  formik.setFieldValue('organization', value)
                }}
              />
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end py-6 px-9'>
          <Link to={`/clinic/patients`} className='btn btn-secondary me-5'>
            Cancel
          </Link>

          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}
