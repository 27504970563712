import {toAbsoluteUrl} from '../../_metronic/helpers'

export const defaultAvatar = toAbsoluteUrl('/media/avatars/blank.png')
export const defaultSignature = toAbsoluteUrl('/media/auth/signature.jpg')

export const getPageNumbersToShow = (
  currentPage: number,
  totalPages: number,
  maxPageToShow: number = 5
) => {
  const halfMaxPageToShow = Math.floor(maxPageToShow / 2)

  const pageNumbers = []
  let startPage, endPage

  if (totalPages <= maxPageToShow) {
    startPage = 1
    endPage = totalPages
  } else if (currentPage <= halfMaxPageToShow) {
    startPage = 1
    endPage = maxPageToShow
  } else if (currentPage + halfMaxPageToShow >= totalPages) {
    startPage = totalPages - maxPageToShow + 1
    endPage = totalPages
  } else {
    startPage = currentPage - halfMaxPageToShow
    endPage = currentPage + halfMaxPageToShow
  }

  if (startPage > 1) {
    pageNumbers.push(1)
    if (startPage > 2) {
      pageNumbers.push('...')
    }
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i)
  }

  if (endPage < totalPages) {
    if (endPage < totalPages - 1) {
      pageNumbers.push('...')
    }
    pageNumbers.push(totalPages)
  }

  return pageNumbers
}

export const API_URL =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:3333'

export const ptToPxRatio = 4 / 3

export enum CallLogType {
  Appointment = 'appointment',
  RX = 'rx',
  Referral = 'referral',
  Immigration = 'immigration',
  PA = 'pa',
  Urgent = 'urgent',
  New = 'New',
  Other = 'Other',
  Labor = 'Labor',
  Medical_rec = 'Medical_rec',
  Fax_req = 'Fax_req',
  Medication = 'Medication',
}

export const callLogTypeOptions = [
  {value: CallLogType.Appointment, label: 'Appointment'},
  {value: CallLogType.RX, label: 'RX Refill'},
  {value: CallLogType.Referral, label: 'Referral'},
  {value: CallLogType.Immigration, label: 'Immigration'},
  {value: CallLogType.PA, label: 'PA'},
  {value: CallLogType.Urgent, label: 'URGENT'},
  {value: CallLogType.New, label: 'New Patient'},
  {value: CallLogType.Other, label: 'Other'},
  {value: CallLogType.Labor, label: 'Laboratory orders/request'},
  {value: CallLogType.Medical_rec, label: 'Medical records'},
  {value: CallLogType.Fax_req, label: 'Fax orders/request'},
  {value: CallLogType.Medication, label: 'Medication question'},
]

export const callLogLocationOptions = [
  {value: 'a', label: 'a'},
  {value: 'b', label: 'b'},
  {value: 'c', label: 'c'},
  {value: 'd', label: 'd'},
  {value: 'e', label: 'e'},
  {value: 'f', label: 'f'},
  {value: 'g', label: 'g'},
  {value: 'h', label: 'h'},
]
