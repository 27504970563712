import {axiosInstace} from '../common/axiosInstace'

export const getSMSConversationList = async () => {
  const res = await axiosInstace.get(`/twilio-actions/sms/conversations`)
  return res.data
}

export const getSMSConversation = async (id: string) => {
  const res = await axiosInstace.get(`/twilio-actions/sms/conversations/${id}`)
  return res.data
}

export const getSMSConvMessages = async (id: string) => {
  const res = await axiosInstace.get(`/twilio-actions/sms/conversations/${id}/messages`)
  return res.data
}

export const udpateSMSConversationName = async (id: string, name: string) => {
  const res = await axiosInstace.put(`/twilio-actions/sms/conversations/${id}/name`, {
    name,
  })
  return res.data
}

export type SMSConversationBody = {
  name?: string
  phone: string
  message: string
}

export const createSMSConversation = async (body: SMSConversationBody) => {
  const res = await axiosInstace.post(`/twilio-actions/sms/conversations`, body)
  return res.data
}
