import {Button, Image, Modal} from 'react-bootstrap'

interface Props {
  show: boolean
  fileName?: string
  tempUrl: string
  fileMimeType?: string
  onSubmit: () => void
  onHide: () => void
}

export const AvatarPreviewModal: React.FC<Props> = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Update Avatar</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex'>
          <h5>File Name:</h5>
          <p className='ms-1'>{props.fileName}</p>
        </div>

        <Image fluid src={props.tempUrl} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onSubmit()}>Update</Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
