import {useEffect, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {updateFaxName, updateOutgoingFaxName} from '../../../apis'
import {useCommonAlert} from '../../../common/CommonAlert'

interface Props {
  show: boolean
  faxId: any
  initFaxName: string
  onSubmit: (faxId: any, faxName: string) => void
  onHide: () => void
  type?: 'incoming' | 'outgoing'
}

export const EditFaxNameModal: React.FC<Props> = (props) => {
  const {showAlert} = useCommonAlert()

  const [faxName, setFaxName] = useState<string>(props.initFaxName)

  useEffect(() => {
    setFaxName(props.initFaxName)
  }, [props.initFaxName])

  const onUpdateName = async () => {
    try {
      let data: any
      if (props.type && props.type === 'outgoing') {
        data = await updateOutgoingFaxName(props.faxId, faxName)
      } else {
        data = await updateFaxName(props.faxId, faxName)
      }
      showAlert('success')
      props.onSubmit(props.faxId, data.fileName)
    } catch (error) {
      console.log(error)
      showAlert('error')
    }
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Fax Name Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>Fax name:</h5>
        <Form.Control
          placeholder='Enter your updated fax name'
          value={faxName}
          required
          onChange={(e) => setFaxName(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => onUpdateName()}>Save</Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
