import moment from 'moment'
import {FC, Fragment, useCallback, useEffect, useState} from 'react'
import {Button, Modal, Pagination, Spinner} from 'react-bootstrap'
import {
  createTag,
  deleteFax,
  getBlobFaxFile,
  getDnisListByClinic,
  getFaxTagsWithCountByClinic,
  getPaginatedFaxesByClinic,
  removeTag,
  syncFaxesForClinic,
  updateTag,
} from '../../apis'
import {getPageNumbersToShow} from '../../common/helpers'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {EditTagsModal} from './modal/EditTagsModal'
import {useCommonAlert} from '../../common/CommonAlert'
import {KTSVG} from '../../../_metronic/helpers'
import {IncomingFax, Tag} from '../../common/types'
import Select from 'react-select'
import {useAuth} from '../../modules/auth'

interface Props {
  lastSyncedAt: Date | null
}

export const IncomingFaxList: FC<Props> = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const {showAlert} = useCommonAlert()
  const {currentUser} = useAuth()

  const [faxes, setFaxes] = useState<IncomingFax[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageNumbers, setPageNumbers] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)
  const [lastSyncedAt, setLastSyncedAt] = useState<Date | null>(props.lastSyncedAt)
  const [loading, setLoading] = useState(false)
  const [selectedFaxId, setSelectedFaxId] = useState<string>('')
  const [tagsOfSelectedFax, setTagsOfSelectedFax] = useState<Tag[]>([])
  const [showEditTagsModal, setShowEditTagsModal] = useState<boolean>(false)
  const [clinicTags, setClinicTags] = useState<Tag[]>([])
  const [tagsFilter, setTagsFilter] = useState<number[]>([])
  const [showConfirmDelModal, setShowConfirmDelModal] = useState(false)
  const [search, setSearch] = useState('')
  const [selectedTag, setSelectedTag] = useState<Tag>()
  const [showConfirmDelTagModal, setShowConfirmDelTagModal] = useState(false)
  const [dnisList, setDnisList] = useState<any[]>([])
  const [dnisFilter, setDnisFilter] = useState<any>()
  const [showTableTagList, setShowTableTagList] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [tableClinicTag, setTableClinicTag] = useState<Tag[]>([])
  const [showModalWarningUpdateTag, setShowModalWarningUpdateTag] = useState(false)
  const [errorInput, setErrorInput] = useState('')

  useEffect(() => {
    if (location.state) {
      if (location.state.showDeletedNoti) {
        showAlert('success', 'Fax has been deleted')
      }
      if (location.state.page) {
        setCurrentPage(location.state.page)
        setTagsFilter(location.state.tags)
        setSearch(location.state.search)
      }
      navigate('', {state: {}, replace: true})
    }
  }, [location.state, navigate, showAlert])

  useEffect(() => {
    setLastSyncedAt(props.lastSyncedAt)
  }, [props.lastSyncedAt])

  const getIncomingFaxesCallback = useCallback(async () => {
    try {
      const data = await getPaginatedFaxesByClinic(
        currentPage,
        20,
        tagsFilter,
        search,
        dnisFilter?.value
      )
      setFaxes(data.data)
      setTotalPages(data.pagination.totalPages)
      setPageNumbers(getPageNumbersToShow(currentPage, data.pagination.totalPages))
    } catch (error) {
      console.log(error)
    }
  }, [currentPage, tagsFilter, search, dnisFilter])

  useEffect(() => {
    getIncomingFaxesCallback()
  }, [getIncomingFaxesCallback])

  const getClinicTagsAndDnisCallback = useCallback(async () => {
    try {
      const data = await getFaxTagsWithCountByClinic()
      setClinicTags(data)
      const arr = data.filter((item: any) => item.id)
      setTableClinicTag(arr)
      const res = await getDnisListByClinic()
      if (Array.isArray(res) && res.length >= 2) {
        const optionList = res.map((item) => ({
          value: item,
          label: item,
        }))
        optionList.unshift({
          value: '',
          label: 'All',
        })
        setDnisList(optionList)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getClinicTagsAndDnisCallback()
  }, [getClinicTagsAndDnisCallback])

  const onRemoveTag = async () => {
    try {
      if (selectedTag) {
        setLoading(true)
        await removeTag(selectedTag.id)
        if (tagsFilter.includes(selectedTag.id)) {
          setTagsFilter(tagsFilter.filter((item) => item !== selectedTag.id))
        } else {
          getIncomingFaxesCallback()
        }
        getClinicTagsAndDnisCallback()
        setShowConfirmDelTagModal(false)
        setLoading(false)
        showAlert('success', 'Tag has been deleted')
      }
    } catch (error) {
      setLoading(false)
      showAlert('error', 'Failed to delete')
      console.log(error)
    }
  }

  const downloadFaxFile = async (faxId: string, fileName: string) => {
    try {
      const res = await getBlobFaxFile(faxId)

      // create file link in browser's memory
      const href = URL.createObjectURL(res.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (error) {
      showAlert('error', 'Failed to download')
      console.log(error)
    }
  }

  const handleSyncFaxes = async () => {
    try {
      setLoading(true)
      const resData = await syncFaxesForClinic()
      setLastSyncedAt(resData.updatedAt)
      if (currentPage !== 1) {
        setCurrentPage(1)
      } else {
        await getIncomingFaxesCallback()
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      showAlert('error', 'Failed to sync')
      console.log(error)
    }
  }

  const deleteFaxFile = async (faxId: string) => {
    try {
      setLoading(true)
      await deleteFax(faxId)
      setShowConfirmDelModal(false)
      if (currentPage !== 1) {
        setCurrentPage(1)
      } else {
        await getIncomingFaxesCallback()
      }
      await getClinicTagsAndDnisCallback()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      showAlert('error', 'Failed to delete')
      console.log(error)
    }
  }

  const handleClickBtnCreateTag = () => {
    const newItem = {
      id: 0,
      name: '',
    }
    const arr = [...tableClinicTag]
    setTableClinicTag([newItem, ...arr])
    setIsEdit(true)
    setSelectedTag(newItem)
  }

  const validate = useCallback((item: string) => {
    if (!item) {
      setErrorInput('Name is required')
    } else {
      setErrorInput('')
    }
  }, [])

  const handleCreateOrUpdate = async () => {
    if (selectedTag) {
      try {
        setLoading(true)
        if (!selectedTag.id) {
          await createTag(selectedTag.name)
        } else {
          await updateTag(selectedTag)
          setShowModalWarningUpdateTag(false)
        }
        showAlert('success', 'Tag has been ' + (!selectedTag.name ? 'created' : 'updated'))
        getClinicTagsAndDnisCallback()
        setIsEdit(false)
        setSelectedTag(undefined)
        setLoading(false)
      } catch (error: any) {
        setLoading(false)
        showAlert(
          'error',
          error && String(error).includes('409')
            ? 'Tag name already exists'
            : 'Failed to ' + (!selectedTag.id ? 'created' : 'updated')
        )
        console.log(error)
      }
    }
  }

  return (
    <div className='card'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 pb-3'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Incoming Faxes</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Last sync: {lastSyncedAt ? moment.utc(lastSyncedAt).calendar() : 'null'}
          </span>
        </h3>

        <div className='card-toolbar'>
          <Button onClick={handleSyncFaxes} disabled={loading}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Sync now'
            )}
          </Button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute mt-3 ms-5'
        />

        <input
          className='form-control form-control-solid ps-15 mb-4'
          placeholder='Search by fax name/phone number'
          style={{maxWidth: 400}}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value)
            setCurrentPage(1)
          }}
        />

        {Boolean(dnisList.length) && (
          <div className='w-50'>
            <h5>Select dnis:</h5>
            <Select
              options={dnisList}
              className='mb-5 z-20'
              placeholder='Select dnis'
              value={dnisFilter}
              onChange={(value) => {
                setDnisFilter(value)
              }}
            />
          </div>
        )}

        {Boolean(clinicTags.length) && (
          <div className='mb-3'>
            <p className='fw-bold mb-1'>Tags filter:</p>
            <div className='d-flex'>
              <div className='d-flex flex-grow-1 flex-wrap gap-3'>
                {clinicTags.map((t) => (
                  <span className={`position-relative`} key={t.id}>
                    <span
                      className={`cursor-pointer badge badge-pill ${
                        tagsFilter.includes(t.id) ? 'badge-primary' : 'badge-light'
                      }`}
                      onClick={() => {
                        const i = tagsFilter.findIndex((item) => item === t.id)
                        if (i === -1) {
                          setTagsFilter([...tagsFilter, t.id])
                        } else {
                          const temp = [...tagsFilter]
                          temp.splice(i, 1)
                          setTagsFilter(temp)
                        }
                      }}
                    >
                      {t.name} ({t.faxCount})
                    </span>
                  </span>
                ))}
              </div>
              {Boolean(currentUser?.isClinicAdmin) && (
                <Button className='text-nowrap' onClick={() => setShowTableTagList(true)}>
                  Manage Tags
                </Button>
              )}
            </div>
          </div>
        )}

        {/* begin::Table container */}
        <div className='table-responsive position-relative'>
          {loading && (
            <div className='loading-filter-wrapped'>
              <div className='loading-filter'>
                <Spinner animation='border' variant='primary' />
              </div>
            </div>
          )}
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-120px'>File Name</th>
                <th className='min-w-140px'>Caller Id</th>
                <th className='min-w-100px'>Received Date</th>
                <th className=''>Pages</th>
                <th className='min-w-140px'>Notes</th>
                <th className='min-w-120px'>DNIS</th>
                <th className='min-w-120px'>Tags</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='text-gray-600 fw-bold'>
              {faxes.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.fileName}</td>
                  <td>{item.callerId}</td>
                  {/* Using .utc() to render correct time (Don't change time per timezone) */}
                  <td>{moment.utc(item.receivedDate).format('YYYY-MM-DD HH:mm')}</td>
                  <td>{item.pageCount}</td>
                  <td>{item.notes}</td>
                  <td>{item.dnis}</td>
                  <td>
                    {item.faxTags.length ? item.faxTags.map((t: any) => t.name).join(', ') : ''}
                  </td>
                  <td>
                    <div className='d-flex align-items-center justify-content-end'>
                      <button
                        className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary me-1'
                        type='button'
                        onClick={() => {
                          setSelectedFaxId(item.faxId)
                          setTagsOfSelectedFax(item.faxTags)
                          setShowEditTagsModal(true)
                        }}
                      >
                        <i className='fa-solid fa-tag' />
                      </button>

                      <button
                        className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary me-1'
                        type='button'
                        onClick={() => {
                          setSelectedFaxId(item.faxId)
                          setShowConfirmDelModal(true)
                        }}
                      >
                        <i className='fa-solid fa-trash' />
                      </button>

                      <Link
                        to={`${item.faxId}`}
                        relative='path'
                        state={{page: currentPage, tags: tagsFilter, search}}
                        className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary me-1'
                        type='button'
                      >
                        <i className='fa-regular fa-eye' />
                      </Link>

                      <button
                        className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary'
                        type='button'
                        onClick={() => downloadFaxFile(item.faxId, item.fileName)}
                      >
                        <i className='fa-solid fa-download' />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        <EditTagsModal
          onCancel={() => setShowEditTagsModal(false)}
          faxId={selectedFaxId}
          tags={tagsOfSelectedFax}
          onSubmit={() => {
            setShowEditTagsModal(false)
            getIncomingFaxesCallback()
            getClinicTagsAndDnisCallback()
          }}
          show={showEditTagsModal}
        />

        <Modal show={showConfirmDelModal} onHide={() => setShowConfirmDelModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to delete this fax?</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={() => deleteFaxFile(selectedFaxId)}>
              Delete
            </Button>
            <Button variant='secondary' onClick={() => setShowConfirmDelModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showTableTagList}
          onHide={() => {
            setShowTableTagList(false)
            setSelectedTag(undefined)
            setIsEdit(false)
            if (selectedTag && !selectedTag.id) {
              const arr = [...tableClinicTag]
              arr.shift()
              setTableClinicTag(arr)
            }
          }}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title> Manage Tags</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='hstack mb-6'>
              <Button className='text-nowrap ms-auto' onClick={handleClickBtnCreateTag}>
                Create Tag
              </Button>
            </div>

            <div className='table-responsive position-relative' style={{height: '70vh'}}>
              {loading && (
                <div className='loading-filter-wrapped'>
                  <div className='loading-filter'>
                    <Spinner animation='border' variant='primary' />
                  </div>
                </div>
              )}
              {/* begin::Table */}
              <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
                {/* begin::Table head */}
                <thead>
                  <tr className='fw-bold text-muted'>
                    <th className='min-w-120px'>Name</th>
                    <th className='min-w-140px'>Actions</th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody className='text-gray-600 fw-bold'>
                  {tableClinicTag.map((t) => (
                    <tr key={t.id} className='flex item-center'>
                      <td className='w-100'>
                        {selectedTag && selectedTag.id === t.id && isEdit ? (
                          <div>
                            <input
                              className='form-control form-control-solid'
                              placeholder='Enter tag name...'
                              style={{maxWidth: '100%'}}
                              value={selectedTag.name}
                              onChange={(e) => {
                                setSelectedTag({
                                  ...selectedTag,
                                  name: e.target.value,
                                })
                                validate(e.target.value)
                              }}
                              required
                              disabled={loading}
                            />
                            {errorInput && <p className='m-0 text-danger'>{errorInput}</p>}
                          </div>
                        ) : (
                          t.name
                        )}
                      </td>
                      <td>
                        <div className='d-flex align-items-center'>
                          {selectedTag && selectedTag.id === t.id && isEdit ? (
                            <>
                              <button
                                className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary me-1'
                                type='button'
                                onClick={() => {
                                  const name = selectedTag.name.trim()
                                  if (name) {
                                    const index = tableClinicTag.findIndex(
                                      (t) => t.name.toLowerCase() === name.toLowerCase()
                                    )
                                    if (index !== -1) {
                                      setShowModalWarningUpdateTag(true)
                                    } else {
                                      handleCreateOrUpdate()
                                    }
                                  } else {
                                    setErrorInput('Name is required')
                                  }
                                }}
                                disabled={loading}
                              >
                                <i className='fa-solid fa-check'></i>
                              </button>
                              <button
                                className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary me-1'
                                type='button'
                                onClick={() => {
                                  setSelectedTag(undefined)
                                  setIsEdit(false)
                                  if (selectedTag && !selectedTag.id) {
                                    const arr = [...tableClinicTag]
                                    arr.shift()
                                    setTableClinicTag(arr)
                                  }
                                  setErrorInput('')
                                }}
                                disabled={loading}
                              >
                                <i className='fa-solid fa-xmark'></i>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary me-1'
                                type='button'
                                onClick={() => {
                                  setSelectedTag(t)
                                  setIsEdit(true)
                                }}
                                disabled={selectedTag && selectedTag?.id !== t.id}
                              >
                                <i className='fa-solid fa-pen'></i>
                              </button>
                              <button
                                className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary me-1'
                                type='button'
                                onClick={() => {
                                  setSelectedTag(t)
                                  setIsEdit(false)
                                  setShowConfirmDelTagModal(true)
                                }}
                                disabled={selectedTag && selectedTag?.id !== t.id}
                              >
                                <i className='fa-solid fa-trash' />
                              </button>
                            </>
                          )}
                        </div>
                        {selectedTag && selectedTag.id === t.id && errorInput && (
                          <p className='m-0'>{'\u00A0'}</p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {/* end::Table */}
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showConfirmDelTagModal} onHide={() => setShowConfirmDelTagModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Are you sure you want to delete the tag "{selectedTag?.name || ''}"? This tag will be
              removed from all faxes in this clinic.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={onRemoveTag}>
              Delete
            </Button>
            <Button variant='secondary' onClick={() => setShowConfirmDelTagModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showModalWarningUpdateTag}
          onHide={() => setShowModalWarningUpdateTag(false)}
          className='shadow-lg'
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Update</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>The tag "{selectedTag?.name || ''}" is already exists! Do you want merge?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={handleCreateOrUpdate}>
              Update
            </Button>
            <Button variant='secondary' onClick={() => setShowModalWarningUpdateTag(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {faxes.length ? (
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {pageNumbers.map((pageNumber, index) => {
              const isEllipsis = pageNumber === '...'

              return isEllipsis ? (
                <Pagination.Ellipsis key={index} disabled />
              ) : (
                <Pagination.Item
                  key={index}
                  active={pageNumber === currentPage}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              )
            })}
            <Pagination.Next
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        ) : (
          <></>
        )}
      </div>
      {/* begin::Body */}
    </div>
  )
}
