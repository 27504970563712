import {Route, Routes, Navigate, useLocation, Outlet} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {useAuth, UserStatus} from '../modules/auth'
import CallPage from '../modules/apps/chat/CallPage'
import {PatientDashboardWrapper} from '../pages/patient/PatientDashboardWrapper'
import ChatPage from '../modules/apps/chat/ChatPage'
import {PatienEditDetailsPageWrapper} from '../pages/patient/PatienEditDetailsPageWrapper'
import {PatienChooseProfilePageWrapper} from '../pages/patient/PatienChooseProfilePageWrapper'
import {PatientDelegatesWrapper} from '../pages/patient/PatientDelegatesWrapper'
import {PatientAddDelegatePageWrapper} from '../pages/patient/PatientAddDelegatePageWrapper'
import {PatientIntegrateIHealth} from '../components/patient/PatientIntegrateIHealth'
import {PatientIhealthWrapper} from '../pages/patient/PatientIhealthWrapper'

const PatientRoutes = () => {
  const {userStatus} = useAuth()
  const location = useLocation()

  // TODO: Use this `from` feature
  const {from} = location.state || {from: {pathname: '/patient/choose-profile'}}

  if (userStatus === UserStatus.OFFLINE) {
    return <Navigate to={`/patient/auth/login`} />
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route index element={<Navigate to='/patient/dashboard' />} />
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to={from} />} />
        {/* Dashboard */}
        <Route path='dashboard' element={<PatientDashboardWrapper />} />
        {/* Edit details */}
        <Route path='profile' element={<PatienEditDetailsPageWrapper />} />
        {/* Choose profile */}
        <Route path='choose-profile' element={<PatienChooseProfilePageWrapper />} />
        {/* Chat page */}
        <Route path='chat/*' element={<ChatPage />} />
        {/* Delegates */}
        <Route path='delegates' element={<PatientDelegatesWrapper />} />
        {/* Add Delegate */}
        <Route path='add-delegate' element={<PatientAddDelegatePageWrapper />} />
        {/* iHealth Data */}
        <Route path='ihealth-data' element={<PatientIhealthWrapper />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>

      {/* Pages that don't use MasterLayout */}
      <Route element={<Outlet />}>
        {/* iHealth Labs */}
        <Route path='authorizations/ihealthlabs' element={<PatientIntegrateIHealth />} />

        <Route path='call/conversation/:id' element={<CallPage />} />
      </Route>
    </Routes>
  )
}

export {PatientRoutes}
