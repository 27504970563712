import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import {getStaffClinicInfo, updateStaffClinicFaxage} from '../../apis'
import {useCommonAlert} from '../../common/CommonAlert'

const editClinicFaxageSchema = Yup.object().shape({
  faxageUserName: Yup.string().required('Faxage username is required'),
  faxageCompany: Yup.string().required('Faxage company is required'),
  faxagePassword: Yup.string().required('Faxage password is required'),
  confirmPass: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('faxagePassword'), null], "Passwords don't match."),
})

export const EditClinicFaxageForm: FC = () => {
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [clinic, setClinic] = useState<any>({})

  const getDataCallback = useCallback(async () => {
    try {
      const clinicData = await getStaffClinicInfo()
      setClinic(clinicData)
    } catch (error) {
      return navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    getDataCallback()
  }, [getDataCallback])

  const formik = useFormik({
    initialValues: {
      faxageUserName: (clinic.faxageUserName as string) || '',
      faxageCompany: (clinic.faxageCompany as string) || '',
      faxagePassword: '',
      confirmPass: '',
    },
    validationSchema: editClinicFaxageSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        await updateStaffClinicFaxage(values)
        navigate('/clinic/edit', {state: {showFaxageUpdated: true}})
        showAlert('success')
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Edit Clinic Faxage Credentials</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          <Row>
            <Form.Group
              as={Col}
              md='12'
              lg='6'
              className='mb-3'
              controlId='clinicFaxageUsernameInput'
            >
              <Form.Label>Clinic Faxage Username</Form.Label>
              <Form.Control
                placeholder='Clinic Faxage Username'
                {...formik.getFieldProps('faxageUserName')}
                className={clsx({
                  'is-invalid': formik.touched.faxageUserName && formik.errors.faxageUserName,
                })}
              />
              {formik.touched.faxageUserName && formik.errors.faxageUserName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.faxageUserName}</div>
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='faxageCompanyInput'>
              <Form.Label>Clinic Faxage Company</Form.Label>
              <Form.Control
                placeholder='Clinic Faxage Company'
                {...formik.getFieldProps('faxageCompany')}
                className={clsx({
                  'is-invalid': formik.touched.faxageCompany && formik.errors.faxageCompany,
                })}
              />
              {formik.touched.faxageCompany && formik.errors.faxageCompany && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.faxageCompany}</div>
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='faxagePasswordInput'>
              <Form.Label>Clinic Faxage Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Clinic Faxage Password'
                {...formik.getFieldProps('faxagePassword')}
                className={clsx({
                  'is-invalid': formik.touched.faxagePassword && formik.errors.faxagePassword,
                })}
              />
              {formik.touched.faxagePassword && formik.errors.faxagePassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.faxagePassword}</div>
                </div>
              )}
            </Form.Group>

            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='confirmPassInput'>
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm Password'
                {...formik.getFieldProps('confirmPass')}
                className={clsx({
                  'is-invalid': formik.touched.confirmPass && formik.errors.confirmPass,
                })}
              />
              {formik.touched.confirmPass && formik.errors.confirmPass && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.confirmPass}</div>
                </div>
              )}
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end py-6 px-9'>
          <Link to='/clinic/edit' className='btn btn-secondary me-5'>
            Cancel
          </Link>

          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}
