import {useCallback, useEffect, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {getFaxRecipientsByClinic, getListOutgoingFaxesByClinic} from '../../../apis'
import AsyncCreatableSelect from 'react-select/async-creatable'
import {debounce} from 'lodash'

type ValueType = 'id' | 'number'

interface Props {
  show: boolean
  initFaxNumber: string
  onSubmit: (fax: number, type: ValueType) => void
  onHide: () => void
}

type FaxNumber = {value: string; label: string}

export const FaxRecipientModal: React.FC<Props> = (props) => {
  const [, setFaxList] = useState<any[]>([])
  const [selectedFaxRecip] = useState<any>()
  const [type] = useState<ValueType>('number')
  const [faxNumber, setFaxNumber] = useState<FaxNumber>({
    value: '',
    label: '',
  })
  const [defaultOptions, setDefaultOptions] = useState<FaxNumber[]>([])

  useEffect(() => {
    setFaxNumber({
      value: 'fax-number',
      label: props.initFaxNumber.replace(/\D/g, ''),
    })
  }, [props.initFaxNumber])

  const getFaxListCallback = useCallback(async () => {
    try {
      const data = await getFaxRecipientsByClinic()
      setFaxList(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  // const debounceSearch = useDebounce(keyword, 300)

  useEffect(() => {
    getFaxListCallback()
  }, [getFaxListCallback])

  const getListOutgoingFax = useCallback(async () => {
    try {
      const data = await getListOutgoingFaxesByClinic()
      setDefaultOptions(data)
    } catch (e) {
      console.log(e)
    }
  }, [])

  useEffect(() => {
    getListOutgoingFax()
  }, [getListOutgoingFax])

  const _loadOptions = (search: string, callback: any) => {
    getListOutgoingFaxesByClinic(search).then((resp) => callback(resp))
  }

  const loadOptions = debounce(_loadOptions, 300)

  return (
    <Modal show={props.show} onHide={props.onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Send Fax Modal</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5>Input Fax number manually:</h5>
          <AsyncCreatableSelect
            loadOptions={loadOptions || []}
            placeholder='Search fax number...'
            defaultValue={faxNumber}
            value={faxNumber}
            onChange={(value) => {
              setFaxNumber(value as FaxNumber)
            }}
            defaultOptions={defaultOptions}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            props.onSubmit(type === 'number' ? faxNumber.label : selectedFaxRecip.id, type)
          }}
        >
          Send Fax
        </Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
