import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import * as Yup from 'yup'
import {clinicAdminUpdateStaffPass, updateUserPass} from '../apis'
import {useCommonAlert} from '../common/CommonAlert'
import {useAuth} from '../modules/auth'

type Props = {
  userId: number
  show: boolean
  onClose: () => void
}

const changePassSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Minimum 8 characters')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
      'Must have at least 1 uppercase, 1 lowercase and 1 number'
    ),
  passwordConfirm: Yup.string()
    .required('Confirm password is required')
    .min(8, 'Minimum 8 characters')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

const initialValues = {
  password: '',
  passwordConfirm: '',
}

export const ChangePasswordModal: FC<Props> = (props) => {
  const {showAlert} = useCommonAlert()
  const {currentUser} = useAuth()
  const [loading, setLoading] = useState(false)

  const closeModal = () => {
    formik.resetForm()
    props.onClose()
  }

  const formik = useFormik({
    initialValues,
    validationSchema: changePassSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        if (currentUser?.role.title === 'super_admin') {
          await updateUserPass(props.userId, values)
        } else {
          await clinicAdminUpdateStaffPass(props.userId, values)
        }
        showAlert('success')
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
      closeModal()
    },
  })

  return (
    <Modal show={props.show} centered>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Modal.Header>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='passInput'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Password'
              {...formik.getFieldProps('password')}
              autoComplete='new-password'
              className={clsx({'is-invalid': formik.touched.password && formik.errors.password})}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.password}</div>
              </div>
            )}
          </Form.Group>
          <Form.Group className='mb-3' controlId='passConfirmInput'>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Confirm Password'
              {...formik.getFieldProps('passwordConfirm')}
              autoComplete='new-password'
              className={clsx({
                'is-invalid': formik.touched.passwordConfirm && formik.errors.passwordConfirm,
              })}
            />
            {formik.touched.passwordConfirm && formik.errors.passwordConfirm && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.passwordConfirm}</div>
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => closeModal()}>
            Close
          </Button>
          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
