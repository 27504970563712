import {FC} from 'react'
import {Navigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {EditClinicFaxageForm} from '../../../components/staff/EditClinicFaxageForm'
import {useAuth} from '../../../modules/auth'

export const EditClinicFaxagePageWrapper: FC = () => {
  const {currentUser} = useAuth()

  if (!currentUser?.isClinicAdmin) {
    return <Navigate to={'/'} />
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Edit Clinic Faxage Credentials</PageTitle>
      <EditClinicFaxageForm />
    </>
  )
}
