import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Row} from 'react-bootstrap'
import {useAuth} from '../../modules/auth'
import {getPatientDashboard, ihealthUnbinding} from '../../apis'

export const PatientDashboard: FC = () => {
  const {currentPatient} = useAuth()
  const [name, setName] = useState<string>('')
  const [type, setType] = useState<string>('')
  const [data, setData] = useState<any>([])
  // const [noneDataKeys, setNoneDataKeys] = useState<any[]>([])

  const getDashboardCallback = useCallback(async () => {
    try {
      const resData = await getPatientDashboard()
      setName(resData.name)
      setType(resData.type)
      setData(resData.data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getDashboardCallback()
  }, [getDashboardCallback])

  // useEffect(() => {
  //   const patientData = data || {}
  //   if (Object.keys(patientData).length) {
  //     const temp: any[] = []
  //     for (let [key, value] of Object.entries(patientData)) {
  //       if (value === 'Non Compliant') {
  //         temp.push(key)
  //       }
  //     }
  //     setNoneDataKeys(temp)
  //   }
  // }, [data])

  const handleDisconnect = async () => {
    await ihealthUnbinding()
    window.location.reload()
  }

  console.log(data)

  return (
    <>
      <h1 className='mb-3'>
        Hello, {currentPatient?.fullName}. You are logging in as a{' '}
        {type === 'delegate' ? `${type}: ${name}` : type}
      </h1>

      <h3 className='mb-2'>Tasks that patient need to complete</h3>

      <Row>
        {data?.map((item: any) => {
          const arrNonCompliant = Object.entries(item.data)
            .map(([key, value]) => ({key, value}))
            .filter((task) => task.value === 'Non Compliant')

          return (
            <Col xs={12} md={6}>
              <Card className='mb-6'>
                <Card.Header className='min-h-50px'>
                  <Card.Title className='m-0'>
                    <h3 className='fw-bolder m-0'>{item.clinic}</h3>
                  </Card.Title>
                </Card.Header>

                <Card.Body className='pt-3'>
                  <ul>
                    {arrNonCompliant.length ? (
                      arrNonCompliant.map((t) => <li key={t.key}>{t.key}</li>)
                    ) : (
                      <li>Patient has no incomplete tasks</li>
                    )}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          )
        })}
      </Row>

      {type === 'delegate' ? (
        <></>
      ) : (
        <Card className='mb-6'>
          <Card.Header className='min-h-50px'>
            <Card.Title className='m-0'>
              <h3 className='fw-bolder m-0'>Connect with iHealth Labs</h3>
            </Card.Title>
          </Card.Header>

          <Card.Body className='pt-3'>
            {currentPatient?.ihealth ? (
              <>
                <p>You've already connected to the Ihealth</p>
                <Button onClick={handleDisconnect} variant='danger'>
                  Disconnect
                </Button>
              </>
            ) : (
              <a
                href='https://api.ihealthlabs.com:8443/OpenApiV2/OAuthv2/userauthorization/?client_id=e42f0c0daf3547c5a1d6252210fa9d4e&response_type=code&redirect_uri=https://doctalklive.app/authorizations/ihealthlabs&APIName=OpenApiSleep OpenApiBP OpenApiWeight OpenApiBG OpenApiSpO2 OpenApiActivity OpenApiUserInfo OpenApiFood OpenApiSport OpenApiT OpenApiHR'
                className='btn btn-primary'
              >
                Connect
              </a>
            )}
          </Card.Body>
        </Card>
      )}
    </>
  )
}
