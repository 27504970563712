import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {ClinicsList} from '../../components/ClinicsList'

export const ClinicsListWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={[]}>Clinic List</PageTitle>
    <ClinicsList />
  </>
)
