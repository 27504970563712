import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {AddOrganizationForm} from '../../components/AddOrganizationForm'

const addOrganizationBreadCrumbs: Array<PageLink> = [
  {
    title: 'Organizations',
    path: '/organizations/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const AddOrganizationsPageWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={addOrganizationBreadCrumbs}>Add Organization</PageTitle>
    <AddOrganizationForm />
  </>
)
