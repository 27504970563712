import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {addFaxRecipient} from '../../../apis'
import {useCommonAlert} from '../../../common/CommonAlert'
import {saveFaxRecipientSchema} from '../../../common/types'

type Props = {
  show: boolean
  onAdded: (recipient: any) => void
  onClose: () => void
}

export const AddFaxRecipientModal: FC<Props> = (props) => {
  const {showAlert} = useCommonAlert()

  const formik = useFormik({
    initialValues: {
      name: '',
      number: '',
    },
    validationSchema: saveFaxRecipientSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        const recipient = await addFaxRecipient(values)
        props.onAdded(recipient)
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else if (res.status === 409) {
            showAlert('error', 'This Fax number is already in use')
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Modal show={props.show} centered onHide={() => props.onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Add Fax Recipient</Modal.Title>
      </Modal.Header>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Modal.Body>
          <Form.Group className='mb-3' controlId='recNameInput'>
            <Form.Label>Recipient Name</Form.Label>
            <Form.Control
              placeholder='Recipient Name'
              {...formik.getFieldProps('name')}
              className={clsx({'is-invalid': formik.touched.name && formik.errors.name})}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.name}</div>
              </div>
            )}
          </Form.Group>

          <Form.Group className='mb-3' controlId='numberInput'>
            <Form.Label>Fax Number</Form.Label>
            <Form.Control
              placeholder='Fax Number'
              {...formik.getFieldProps('number')}
              className={clsx({'is-invalid': formik.touched.number && formik.errors.number})}
            />
            {formik.touched.number && formik.errors.number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.number}</div>
              </div>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => props.onClose()}>
            Close
          </Button>
          <Button type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}
