/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {useAuth} from '../../../../../app/modules/auth'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser, currentPatient, unreadMessagesCount, unreadSMSMessagesCount} = useAuth()

  const getSideBarMenuItems = () => {
    if (currentUser) {
      if (currentUser?.role.title === 'super_admin') {
        return (
          <>
            <SidebarMenuItem
              to='/dashboard'
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem to='/clinics/list' fontIcon='bi-hospital' title='Clinics' />
            <SidebarMenuItem
              to='/organizations/list'
              fontIcon='bi-building'
              title='Organizations'
            />
          </>
        )
      } else {
        return (
          <>
            <SidebarMenuItem
              to='/dashboard'
              title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem to='/clinic/staff' fontIcon='bi-people-fill' title='Staff' />
            <SidebarMenuItem to='/clinic/patients' fontIcon='bi-people' title='Patients' />
            <SidebarMenuItem
              to='/clinic/ihealth/patients'
              fontIcon='bi-people'
              title='Remote Patient Monitoring'
            />
            <SidebarMenuItem
              to='/chat'
              fontIcon='bi-chat-left'
              title={`Chat ${unreadMessagesCount ? `(${unreadMessagesCount})` : ''}`}
            />
            {!currentUser?.clinic?.parentClinic && (
              <>
                <SidebarMenuItem
                  to='/sms-chat'
                  fontIcon='bi-chat-left'
                  title={`SMS Chat ${unreadSMSMessagesCount ? `(${unreadSMSMessagesCount})` : ''}`}
                />
                <SidebarMenuItem
                  to='/clinic/incoming-faxes'
                  fontIcon='bi-envelope-paper'
                  title='Incoming Faxes'
                />
                <SidebarMenuItem
                  to='/clinic/outgoing-faxes'
                  fontIcon='bi-envelope-paper'
                  title='Outgoing Faxes'
                />
                <SidebarMenuItem
                  to='/clinic/call-logs'
                  faIcon='fas fa-square-phone'
                  title='Call Log'
                />
                <SidebarMenuItem
                  to='/clinic/frequent-contacts'
                  faIcon='fas fa-address-book'
                  title='Frequent Contacts'
                />
                {currentUser.isClinicAdmin && (
                  <>
                    <SidebarMenuItem
                      to='/clinic/fax-recipients'
                      faIcon='fas fa-right-to-bracket'
                      title='Fax Recipients'
                    />
                    <SidebarMenuItem
                      to='/clinic/sub-clinics'
                      faIcon='fas fa-house-chimney-medical'
                      title='Sub Clinics'
                    />
                  </>
                )}
              </>
            )}
          </>
        )
      }
    } else if (currentPatient) {
      return (
        <>
          <SidebarMenuItem
            to='/patient/dashboard'
            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            fontIcon='bi-app-indicator'
          />
          {currentPatient.ihealth && currentPatient.id === currentPatient.currentProfileId && (
            <SidebarMenuItem
              to='/patient/ihealth-data'
              fontIcon='bi-cloud-arrow-down'
              title='iHealth Data'
            />
          )}
          {currentPatient.id === currentPatient.currentProfileId && (
            <SidebarMenuItem
              to='/patient/delegates'
              fontIcon='bi-person-hearts'
              title='Manage Delegates'
            />
          )}
          {currentPatient.profileCount >= 2 && (
            <SidebarMenuItem
              to='/patient/choose-profile'
              fontIcon='bi-people-fill'
              title='Choose Profile'
            />
          )}
          <SidebarMenuItem
            to='/patient/chat'
            fontIcon='bi-chat-left'
            title={`Chat ${unreadMessagesCount ? `(${unreadMessagesCount})` : ''}`}
          />
        </>
      )
    } else {
      return <></>
    }
  }

  return getSideBarMenuItems()
}

export {SidebarMenuMain}
