import {axiosAuthInstace, axiosInstace} from '../../../common/axiosInstace'
import {AuthModel, UserAuthModel} from './_models'

const API_URL =
  process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : 'http://localhost:3333'

export const GET_USER_PROFILE_URL = `${API_URL}/users/profile`
export const GENERATE_OTP_URL = `${API_URL}/auth/generate-otp`
export const LOGIN_URL = `${API_URL}/auth/login`
export const LOGOUT_URL = `${API_URL}/auth/logout`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

export async function generateOtp(email: string, password: string) {
  return await axiosAuthInstace.post<AuthModel>(GENERATE_OTP_URL, {
    email,
    password,
  })
}

export async function login(email: string, password: string, otp: string) {
  return await axiosAuthInstace.post<AuthModel>(LOGIN_URL, {
    email,
    password,
    otp,
  })
}

export function logout() {
  return axiosAuthInstace.post(LOGOUT_URL)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axiosInstace.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export async function getUserProfile() {
  const res = await axiosAuthInstace.get<UserAuthModel>(GET_USER_PROFILE_URL)
  return res.data
}
