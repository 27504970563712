import {FC, useState, createContext, useContext, Dispatch, SetStateAction, useEffect} from 'react'
import {PatientAuthModel, UnreadMessages, UserAuthModel, UserStatus} from './_models'
import {getUserProfile, logout as postLogout} from './_requests'
import {WithChildren} from '../../../../_metronic/helpers'
import {useSocket} from '../../apps/chat/core/Socket'
import {getPatientProfile} from '../../../apis'

type AuthContextProps = {
  currentUser: UserAuthModel | null
  setCurrentUser: Dispatch<SetStateAction<UserAuthModel | null>>
  currentPatient: PatientAuthModel | null
  setCurrentPatient: Dispatch<SetStateAction<PatientAuthModel | null>>
  unreadMessages: UnreadMessages[]
  setUnreadMessages: Dispatch<SetStateAction<UnreadMessages[]>>
  unreadMessagesCount: number
  unreadSMSMessages: UnreadMessages[]
  setUnreadSMSMessages: Dispatch<SetStateAction<UnreadMessages[]>>
  unreadSMSMessagesCount: number
  logout: () => void
  autoLogin: () => void
  userStatus: UserStatus
  setUserStatus: Dispatch<SetStateAction<UserStatus>>
  indexUrl: string
}

const initAuthContextPropsState: AuthContextProps = {
  currentUser: null,
  setCurrentUser: () => {},
  currentPatient: null,
  setCurrentPatient: () => {},
  unreadMessages: [],
  setUnreadMessages: () => {},
  unreadMessagesCount: 0,
  unreadSMSMessages: [],
  setUnreadSMSMessages: () => {},
  unreadSMSMessagesCount: 0,
  logout: () => {},
  autoLogin: () => {},
  userStatus: UserStatus.AUTO,
  setUserStatus: () => {},
  indexUrl: '/',
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const {socket, twilioSocket} = useSocket()

  const [currentUser, setCurrentUser] = useState<UserAuthModel | null>(null)
  const [currentPatient, setCurrentPatient] = useState<PatientAuthModel | null>(null)
  const [userStatus, setUserStatus] = useState<UserStatus>(UserStatus.AUTO)

  const [unreadMessages, setUnreadMessages] = useState<UnreadMessages[]>([])
  const [unreadMessagesCount, setUnreadMessagesCount] = useState<number>(0)

  const [unreadSMSMessages, setUnreadSMSMessages] = useState<UnreadMessages[]>([])
  const [unreadSMSMessagesCount, setUnreadSMSMessagesCount] = useState<number>(0)

  const [indexUrl, setIndexUrl] = useState<string>(initAuthContextPropsState.indexUrl)

  useEffect(() => {
    switch (userStatus) {
      case UserStatus.ONLINE:
        setIndexUrl('/dashboard')
        break
      case UserStatus.PATIENT:
        setIndexUrl('/patient')
        break
      default:
        setIndexUrl('/')
        break
    }
  }, [userStatus])

  // Calculate Unread messages count when changing obj array
  useEffect(() => {
    setUnreadMessagesCount(unreadMessages.reduce((sum, message) => sum + message.unreadCount, 0))
  }, [unreadMessages])

  useEffect(() => {
    setUnreadSMSMessagesCount(
      unreadSMSMessages.reduce((sum, message) => sum + message.unreadCount, 0)
    )
  }, [unreadSMSMessages])

  const logout = () => {
    postLogout()
    socket.disconnect()
    twilioSocket.disconnect()
    setUnreadMessages([])
    setUnreadSMSMessages([])
    setCurrentUser(null)
    setCurrentPatient(null)
    setUserStatus(UserStatus.OFFLINE)
  }

  const autoLogin = async () => {
    try {
      const data = await getUserProfile()
      socket.connect()
      twilioSocket.connect()
      setCurrentUser(data)
      setUnreadMessages(data.unreadMessages)
      setUnreadSMSMessages(data.unreadSMSMessages)
      setUserStatus(UserStatus.ONLINE)
    } catch (e) {
      try {
        const data = await getPatientProfile()
        socket.connect()
        setCurrentPatient(data)
        setUnreadMessages(data.unreadMessages)
        setUserStatus(UserStatus.PATIENT)
      } catch (error) {
        console.log(error)
        logout()
      }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        currentPatient,
        setCurrentPatient,
        unreadMessages,
        setUnreadMessages,
        unreadMessagesCount,
        unreadSMSMessages,
        setUnreadSMSMessages,
        unreadSMSMessagesCount,
        logout,
        autoLogin,
        userStatus,
        setUserStatus,
        indexUrl,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export {AuthProvider, useAuth}
