import {FC, useEffect, useState} from 'react'
import {useSocket} from '../modules/apps/chat/core/Socket'
import {getConversation} from '../apis'
import {Button, Modal} from 'react-bootstrap'
import {UnreadMessages, UserStatus, useAuth} from '../modules/auth'
import {Conversation} from './types'

export const SocketSubscribe: FC = () => {
  const {socket, twilioSocket} = useSocket()
  const {userStatus, setUnreadMessages, setUnreadSMSMessages} = useAuth()

  const [incomingCallName, setIncomingCallName] = useState<string>('')
  const [incomingCallId, setIncomingCallId] = useState<string>('')
  const [showCallModal, setShowCallModal] = useState<boolean>(false)

  useEffect(() => {
    socket.on('incoming-call', async (convId: string) => {
      const conv = await getConversation(convId)
      setIncomingCallName(conv.name)
      setIncomingCallId(convId)
      setTimeout(() => {
        setShowCallModal(true)
      }, 300)
    })

    return () => {
      socket.off('incoming-call')
    }
  }, [socket])

  useEffect(() => {
    socket.on('incUnreadCount', async (conversationId: number) => {
      setUnreadMessages((prevUnreadMessages) =>
        prevUnreadMessages.map((userConv) =>
          userConv.conversationId === conversationId
            ? {...userConv, unreadCount: userConv.unreadCount + 1}
            : userConv
        )
      )
    })

    return () => {
      socket.off('incUnreadCount')
    }
  }, [socket, setUnreadMessages])

  useEffect(() => {
    socket.on('new-conversation', async (conv: Conversation) => {
      socket.emit('join-conv', conv.id)
      setUnreadMessages((prevUnreadMessages) => {
        const newConv: UnreadMessages = {
          conversationId: conv.id,
          unreadCount: 1,
        }

        return [newConv, ...prevUnreadMessages]
      })
    })

    return () => {
      socket.off('new-conversation')
    }
  }, [socket, setUnreadMessages])

  useEffect(() => {
    socket.on('resetUnreadCount', async (conversationId: number) => {
      setUnreadMessages((prevUnreadMessages) =>
        prevUnreadMessages.map((userConv) =>
          userConv.conversationId === conversationId
            ? {conversationId: conversationId, unreadCount: 0}
            : userConv
        )
      )
    })

    return () => {
      socket.off('resetUnreadCount')
    }
  }, [socket, setUnreadMessages])

  useEffect(() => {
    twilioSocket.on('sms-new-conv', async (smsConv: Conversation) => {
      setUnreadSMSMessages((prevUnreadSMSMessages) => {
        const newConv: UnreadMessages = {
          conversationId: smsConv.id,
          unreadCount: 1,
        }

        return [newConv, ...prevUnreadSMSMessages]
      })
    })

    return () => {
      twilioSocket.off('sms-new-conv')
    }
  }, [twilioSocket, setUnreadSMSMessages])

  useEffect(() => {
    // TODO: Not work on SMS chat page
    twilioSocket.on('sms-new-msg', async (msgData: {convId: number}) => {
      setUnreadSMSMessages((prevUnreadSMSMessages) =>
        prevUnreadSMSMessages.map((smsConv) =>
          smsConv.conversationId === msgData.convId
            ? {...smsConv, unreadCount: smsConv.unreadCount + 1}
            : smsConv
        )
      )
    })

    return () => {
      twilioSocket.off('sms-new-msg')
    }
  }, [twilioSocket, setUnreadSMSMessages])

  useEffect(() => {
    twilioSocket.on('read-sms-msg', async (conversationId: number) => {
      setUnreadSMSMessages((prevUnreadSMSMessages) =>
        prevUnreadSMSMessages.map((userConv) =>
          userConv.conversationId === conversationId
            ? {conversationId: conversationId, unreadCount: 0}
            : userConv
        )
      )
    })

    return () => {
      twilioSocket.off('read-sms-msg')
    }
  }, [twilioSocket, setUnreadSMSMessages])

  return (
    <Modal
      size='lg'
      show={showCallModal}
      centered
      backdrop='static'
      keyboard={false}
      onHide={() => setShowCallModal(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Incoming Call</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Incoming Call</h4>
        <p>{incomingCallName} wants to video call with you</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='danger' onClick={() => setShowCallModal(false)}>
          Reject
        </Button>
        <Button
          variant='primary'
          onClick={() => {
            setShowCallModal(false)
            window.open(
              `${
                userStatus === UserStatus.PATIENT ? '/patient' : ''
              }/call/conversation/${incomingCallId}`,
              '_blank',
              'height=720,width=1280,menubar=no,location=no'
            )
          }}
        >
          Accept
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
