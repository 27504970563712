/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth, UserStatus} from '../modules/auth'
import {App} from '../App'
import {PatientAuthPage} from '../modules/auth/patient/PatientAuthPage'
import {PatientRoutes} from './PatientRoutes'
import {IntegrateIHealth} from '../components/IntegrateIHealth'

const AppRoutes: FC = () => {
  const {userStatus, autoLogin, indexUrl} = useAuth()

  useEffect(() => {
    // auto login with Cookie
    autoLogin()
  }, []) // eslint-disable-line

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='authorizations/ihealthlabs' element={<IntegrateIHealth />} />
          {userStatus === UserStatus.OFFLINE ? (
            <>
              <Route path='patient/auth/*' element={<PatientAuthPage />} />
              <Route path='patient/*' element={<Navigate to='/patient/auth/login' />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth/login' />} />
            </>
          ) : (
            <>
              {userStatus !== UserStatus.AUTO ? (
                <>
                  <Route index element={<Navigate to={indexUrl} />} />
                  <Route
                    path='patient/*'
                    element={
                      userStatus === UserStatus.PATIENT ? (
                        <PatientRoutes />
                      ) : (
                        <Navigate to='/error/404' />
                      )
                    }
                  />
                  <Route
                    path='*'
                    element={
                      userStatus === UserStatus.ONLINE ? (
                        <PrivateRoutes />
                      ) : (
                        <Navigate to='/error/404' />
                      )
                    }
                  />
                </>
              ) : (
                <Route path='*' element={<></>} />
              )}
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
