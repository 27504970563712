import {FC, useCallback, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {getStaffClinicInfo} from '../../../apis'
import {AddCallLogForm} from '../../../components/staff/AddCallLogForm'

export const ClinicAddCallLogPageWrapper: FC = () => {
  const navigate = useNavigate()

  const getClinicCallback = useCallback(async () => {
    try {
      await getStaffClinicInfo()
    } catch (error) {
      return navigate('/')
    }
  }, [navigate])

  useEffect(() => {
    getClinicCallback()
  }, [getClinicCallback])

  const ClinicAddCallLogBreadCrumbs: Array<PageLink> = [
    {
      title: 'Call Logs',
      path: '/clinic/call-logs',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={ClinicAddCallLogBreadCrumbs}>Add Call Log</PageTitle>
      <AddCallLogForm />
    </>
  )
}
