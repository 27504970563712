import moment from 'moment'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Modal, Pagination} from 'react-bootstrap'
import {
  deleteOutgoingFax,
  getBlobOutgoingFaxFile,
  getPaginatedOutgoingFaxesByClinic,
} from '../../apis'
import {getPageNumbersToShow} from '../../common/helpers'
import {KTSVG} from '../../../_metronic/helpers'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {useCommonAlert} from '../../common/CommonAlert'

export const OutgoingFaxList: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {showAlert} = useCommonAlert()

  const [faxes, setFaxes] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageNumbers, setPageNumbers] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)
  const [search, setSearch] = useState('')
  const [selectedFaxId, setSelectedFaxId] = useState<string>('')
  const [showConfirmDelModal, setShowConfirmDelModal] = useState(false)

  const getOutgoingFaxesCallback = useCallback(async () => {
    try {
      const data = await getPaginatedOutgoingFaxesByClinic(currentPage, 20, search)
      setFaxes(data.data)
      setTotalPages(data.pagination.totalPages)
      setPageNumbers(getPageNumbersToShow(currentPage, data.pagination.totalPages))
    } catch (error) {
      console.log(error)
    }
  }, [currentPage, search])

  useEffect(() => {
    if (location.state) {
      if (location.state.showDeletedNoti) {
        showAlert('success', 'Fax has been deleted')
      }
      if (location.state.page) {
        setCurrentPage(location.state.page)
        setSearch(location.state.search)
      }
      navigate('', {state: {}, replace: true})
    }
  }, [location.state, navigate, showAlert])

  useEffect(() => {
    getOutgoingFaxesCallback()
  }, [getOutgoingFaxesCallback])

  const deleteFaxFile = async (id: string) => {
    try {
      await deleteOutgoingFax(id)
      setShowConfirmDelModal(false)
      if (currentPage !== 1) {
        setCurrentPage(1)
      } else {
        await getOutgoingFaxesCallback()
      }
      showAlert('success', 'Deleted')
    } catch (error) {
      showAlert('error', 'Failed to delete')
      console.log(error)
    }
  }

  const downloadFaxFile = async (id: string, fileName: string) => {
    try {
      const res = await getBlobOutgoingFaxFile(id)

      fileName = fileName.replaceAll(' ', '_')
      if (!fileName.endsWith('.pdf')) {
        if (fileName.endsWith('.')) {
          fileName = fileName.concat('pdf')
        } else {
          fileName = fileName.concat('.pdf')
        }
      }

      // create file link in browser's memory
      const href = URL.createObjectURL(res.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className='card'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 pb-3'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Outgoing Faxes</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <KTSVG
          path='/media/icons/duotune/general/gen021.svg'
          className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute mt-3 ms-5'
        />

        <input
          className='form-control form-control-solid ps-15 mb-4'
          placeholder='Search by fax name/phone number'
          style={{maxWidth: 400}}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value)
            setCurrentPage(1)
          }}
        />

        {/* begin::Table container */}
        <div className='table-responsive position-relative'>
          {/* begin::Table */}
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-120px'>File Name</th>
                <th className='min-w-140px'>Receiver Number</th>
                <th className='min-w-100px'>Sent At</th>
                <th className='min-w-140px'>Status</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody className='text-gray-600 fw-bold'>
              {faxes.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.fileName}</td>
                  <td>{item.receiverNum}</td>
                  {/* Using .utc() to render correct time (Don't change time per timezone) */}
                  <td>{moment.utc(item.sentAt).format('YYYY-MM-DD HH:mm')}</td>
                  <td>{item.status}</td>
                  <td>
                    <div className='d-flex align-items-center justify-content-end'>
                      <button
                        className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary me-1'
                        type='button'
                        onClick={() => {
                          setSelectedFaxId(item.id)
                          setShowConfirmDelModal(true)
                        }}
                      >
                        <i className='fa-solid fa-trash' />
                      </button>
                      <Link
                        to={`${item.id}`}
                        relative='path'
                        className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary me-1'
                        type='button'
                      >
                        <i className='fa-regular fa-eye' />
                      </Link>

                      <button
                        className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary'
                        type='button'
                        onClick={() => downloadFaxFile(item.id, item.fileName)}
                      >
                        <i className='fa-solid fa-download' />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}

        <Modal show={showConfirmDelModal} onHide={() => setShowConfirmDelModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5>Are you sure you want to delete this fax?</h5>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='danger' onClick={() => deleteFaxFile(selectedFaxId)}>
              Delete
            </Button>
            <Button variant='secondary' onClick={() => setShowConfirmDelModal(false)}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        {faxes.length ? (
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {pageNumbers.map((pageNumber, index) => {
              const isEllipsis = pageNumber === '...'

              return isEllipsis ? (
                <Pagination.Ellipsis key={index} disabled />
              ) : (
                <Pagination.Item
                  key={index}
                  active={pageNumber === currentPage}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              )
            })}
            <Pagination.Next
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        ) : (
          <></>
        )}
      </div>
      {/* begin::Body */}
    </div>
  )
}
