import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useEffect, useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import * as Yup from 'yup'
import {Link, useNavigate} from 'react-router-dom'
import moment from 'moment'
import {useCommonAlert} from '../../common/CommonAlert'
import {useAuth} from '../../modules/auth'
import {patientAddDelegate} from '../../apis'

const addDelegateSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Wrong email format').optional(),
  phone: Yup.string()
    .required('Phone is required')
    .length(10)
    .matches(/^[0-9]*$/, 'Phone contains only digits'),
  dob: Yup.date().required('DOB is required'),
  patientRelationshipName: Yup.string().required('Patient relationship name is required'),
  delegateRelationshipName: Yup.string().required('Delegate relationship name name is required'),
})

export const PatientAddDelegateForm: FC = () => {
  const {showAlert} = useCommonAlert()
  const {currentPatient} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (currentPatient && currentPatient.id !== currentPatient.currentProfileId) {
      navigate('/patient/dashboard')
    }
  }, [currentPatient, navigate])

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      dob: moment().format('YYYY-MM-DD'),
      patientRelationshipName: '',
      delegateRelationshipName: '',
    },
    validationSchema: addDelegateSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        await patientAddDelegate(values)
        return navigate(`/patient/delegates`, {state: {showAddedNoti: true}})
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else if (res.status === 409) {
            showAlert('error', 'This relationship information already in system')
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Add Delegate</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          <h4 className='card-title my-3'>Delegate Detail</h4>
          <Row>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='FNameInput'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder='First Name'
                {...formik.getFieldProps('firstName')}
                className={clsx({
                  'is-invalid': formik.touched.firstName && formik.errors.firstName,
                })}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.firstName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='LNameInput'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder='Last Name'
                {...formik.getFieldProps('lastName')}
                className={clsx({'is-invalid': formik.touched.lastName && formik.errors.lastName})}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.lastName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='EmailInput'>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type='email'
                placeholder='Email'
                {...formik.getFieldProps('email')}
                autoComplete='new-email'
                className={clsx({'is-invalid': formik.touched.email && formik.errors.email})}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='PhoneInput'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder='Phone Number'
                {...formik.getFieldProps('phone')}
                autoComplete='off'
                className={clsx({'is-invalid': formik.touched.phone && formik.errors.phone})}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='dobInput'>
              <Form.Label>DOB</Form.Label>
              <Form.Control
                type='date'
                autoComplete='off'
                {...formik.getFieldProps('dob')}
                className={clsx('form-control bg-transparent', {
                  'is-invalid': formik.touched.dob && formik.errors.dob,
                })}
              />
            </Form.Group>
          </Row>

          <h4 className='card-title my-3'>Relationship Detail</h4>
          <Row>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='prNameInput'>
              <Form.Label>Patient Relationship Name</Form.Label>
              <p className='text-black-50'>How do you call the delegate</p>
              <Form.Control
                placeholder='Patient Relationship Name (Ex: Daughter, Son, ...)'
                {...formik.getFieldProps('patientRelationshipName')}
                className={clsx({
                  'is-invalid':
                    formik.touched.patientRelationshipName && formik.errors.patientRelationshipName,
                })}
              />
              {formik.touched.patientRelationshipName && formik.errors.patientRelationshipName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.patientRelationshipName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='prNameInput'>
              <Form.Label>Delegate Relationship Name</Form.Label>
              <p className='text-black-50'>How the delegate calls you</p>
              <Form.Control
                placeholder='Delegate Relationship Name (Ex: Mom, Dad, ...)'
                {...formik.getFieldProps('delegateRelationshipName')}
                className={clsx({
                  'is-invalid':
                    formik.touched.delegateRelationshipName &&
                    formik.errors.delegateRelationshipName,
                })}
              />
              {formik.touched.delegateRelationshipName && formik.errors.delegateRelationshipName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.delegateRelationshipName}</div>
                </div>
              )}
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end py-6 px-9'>
          <Link to={`/patient/delegates`} className='btn btn-secondary me-5'>
            Cancel
          </Link>

          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}
