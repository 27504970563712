import {FC} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {EditOrganizationForm} from '../../components/EditOrganizationForm'

const editOrgBreadCrumbs: Array<PageLink> = [
  {
    title: 'Organizations',
    path: '/organizations/list',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

export const EditOrganizationPageWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={editOrgBreadCrumbs}>Edit Organization</PageTitle>
    <EditOrganizationForm />
  </>
)
