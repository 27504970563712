/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../_metronic/helpers'
import {importCsv} from '../apis'
import {useCommonAlert} from '../common/CommonAlert'
import {ImportCsvModal} from './admin/modals/ImportCsvModal'

type Props = {
  id: number
  orgList: any[]
  onDelete: (id: number) => void
}

export const ClinicActionsCell: FC<Props> = (props) => {
  const {showAlert} = useCommonAlert()

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showImportCsvModal, setShowImportCsvModal] = useState(false)

  const handleImportCsv = async (
    id: number,
    fromLine: number,
    selectedFile: any,
    selectedOrg: any
  ) => {
    try {
      const frmData = new FormData()
      if (selectedOrg) {
        frmData.append('organizationId', selectedOrg.value)
      }
      frmData.append('from', fromLine.toString())
      frmData.append('file', selectedFile, selectedFile.name)
      await importCsv(id, frmData)
      setShowImportCsvModal(false)
      showAlert('success', 'CSV file imported')
    } catch (error: any) {
      if (error.response && error.response.data.statusCode === 400) {
        showAlert('error', error.response.data.message)
      } else {
        showAlert('error', 'Failed to import, please try again later')
      }
    }
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary w-200-imp fw-bold fs-7 py-3'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link to={`/clinics/${props.id}/staff-list`} className='menu-link px-3'>
            Staff list
          </Link>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link to={`/clinics/${props.id}/edit`} className='menu-link px-3'>
            Edit
          </Link>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => setShowConfirmModal(true)}>
            Delete
          </a>

          <Modal show={showConfirmModal} centered onHide={() => setShowConfirmModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are You Sure You Want To Delete This Clinic?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShowConfirmModal(false)}>
                Close
              </Button>
              <Button
                onClick={() => {
                  setShowConfirmModal(false)
                  props.onDelete(props.id)
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => setShowImportCsvModal(true)}>
            Import Patients CSV
          </a>

          <ImportCsvModal
            show={showImportCsvModal}
            onSubmit={(fromLine: number, selectedFile: any, selectedOrg: any) =>
              handleImportCsv(props.id, fromLine, selectedFile, selectedOrg)
            }
            onHide={() => setShowImportCsvModal(false)}
            orgList={props.orgList}
          />
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}
