/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useParams} from 'react-router-dom'
import _ from 'lodash'
import {ChatInner} from '../../../../../_metronic/partials'
import {getConversation, sendFax} from '../../../../apis'
import {useCommonAlert} from '../../../../common/CommonAlert'
import {useAuth, UserStatus} from '../../../auth'
import {useSocket} from '../core/Socket'
import {PatientsIncompleteTasksModal} from './PatientsIncompleteTasksModal'
import {NoteModal} from './NoteModal'
import {NotePdfPreviewModal} from './NotePdfPreviewModal'
import {SignatureModal} from './SignatureModal'
import {AddParticipantsToConversationModal} from './AddParticipantsToConversationModal'

interface Props {
  onInit: (id: string) => void
}

const Private: FC<Props> = (props) => {
  const {socket} = useSocket()
  const {id} = useParams()
  const {userStatus, unreadMessages} = useAuth()
  const {showAlert} = useCommonAlert()

  const [conversationData, setConversationData] = useState<any>({})
  const [showIncompleteTasksModal, setShowIncompleteTasksModal] = useState<boolean>(false)
  const [showNoteModal, setShowNoteModal] = useState<boolean>(false)
  const [showSignatureModal, setShowSignatureModal] = useState<boolean>(false)
  const [showNotePdfPreviewModal, setShowNotePdfPreviewModal] = useState<boolean>(false)
  const [canvasEl, setCanvasEl] = useState<HTMLCanvasElement>()
  const [pdfUrl, setPdfUrl] = useState<URL>()
  const [notePdfBlob, setNotePdfBlob] = useState<Blob>()
  const [showAddPeopleModal, setShowAddPeopleModal] = useState<boolean>(false)

  const getConversationCallback = useCallback(async () => {
    try {
      const data = await getConversation(id!)
      setConversationData(data)
    } catch (error) {
      console.log(error)
    }
  }, [id])

  useEffect(() => {
    getConversationCallback()
  }, [getConversationCallback])

  const sendMessage = (message: string) => {
    try {
      socket.emit('message', {
        conversationId: id,
        message: message,
      })
      setShowIncompleteTasksModal(false)
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed to send, please try again later')
    }
  }

  const handleAddPeople = (ptcps: any[]) => {
    try {
      socket.emit('add-participants', {
        conversationId: parseInt(id || ''),
        participants: ptcps.map((item) => item.id),
      })
      setShowAddPeopleModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    props.onInit(id || '')
  }, [props, id])

  const currConvUnreadCount = useMemo(
    () =>
      unreadMessages.find((item) => item.conversationId === parseInt(id || '0'))?.unreadCount || 0,
    [unreadMessages, id]
  )

  const readMsgCallback = useCallback(() => {
    if (currConvUnreadCount) {
      socket.emit('read-msg', id)
    }
  }, [currConvUnreadCount, socket, id])

  const debouncedReadMsg = _.debounce(readMsgCallback, 1000, {
    leading: true,
    trailing: false,
  })

  useEffect(() => {
    debouncedReadMsg()
  }, [debouncedReadMsg])

  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      debouncedReadMsg()
    }
  }

  const handleUserActivity = () => {
    debouncedReadMsg()
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    document.addEventListener('mousemove', handleUserActivity)
    document.addEventListener('keydown', handleUserActivity)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
      document.removeEventListener('mousemove', handleUserActivity)
      document.removeEventListener('keydown', handleUserActivity)
    }
  }, []) //eslint-disable-line

  const handleSubmitNoteModal = (canvas: HTMLCanvasElement) => {
    setCanvasEl(canvas)
    setShowNoteModal(false)
    setShowSignatureModal(true)
  }

  const handleSubmitSignatureModal = (url: URL, pdfBlob: Blob) => {
    setPdfUrl(url)
    setNotePdfBlob(pdfBlob)
    setShowSignatureModal(false)
    setShowNotePdfPreviewModal(true)
  }

  const handleSendFax = async (faxId: number, selectedFile: any) => {
    try {
      const frmData = new FormData()
      frmData.append('faxId', faxId.toString())
      frmData.append('file', selectedFile, 'progressnotes.pdf')
      await sendFax(frmData)
      setShowNotePdfPreviewModal(false)
      showAlert('success', 'Fax has been sent successfully')
    } catch (error: any) {
      if (error.response && error.response.data.statusCode === 400) {
        showAlert('error', error.response.data.message)
      } else {
        showAlert('error', 'Failed to send, please try again later')
      }
    }
  }

  return (
    <div className='flex-column col-8 flex-lg-row-fluid ms-lg-7 ms-xl-10 ms-6'>
      <div className='card' id='kt_chat_messenger'>
        <div className='card-header align-items-center' id='kt_chat_messenger_header'>
          <div className='card-title align-items-center w-75 text-start'>
            <div className='symbol-group symbol-hover'></div>
            <div className='d-flex justify-content-center flex-column me-3 w-100'>
              <p className='fs-4 fw-bolder text-gray-900 me-1 mb-2 lh-1 text-truncate'>
                {conversationData.name}
              </p>
            </div>
          </div>

          <div className='card-toolbar'>
            {/* Make call button */}
            <button
              onClick={() => {
                socket.emit('makeCall', {
                  conversationId: id,
                })
                window.open(
                  `${userStatus === UserStatus.PATIENT ? '/patient' : ''}/call/conversation/${id}`,
                  '_blank',
                  'height=720,width=1280,menubar=no,location=no'
                )
              }}
              className='btn btn-sm btn-icon btn-outline btn-outline-primary btn-active-light-primary me-1'
            >
              <i className='fa-solid fa-phone'></i>
            </button>

            {/* Dropdown button */}
            {userStatus === UserStatus.ONLINE && (
              <div className='me-n3 ms-2'>
                <button
                  className='btn btn-sm btn-icon btn-active-light-primary'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                >
                  <i className='bi bi-three-dots fs-2'></i>
                </button>
                <div
                  className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-250px py-3'
                  data-kt-menu='true'
                >
                  <div className='menu-item px-3'>
                    <div className='menu-content fs-6 text-dark fw-bolder px-3 py-4'>
                      Chat Actions
                    </div>
                  </div>

                  <div className='separator mb-3 opacity-75' />

                  <div className='menu-item px-3'>
                    {/* <span className='menu-link px-3' onClick={() => setShowAddPeopleModal(true)}>
                      Add participants
                    </span> */}

                    <AddParticipantsToConversationModal
                      convId={id}
                      onHide={() => setShowAddPeopleModal(false)}
                      onSubmit={(newPtcps) => handleAddPeople(newPtcps)}
                      show={showAddPeopleModal}
                    />

                    {Boolean(conversationData.primaryPatient) && (
                      <>
                        <span
                          className='menu-link px-3'
                          onClick={() => setShowIncompleteTasksModal(true)}
                        >
                          View patient incomplete tasks
                        </span>

                        <PatientsIncompleteTasksModal
                          patient={conversationData.primaryPatient}
                          onHide={() => setShowIncompleteTasksModal(false)}
                          onSubmit={(message) => sendMessage(message)}
                          show={showIncompleteTasksModal}
                        />

                        <span className='menu-link px-3' onClick={() => setShowNoteModal(true)}>
                          Progress Notes
                        </span>

                        <NoteModal
                          patient={conversationData.primaryPatient}
                          onHide={() => setShowNoteModal(false)}
                          onSubmit={(canvas: HTMLCanvasElement) => handleSubmitNoteModal(canvas)}
                          show={showNoteModal}
                        />

                        <SignatureModal
                          onHide={() => setShowSignatureModal(false)}
                          onSubmit={(url: URL, pdfBlob: Blob) =>
                            handleSubmitSignatureModal(url, pdfBlob)
                          }
                          show={showSignatureModal}
                          canvasEl={canvasEl}
                        />

                        <NotePdfPreviewModal
                          onHide={() => setShowNotePdfPreviewModal(false)}
                          onSubmit={(faxId: number, pdfFile: any) => handleSendFax(faxId, pdfFile)}
                          show={showNotePdfPreviewModal}
                          pdfUrl={pdfUrl}
                          pdfBlob={notePdfBlob}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <ChatInner conversationId={id!} />
      </div>
    </div>
  )
}

export {Private}
