import {FC, useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {staffGetPatientProfile} from '../../../apis'
import {ClinicAddDelegateForPatientForm} from '../../../components/staff/patient/ClinicAddDelegateForPatientForm'

export const ClinicAddDelegatePageWrapper: FC = () => {
  const navigate = useNavigate()
  const {id} = useParams()

  const [patientData, setPatientData] = useState<any>()

  const getPatientProfileCallback = useCallback(async () => {
    try {
      const data = await staffGetPatientProfile(id)
      setPatientData(data)
    } catch (error) {
      return navigate('/clinic/patients')
    }
  }, [navigate, id])

  useEffect(() => {
    getPatientProfileCallback()
  }, [getPatientProfileCallback])

  const ClinicAddDelegateBreadCrumbs: Array<PageLink> = [
    {
      title: 'Patient List',
      path: '/clinic/patients',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: patientData?.fullName,
      path: '/clinic/patients',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={ClinicAddDelegateBreadCrumbs}>Add Delegate</PageTitle>
      <ClinicAddDelegateForPatientForm patient={patientData} />
    </>
  )
}
