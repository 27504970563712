import {axiosInstace} from '../common/axiosInstace'

export const getOrganizationsCount = async () => {
  const res = await axiosInstace.get('/organizations/count')
  return res.data
}

export const getAllOrganizations = async () => {
  const res = await axiosInstace.get('/organizations')
  return res.data
}

export const addOrganization = async (data: any) => {
  const res = await axiosInstace.post('/organizations', data)
  return res.data
}

export const updateOrganization = async (id: string, data: any) => {
  const res = await axiosInstace.put(`/organizations/${id}`, data)
  return res.data
}

export const getOrganizationById = async (id: string) => {
  const res = await axiosInstace.get(`/organizations/${id}`)
  return res.data
}

export const deleteOrganization = async (id: number) => {
  const res = await axiosInstace.delete(`/organizations/${id}`)
  return res.data
}
