import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Form, Row} from 'react-bootstrap'
import * as Yup from 'yup'
import moment from 'moment'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useCommonAlert} from '../../common/CommonAlert'
import {getCallLogInfo, updateCallLogInfo} from '../../apis/calllog'
import Select from 'react-select'
import {callLogLocationOptions, CallLogType, callLogTypeOptions} from '../../common/helpers'
import {CallLogTimeSpent} from '../../common/types'

const editCallLogSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string()
    .required('Phone is required')
    .length(10)
    .matches(/^[0-9]*$/, 'Phone contains only digits'),
  dob: Yup.date().required('DOB is required'),
  type: Yup.string().required(),
  body: Yup.string().required('Request / Content is required'),
  note: Yup.string(),
  location: Yup.string(),
})

export const EditCallLogForm: FC = () => {
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()
  const {id} = useParams()

  const [loading, setLoading] = useState(false)
  const [callLog, setCallLog] = useState<any>()
  const [timespents, setTimespents] = useState<CallLogTimeSpent[]>([])

  const getCallLogCallback = useCallback(async () => {
    try {
      const data = await getCallLogInfo(id)
      setCallLog(data)
      const timeSpents: CallLogTimeSpent[] = data.timespents.map((item: any) => ({
        actionDate: item.actionDate,
        minutesSpent: item.minutesSpent,
        note: item.note,
      }))
      setTimespents(timeSpents)
    } catch (error) {
      console.log(error)
    }
  }, [id])

  useEffect(() => {
    getCallLogCallback()
  }, [getCallLogCallback])

  const formik = useFormik({
    initialValues: {
      firstName: (callLog?.firstName as string) || '',
      lastName: (callLog?.lastName as string) || '',
      phone: (callLog?.phone as string) || '',
      dob: moment(callLog?.dob).format('YYYY-MM-DD'),
      type: (callLog?.type as string) || CallLogType.Other,
      body: (callLog?.body as string) || '',
      note: (callLog?.note as string) || '',
      location: (callLog?.location as string) || '',
    },
    validationSchema: editCallLogSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setLoading(true)
      try {
        setLoading(false)
        await updateCallLogInfo(id, {...values, timespents})
        return navigate(`/clinic/call-logs`, {state: {showEditedNoti: true}})
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
        setLoading(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Card>
      <Form onSubmit={formik.handleSubmit} noValidate>
        <Card.Header className='min-h-50px'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Edit Call Log</h3>
          </Card.Title>
        </Card.Header>

        <Card.Body className='pt-3'>
          <h4 className='card-title my-3'>Call Log Detail</h4>
          <h5>Caller Info</h5>

          <Row>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='FNameInput'>
              <Form.Label>First Name</Form.Label>
              <Form.Control
                placeholder='First Name'
                {...formik.getFieldProps('firstName')}
                disabled
                className={clsx({
                  'is-invalid': formik.touched.firstName && formik.errors.firstName,
                })}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.firstName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='LNameInput'>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                placeholder='Last Name'
                disabled
                {...formik.getFieldProps('lastName')}
                className={clsx({'is-invalid': formik.touched.lastName && formik.errors.lastName})}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.lastName}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='PhoneInput'>
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                placeholder='Phone Number'
                disabled
                {...formik.getFieldProps('phone')}
                autoComplete='off'
                className={clsx({'is-invalid': formik.touched.phone && formik.errors.phone})}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='dobInput'>
              <Form.Label>DOB</Form.Label>
              <Form.Control
                type='date'
                autoComplete='off'
                disabled
                {...formik.getFieldProps('dob')}
                className={clsx('form-control bg-transparent', {
                  'is-invalid': formik.touched.dob && formik.errors.dob,
                })}
              />
            </Form.Group>

            <h5>Log Info</h5>

            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='LNameInput'>
              <Form.Label>Request / Content</Form.Label>
              <Form.Control
                placeholder='Request / Content'
                {...formik.getFieldProps('body')}
                className={clsx({'is-invalid': formik.touched.body && formik.errors.body})}
              />
              {formik.touched.body && formik.errors.body && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.body}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='TypeInput'>
              <Form.Label>Type</Form.Label>
              <Select
                name='type'
                options={callLogTypeOptions}
                value={callLogTypeOptions.find((item) => item.value === formik.values.type)}
                onChange={(val) => formik.setFieldValue('type', val?.value)}
              />
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='NoteInput'>
              <Form.Label>Note</Form.Label>
              <Form.Control
                placeholder='Note'
                as='textarea'
                rows={3}
                {...formik.getFieldProps('note')}
                className={clsx({'is-invalid': formik.touched.note && formik.errors.note})}
              />
              {formik.touched.note && formik.errors.note && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.note}</div>
                </div>
              )}
            </Form.Group>
            <Form.Group as={Col} md='12' lg='6' className='mb-3' controlId='LocationInput'>
              <Form.Label>Location</Form.Label>
              <Select
                name='location'
                options={callLogLocationOptions}
                value={callLogLocationOptions.find((item) => item.value === formik.values.location)}
                onChange={(val) => formik.setFieldValue('location', val?.value)}
              />
            </Form.Group>
            <Form.Group className='mb-3' controlId='TimeInput'>
              <div className='d-flex justify-content-between w-lg-50'>
                <div>
                  <Form.Label className='mb-1'>Time Spent</Form.Label>
                  <p className='mb-1'>NOTE: Action Date must be unique</p>
                </div>

                <Button
                  onClick={() =>
                    setTimespents([
                      ...timespents,
                      {
                        actionDate: '2024-09-05',
                        minutesSpent: 0,
                        note: '',
                      },
                    ])
                  }
                >
                  +
                </Button>
              </div>
              {timespents.length ? (
                <>
                  <div className='table-responsive'>
                    <table className='table align-middle gs-0 gy-3'>
                      <thead>
                        <tr className='fw-bold text-muted border border-secondary-subtle'>
                          <th>Action Date</th>
                          <th>Minutes Spent</th>
                          <th>Note</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className='text-gray-600 fw-bold border border-secondary-subtle'>
                        {timespents.map((item: any, index: number) => (
                          <tr key={`key-${index}`}>
                            <td>
                              <Form.Control
                                type='date'
                                autoComplete='off'
                                value={item.actionDate}
                                onChange={(e) => {
                                  setTimespents((prev) => {
                                    return prev.map((obj, idx) =>
                                      idx === index ? {...obj, actionDate: e.target.value} : obj
                                    )
                                  })
                                }}
                                className={'form-control bg-transparent'}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type='number'
                                autoComplete='off'
                                value={item.minutesSpent}
                                onChange={(e) => {
                                  setTimespents((prev) => {
                                    return prev.map((obj, idx) =>
                                      idx === index
                                        ? {...obj, minutesSpent: Number(e.target.value)}
                                        : obj
                                    )
                                  })
                                }}
                                className={'form-control bg-transparent'}
                              />
                            </td>
                            <td>
                              <Form.Control
                                placeholder='Note'
                                autoComplete='off'
                                value={item.note}
                                onChange={(e) => {
                                  setTimespents((prev) => {
                                    return prev.map((obj, idx) =>
                                      idx === index ? {...obj, note: e.target.value} : obj
                                    )
                                  })
                                }}
                              />
                            </td>
                            <td>
                              <Button
                                onClick={() => {
                                  const temp = [...timespents]
                                  temp.splice(index, 1)
                                  setTimespents(temp)
                                }}
                              >
                                -
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <></>
              )}
            </Form.Group>
          </Row>
        </Card.Body>

        <Card.Footer className='d-flex justify-content-end py-6 px-9'>
          <Link to={`/clinic/call-logs`} className='btn btn-secondary me-5'>
            Cancel
          </Link>

          <Button variant='primary' type='submit' disabled={formik.isSubmitting || !formik.isValid}>
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            ) : (
              'Save Changes'
            )}
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  )
}
