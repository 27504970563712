/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap'
import {StatisticsWidget5} from '../../_metronic/partials/widgets'
import {getClinicsCount, getOrganizationsCount, getUsersCount} from '../apis'

export const AdminDashboard: FC = () => {
  const [usersCount, setUsersCount] = useState(0)
  const [clinicsCount, setClinicsCount] = useState(0)
  const [organizationsCount, setOrganizationsCount] = useState(0)

  const getUsersCountCallback = useCallback(async () => {
    try {
      const data = await getUsersCount()
      setUsersCount(data.count)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getUsersCountCallback()
  }, [getUsersCountCallback])

  const getClinicsCountCallback = useCallback(async () => {
    try {
      const data = await getClinicsCount()
      setClinicsCount(data.count)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getClinicsCountCallback()
  }, [getClinicsCountCallback])

  const getOrganizationsCountCallback = useCallback(async () => {
    try {
      const data = await getOrganizationsCount()
      setOrganizationsCount(data.count)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getOrganizationsCountCallback()
  }, [getOrganizationsCountCallback])

  return (
    <Row>
      <Col lg={6} xl={4} className='py-2'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/communication/com006.svg'
          color='warning'
          iconColor='white'
          titleColor='dark'
          descriptionColor='dark'
          title={usersCount.toString()}
          description='Users'
        />
      </Col>

      <Col lg={6} xl={4} className='py-2'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/medicine/med001.svg'
          color='success'
          iconColor='white'
          titleColor='dark'
          descriptionColor='dark'
          title={clinicsCount.toString()}
          description='Clinics'
        />
      </Col>

      <Col lg={6} xl={4} className='py-2'>
        <StatisticsWidget5
          className='card-xl-stretch mb-xl-8'
          svgIcon='/media/icons/duotune/ecommerce/ecm008.svg'
          color='primary'
          iconColor='white'
          titleColor='dark'
          descriptionColor='dark'
          title={organizationsCount.toString()}
          description='Organizations'
        />
      </Col>
    </Row>
  )
}
