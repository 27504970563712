import {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import {useCreateMediaStream} from '../../../hooks/useCreateMediaStream'
import {useStartPeerSession} from '../../../hooks/useStartPeerSession'
import {LocalVideo} from './components/LocalVideo'
import {RemoteVideo} from './components/RemoteVideo'

const CallPage = () => {
  const {id} = useParams()

  const [childWidth, setChildWidth] = useState<number>(400)
  const [childHeight, setChildHeight] = useState<number>(300)

  const containerRef = useRef<HTMLDivElement>(null)
  const localVideoRef = useRef<HTMLVideoElement>(null)

  const userMediaStream = useCreateMediaStream(localVideoRef)
  const connectedUsers = useStartPeerSession(id!, userMediaStream!)

  const margin = 10
  const ratio = 3 / 4

  const calcChildSize = () => {
    let max = 0
    let i = 1
    while (i < 5000) {
      let a = area(i)
      if (a === false) {
        max = i - 1
        break
      }
      i++
    }

    // remove margins
    max = max - margin * 2

    setChildWidth(max)
    setChildHeight(max * ratio)
  }

  const area = (increment: number) => {
    let i = 0
    let w = 0
    let h = increment * ratio + margin * 2
    const cw = containerRef.current!.offsetWidth - margin * 2
    const ch = containerRef.current!.offsetHeight - margin * 2
    if (containerRef) {
      while (i < connectedUsers.length + 1) {
        if (w + increment > cw) {
          w = 0
          h = h + increment * ratio + margin * 2
        }
        w = w + increment + margin * 2
        i++
      }
      if (h > ch || increment > cw) return false
      else return increment
    }
    return false
  }

  useEffect(() => {
    calcChildSize()
  }, [connectedUsers.length]) //eslint-disable-line

  return (
    <div className='call-page'>
      <div className='call-layout-wrapper' ref={containerRef}>
        <div style={{width: childWidth, height: childHeight, margin: margin}}>
          <LocalVideo ref={localVideoRef} autoPlay playsInline muted />
        </div>
        {connectedUsers.map((item) => (
          <div key={item} style={{width: childWidth, height: childHeight, margin: margin}}>
            <RemoteVideo id={item} autoPlay playsInline />
          </div>
        ))}
      </div>
    </div>
  )
}

export default CallPage
