/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import {getConversationMessages, getMediaUploadUrl, sendFax} from '../../../app/apis'
import {ChatMessage, MessageType} from '../../../app/common/types'
import {PatientAuthModel, useAuth, UserAuthModel, UserStatus} from '../../../app/modules/auth'
import moment from 'moment'
import {useSocket} from '../../../app/modules/apps/chat/core/Socket'
import {FaxModal} from './FaxModal'
import {useCommonAlert} from '../../../app/common/CommonAlert'
import {MediaPreviewModal} from './MediaPreviewModal'
import {Image} from 'react-bootstrap'
import {SelectContactModal} from './SelectContactModal'

type Props = {
  conversationId: string
}

const ChatInner: FC<Props> = ({conversationId}) => {
  const {currentUser, currentPatient, userStatus} = useAuth()
  const {socket} = useSocket()
  const {showAlert} = useCommonAlert()

  const hiddenInputFileRef = useRef<HTMLInputElement>(null)

  const [message, setMessage] = useState<string>('')
  const [messageList, setMessageList] = useState<ChatMessage[]>([])
  const [showFaxModal, setShowFaxModal] = useState<boolean>(false)
  const [currentParticipant, setCurrentParticipant] = useState<
    UserAuthModel | PatientAuthModel | null
  >(null)

  const [showMediaPreviewModal, setShowMediaPreviewModal] = useState<boolean>(false)
  const [selectedMediaFile, setSelectedMediaFile] = useState<File>()

  const [showSelectContactModal, setShowSelectContactModal] = useState<boolean>(false)

  const getMessagesCallback = useCallback(async () => {
    try {
      const data: ChatMessage[] = await getConversationMessages(conversationId)
      setMessageList(data)
    } catch (error) {
      console.log(error)
    }
  }, [conversationId])

  useEffect(() => {
    socket.on('msgToClient', (msg: ChatMessage) => {
      if (msg.conversation.id.toString() === conversationId) {
        const temp = [...messageList]
        temp.unshift(msg)
        setMessageList(temp)
      }
    })

    return () => {
      socket.off('msgToClient')
    }
  }, [messageList, socket, conversationId])

  useEffect(() => {
    getMessagesCallback()
  }, [getMessagesCallback])

  useEffect(() => {
    if (userStatus === UserStatus.ONLINE) {
      setCurrentParticipant(currentUser)
    } else if (userStatus === UserStatus.PATIENT) {
      setCurrentParticipant(currentPatient)
    }
  }, [userStatus, currentUser, currentPatient])

  const sendMessage = () => {
    try {
      if (!message?.trim()) {
        return false
      }
      socket.emit('message', {
        conversationId: conversationId,
        message: message,
      })
      setMessage('')
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed to send, please try again later')
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const handleSendFax = async (faxId: number, selectedFile: any) => {
    try {
      const frmData = new FormData()
      frmData.append('faxId', faxId.toString())
      frmData.append('file', selectedFile, selectedFile.name)
      await sendFax(frmData)
      setShowFaxModal(false)
      showAlert('success', 'Fax has been sent successfully')
    } catch (error: any) {
      if (error.response && error.response.data.statusCode === 400) {
        showAlert('error', error.response.data.message)
      } else {
        showAlert('error', 'Failed to send, please try again later')
      }
    }
  }

  const handleSelectContact = async (selectedContact: any) => {
    const contactStr = `${
      selectedContact?.practiceName ? `${selectedContact?.practiceName}, ` : ''
    } ${selectedContact?.fullName}, ${selectedContact?.specialty}, ${selectedContact?.IPA}, ${
      selectedContact?.phone
    }, ${selectedContact?.fax}, ${selectedContact?.address}, ${selectedContact?.city}, ${
      selectedContact?.ST
    }, ${selectedContact?.zip}`

    setMessage(contactStr)
    setShowSelectContactModal(false)
    showAlert('success', 'Contact info added to messages')
  }

  const handleSelectMedia = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0]

      if (!file.type.startsWith('image') && !file.type.startsWith('video')) {
        showAlert('error', 'File type must be image or video')
      }

      const maxFileSize = 20 * 1024 * 1024 // 20 MB

      if (file.size > maxFileSize) {
        showAlert('error', 'File size must be smaller than 20MB')
      }

      setSelectedMediaFile(e.target.files[0])
      setShowMediaPreviewModal(true)
    }
  }

  const handleSendMedia = async () => {
    try {
      if (selectedMediaFile) {
        const data = await getMediaUploadUrl(
          conversationId,
          selectedMediaFile.name,
          selectedMediaFile.type
        )

        await fetch(data.uploadUrl, {
          method: 'PUT',
          body: selectedMediaFile,
          headers: {
            'Content-Type': selectedMediaFile.type,
          },
        })

        setShowMediaPreviewModal(false)

        socket.emit('message', {
          conversationId: conversationId,
          message: data.s3Uri,
          type: selectedMediaFile.type.startsWith('image') ? MessageType.Image : MessageType.Video,
        })
      }
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed to send, please try again later')
    }
  }

  const renderMessage = (msg: ChatMessage) => {
    switch (msg.type) {
      case MessageType.Text:
        return (
          <div
            className={clsx(
              'p-5 rounded w-100 text-start',
              `bg-light-${
                msg.subject.id !== currentParticipant?.chatParticipant.id ? 'info' : 'primary'
              }`,
              'text-dark fw-bold mw-lg-50'
            )}
            data-kt-element='message-text'
          >
            {msg.body}
          </div>
        )

      case MessageType.Image:
        return (
          <div
            className={clsx(
              'p-5 rounded w-100 text-start',
              `bg-light-${
                msg.subject.id !== currentParticipant?.chatParticipant.id ? 'info' : 'primary'
              }`,
              'text-dark fw-bold mw-lg-50'
            )}
            data-kt-element='message-text'
          >
            <Image fluid src={msg.src} />
          </div>
        )

      case MessageType.Video:
        return (
          <div
            className={clsx(
              'p-5 rounded w-100 text-start',
              `bg-light-${
                msg.subject.id !== currentParticipant?.chatParticipant.id ? 'info' : 'primary'
              }`,
              'text-dark fw-bold mw-lg-50'
            )}
            data-kt-element='message-text'
          >
            <video style={{width: '100%'}} controls>
              <source src={msg.src} />
            </video>
          </div>
        )

      case MessageType.Announcement:
        return <p className='text-muted fs-4'>{msg.body}</p>
    }
  }

  return (
    <div className='card-body' id='kt_chat_messenger_body'>
      <div
        className='scroll-y pe-2 chat-body-container'
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        data-kt-scroll-wrappers='#kt_content, #kt_app_content, #kt_chat_messenger_body'
        data-kt-scroll-offset='5px'
      >
        {messageList.map((msg) =>
          msg.type === MessageType.Announcement ? (
            <div
              key={`message-${msg.id}`}
              className='d-flex flex-column justify-content-center mb-4'
            >
              <span className='text-muted fs-7'>
                {moment(msg.createdAt).format('M/D/YY, HH:mm')}
              </span>
              <span className='text-muted fw-semibold fs-6'>{msg.body}</span>
            </div>
          ) : (
            <div key={`message-${msg.id}`} className={`d-flex mb-5`}>
              <div
                className={clsx(
                  'd-flex flex-column align-items w-100',
                  `align-items-${
                    msg.subject.id !== currentParticipant?.chatParticipant.id ? 'start' : 'end'
                  }`
                )}
              >
                <div className='d-flex align-items-center mb-2'>
                  {msg.subject.id !== currentParticipant?.chatParticipant.id ? (
                    <>
                      <div className='symbol symbol-35px symbol-circle'>
                        {msg.subject.avatar ? (
                          <img src={msg.subject.avatar} alt='avatar' className='object-fit-cover' />
                        ) : (
                          <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                            {msg.subject.fullName?.[0]}
                          </span>
                        )}
                      </div>
                      <div className='ms-3'>
                        <span className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                          {msg.subject.fullName}
                        </span>
                        <span className='text-muted fs-7 mb-1'>
                          {moment(msg.createdAt).format('M/D/YY, HH:mm')}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='me-3'>
                        <span className='text-muted fs-7 mb-1'>
                          {moment(msg.createdAt).format('M/D/YY, HH:mm')}
                        </span>
                        <a
                          href='#'
                          className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                        >
                          You
                        </a>
                      </div>
                      <div className='symbol symbol-35px symbol-circle'>
                        <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                          {currentParticipant?.fullName[0]}
                        </span>
                      </div>
                    </>
                  )}
                </div>

                {renderMessage(msg)}
              </div>
            </div>
          )
        )}
      </div>

      <div className='card-footer pt-4 d-flex mb-3' id='kt_chat_messenger_footer'>
        <textarea
          className='form-control'
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        />

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center ms-1'>
            <>
              <button
                className='btn btn-icon btn-outline btn-active-light-primary me-1'
                type='button'
                onClick={() => hiddenInputFileRef.current?.click()}
              >
                <i className='fa-solid fa-image' />
              </button>

              <input
                type='file'
                className='d-none'
                accept='image/*,video/*'
                ref={hiddenInputFileRef}
                onChange={(e) => handleSelectMedia(e)}
                onClick={(e: any) => (e.target.value = null)}
              />
            </>

            {userStatus === UserStatus.ONLINE && (
              <>
                <button
                  className='btn btn-icon btn-outline btn-active-light-primary me-1'
                  type='button'
                  onClick={() => setShowSelectContactModal(true)}
                >
                  <i className='fa-solid fa-address-book' />
                </button>

                <SelectContactModal
                  show={showSelectContactModal}
                  onHide={() => setShowSelectContactModal(false)}
                  onSubmit={(selectedContact: any) => handleSelectContact(selectedContact)}
                />
              </>
            )}

            {userStatus === UserStatus.ONLINE && currentParticipant?.clinic?.isFaxageEnabled ? (
              <>
                <button
                  className='btn btn-icon btn-outline btn-active-light-primary me-1'
                  type='button'
                  onClick={() => setShowFaxModal(true)}
                >
                  <i className='fa-solid fa-fax' />
                </button>

                <FaxModal
                  show={showFaxModal}
                  onHide={() => setShowFaxModal(false)}
                  onSubmit={(faxId: number, selectedFile: any) =>
                    handleSendFax(faxId, selectedFile)
                  }
                />
              </>
            ) : (
              <></>
            )}
          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            disabled={!message?.trim()}
            onClick={sendMessage}
          >
            Send
          </button>
        </div>
      </div>

      <MediaPreviewModal
        show={showMediaPreviewModal}
        fileMimeType={selectedMediaFile?.type}
        fileName={selectedMediaFile?.name}
        onHide={() => setShowMediaPreviewModal(false)}
        onSubmit={() => handleSendMedia()}
        tempUrl={selectedMediaFile ? URL.createObjectURL(selectedMediaFile) : ''}
      />
    </div>
  )
}

export {ChatInner}
