import {FC, useCallback, useEffect, useState} from 'react'
import {Button, Dropdown, Form, Modal, Pagination} from 'react-bootstrap'
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {KTSVG, useDebounce} from '../../../_metronic/helpers'
import {useCommonAlert} from '../../common/CommonAlert'
import {
  getBlobTimeSpentReport,
  getCallLogByClinic,
  markCallLogDone,
  markCallLogUnDone,
} from '../../apis/calllog'
import {callLogTypeOptions, getPageNumbersToShow} from '../../common/helpers'
import moment from 'moment'

export const ClinicCallLog: FC = () => {
  const location = useLocation()
  const {showAlert} = useCommonAlert()
  const navigate = useNavigate()

  const [callLogs, setCallLogs] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageNumbers, setPageNumbers] = useState<any[]>([])
  const [totalPages, setTotalPages] = useState<number>(1)
  const [search, setSearch] = useState<string>('')
  const [hideCompleted, setHideCompleted] = useState<boolean>(true)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<string>('2024-01-01')
  const [endDate, setEndDate] = useState<string>('2025-01-01')

  const debounceSearch = useDebounce(search, 300)

  const getCallLogsCallback = useCallback(async () => {
    try {
      const data = await getCallLogByClinic(
        debounceSearch,
        hideCompleted ? 'true' : '',
        currentPage,
        20
      )
      setCallLogs(data.data)
      setTotalPages(data.pagination.totalPages)
      setPageNumbers(getPageNumbersToShow(currentPage, data.pagination.totalPages))
    } catch (error) {
      console.log(error)
    }
  }, [currentPage, debounceSearch, hideCompleted])

  useEffect(() => {
    getCallLogsCallback()
  }, [getCallLogsCallback])

  useEffect(() => {
    const state: any = location.state
    if (state) {
      if (state.showAddedNoti) {
        showAlert('success', 'Calllog added successfully')
      } else if (state.showEditedNoti) {
        showAlert('success', `Calllog updated successfully`)
      }

      navigate('', {state: null, replace: true})
    }
  }, [location.state, showAlert, navigate])

  const updateStatusForCallLogId = useCallback(
    async (id: number, isDone: boolean) => {
      try {
        if (isDone) {
          await markCallLogUnDone(id)
        } else {
          await markCallLogDone(id)
        }
        await getCallLogsCallback()
        showAlert('success', `Calllog updated successfully`)
      } catch (error) {
        showAlert('error', 'Failed to update')
      }
    },
    [getCallLogsCallback, showAlert]
  )

  const handleCloseModal = useCallback(() => {
    setShowModal(false)
  }, [])

  const handleGenerateReport = useCallback(async () => {
    try {
      const res = await getBlobTimeSpentReport(startDate, endDate)

      // create file link in browser's memory
      const href = URL.createObjectURL(res.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `report-${startDate}-${endDate}.csv`)
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (error) {
      showAlert('error', 'Failed to download')
      console.log(error)
    }
  }, [endDate, showAlert, startDate])

  return (
    <div className='card'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Call logs</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Clinic Call logs</span>
        </h3>

        <div className='card-toolbar'>
          <Link to='/clinic/add-call-log' className='btn btn-primary'>
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
            Add Call log
          </Link>
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='d-flex justify-content-between'>
          <form className='position-relative mb-4' autoComplete='off' style={{maxWidth: 400}}>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-2 svg-icon-lg-1 svg-icon-gray-500 position-absolute mt-3 ms-5'
            />

            <input
              className='form-control form-control-solid ps-15 mb-3'
              placeholder='Search caller name...'
              value={search}
              onChange={(e) => {
                setSearch(e.target.value)
                setCurrentPage(1)
              }}
            />
          </form>

          <Form.Check
            type='switch'
            label='Hide completed log'
            checked={hideCompleted}
            onChange={() => setHideCompleted(!hideCompleted)}
          />
        </div>
        <Button className='mb-3' onClick={() => setShowModal(true)}>
          Generate Time Spent Report
        </Button>

        {/* begin::Table container */}
        <div className='table-responsive mb-5'>
          <table className='table align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted border border-secondary-subtle'>
                <th>Actions</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone</th>
                <th>DOB</th>
                <th>Request / Content</th>
                <th>Type</th>
                <th>Status</th>
                <th>Note</th>
                <th>Location</th>
                <th>Last Modified By</th>
                <th>Last Modified At</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold border border-secondary-subtle'>
              {callLogs.map((item: any) => (
                <tr key={item.id}>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle
                        className='btn-light btn-active-light-primary btn-sm'
                        variant=''
                      >
                        Actions
                      </Dropdown.Toggle>

                      <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-3 m-0'>
                        <div className='menu-item px-3'>
                          <Link to={`/clinic/edit-call-log/${item.id}`} className='menu-link px-3'>
                            Edit Call log
                          </Link>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                  <td className='ps-3'>{item.firstName}</td>
                  <td>{item.lastName}</td>
                  <td>{item.phone}</td>
                  <td className='text-nowrap'>{item.dob}</td>
                  <td>{item.body}</td>
                  <td>{callLogTypeOptions.find((type) => type.value === item.type)?.label}</td>
                  <td>
                    <Form.Check
                      type='switch'
                      label='Completed'
                      checked={item.isDone}
                      onChange={() => updateStatusForCallLogId(item.id, item.isDone)}
                    />
                  </td>
                  <td>{item.note}</td>
                  <td>{item.location}</td>
                  <td>{item.lastModifiedBy?.fullName}</td>
                  <td>{moment(item.updatedAt).format('YYYY-MM-DD HH:mm')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* end::Table container */}

        {callLogs.length ? (
          <Pagination>
            <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
            <Pagination.Prev
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {pageNumbers.map((pageNumber, index) => {
              const isEllipsis = pageNumber === '...'

              return isEllipsis ? (
                <Pagination.Ellipsis key={index} disabled />
              ) : (
                <Pagination.Item
                  key={index}
                  active={pageNumber === currentPage}
                  onClick={() => setCurrentPage(pageNumber)}
                >
                  {pageNumber}
                </Pagination.Item>
              )
            })}
            <Pagination.Next
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => setCurrentPage(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        ) : (
          <></>
        )}

        <Modal show={showModal} centered onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Generate Time spent report</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='mb-3'>
              <h4 className='mb-3'>Select Start Date</h4>
              <Form.Control
                type='date'
                autoComplete='off'
                value={startDate}
                onChange={(e) => setStartDate(e.target.value.toString())}
              />
            </div>
            <div>
              <h4 className='mb-3'>Select End Date</h4>
              <Form.Control
                type='date'
                autoComplete='off'
                value={endDate}
                onChange={(e) => setEndDate(e.target.value.toString())}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='primary' onClick={handleGenerateReport}>
              Submit
            </Button>
            <Button variant='secondary' onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* begin::Body */}
    </div>
  )
}
