import {Button, Modal} from 'react-bootstrap'
import {Document, Page} from 'react-pdf/dist/esm/entry.webpack5'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import {useCallback, useEffect, useState} from 'react'
import Select from 'react-select'
import {getFaxRecipientsByClinic} from '../../../../apis'
import {ptToPxRatio} from '../../../../common/helpers'

interface Props {
  show: boolean
  onSubmit: (faxId: number, pdfFile: any) => void
  onHide: () => void
  pdfUrl?: URL
  pdfBlob?: Blob
}

export const NotePdfPreviewModal: React.FC<Props> = (props) => {
  const [faxList, setFaxList] = useState<any[]>([])
  const [selectedFaxRecip, setSelectedFaxRecip] = useState<any>()

  const getFaxListCallback = useCallback(async () => {
    try {
      const data = await getFaxRecipientsByClinic()
      setFaxList(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getFaxListCallback()
  }, [getFaxListCallback])

  return (
    <Modal className='pdf-edit-modal' size='xl' show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Edit PDF</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='w-100'>
          <h5>Choose Fax Recipient</h5>
          <Select
            options={faxList}
            className='mb-5'
            placeholder='Choose Fax Recipient'
            value={selectedFaxRecip}
            onChange={(value) => {
              setSelectedFaxRecip(value)
            }}
            getOptionValue={(opt) => opt.id}
            getOptionLabel={(opt) => `${opt.name} - ${opt.number}`}
          />
        </div>

        {props.pdfUrl ? (
          <Document file={props.pdfUrl} className='pdf-document'>
            <Page pageNumber={1} renderTextLayer={false} scale={ptToPxRatio} className='pdf-page' />
          </Document>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => {
            props.onSubmit(selectedFaxRecip.id, props.pdfBlob)
          }}
          disabled={!selectedFaxRecip}
        >
          Send Fax
        </Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
