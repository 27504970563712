import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {SubClinicsList} from '../../../components/staff/sub-clinic/SubClinicsList'

export const ClinicSubClinicListWrapper: FC = () => (
  <>
    <PageTitle breadcrumbs={[]}>Sub Clinic List</PageTitle>
    <SubClinicsList />
  </>
)
