/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import {Button, Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
  staffId: number
  onDelete: (staffId: number) => void
}

export const SubClinicStaffActionsCell: FC<Props> = (props) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-3'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => setShow(true)}>
            Delete
          </a>

          <Modal show={show} centered onHide={() => setShow(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are You Sure You Want To Delete This Staff?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShow(false)}>
                Close
              </Button>
              <Button
                onClick={() => {
                  setShow(false)
                  props.onDelete(props.staffId)
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        {/* end::Menu item */}
      </div>
      {/* end::Menu */}
    </>
  )
}
