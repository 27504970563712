import {FC, useCallback, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {getFaxRecipientsByClinic} from '../../../apis'
import {useCommonAlert} from '../../../common/CommonAlert'
import {useAuth} from '../../../modules/auth'
import {AddFaxRecipientModal} from './AddFaxRecipientModal'
import {FaxRecipientActionsCell} from './FaxRecipientActionsCell'

export const FaxRecipientList: FC = () => {
  const {currentUser} = useAuth()
  const {showAlert} = useCommonAlert()

  const [faxRecipients, setFaxRecipients] = useState<any[]>([])
  const [showAddModal, setShowAddModal] = useState<boolean>(false)

  const getFaxRecipientListCallback = useCallback(async () => {
    try {
      const data = await getFaxRecipientsByClinic()
      setFaxRecipients(data)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getFaxRecipientListCallback()
  }, [getFaxRecipientListCallback])

  const handleRecipientAdded = async (recipient: any) => {
    setFaxRecipients([recipient, ...faxRecipients])
    setShowAddModal(false)
    showAlert('success', 'Fax Recipient Added Successfully')
  }

  const handleRecipientUpdated = async (updatedRecipient: any, index: number) => {
    const recList = [...faxRecipients]
    recList[index] = updatedRecipient
    setFaxRecipients(recList)
  }

  const handleRecipientDeleted = async (index: number) => {
    const recList = [...faxRecipients]
    recList.splice(index, 1)
    setFaxRecipients(recList)
  }

  return (
    <div className='card'>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Fax Recipient List</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Maximum of 10 recipients per clinic
          </span>
        </h3>
        {currentUser?.isClinicAdmin && (
          <div className='card-toolbar'>
            <Button onClick={() => setShowAddModal(true)}>
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              Add Fax Recipient
            </Button>

            <AddFaxRecipientModal
              show={showAddModal}
              onAdded={handleRecipientAdded}
              onClose={() => setShowAddModal(false)}
            />
          </div>
        )}
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th>Fax Recipient Id</th>
                <th>Recipient Name</th>
                <th>Fax number</th>
                <th className='text-end'>Actions</th>
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold'>
              {faxRecipients.map((item: any, i: number) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.number}</td>
                  <td className='text-end'>
                    <FaxRecipientActionsCell
                      index={i}
                      recipient={item}
                      onUpdated={(recipient, index: number) =>
                        handleRecipientUpdated(recipient, index)
                      }
                      onDeleted={(index: number) => handleRecipientDeleted(index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
