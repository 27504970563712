/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import clsx from 'clsx'
import moment from 'moment'
import {useSocket} from '../../../modules/apps/chat/core/Socket'
import {useCommonAlert} from '../../../common/CommonAlert'
import {SMSMessage, SMSMessageType} from '../../../common/types'
import {getSMSConvMessages} from '../../../apis'
import {SelectContactModal} from '../../../../_metronic/partials/chat/SelectContactModal'

type Props = {
  conversationId: string
}

export const SMSChatInner: FC<Props> = ({conversationId}) => {
  const {twilioSocket} = useSocket()
  const {showAlert} = useCommonAlert()
  const messagesContainerRef = useRef<HTMLDivElement>(null)

  const [message, setMessage] = useState<string>('')
  const [messageList, setMessageList] = useState<SMSMessage[]>([])
  const [showSelectContactModal, setShowSelectContactModal] = useState<boolean>(false)

  const getSMSMessagesCallback = useCallback(async () => {
    try {
      const data: SMSMessage[] = await getSMSConvMessages(conversationId)
      setMessageList(data)
    } catch (error) {
      console.log(error)
    }
  }, [conversationId])

  useEffect(() => {
    getSMSMessagesCallback()
  }, [getSMSMessagesCallback])

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [messageList])

  useEffect(() => {
    twilioSocket.on(
      'sms-new-msg',
      (msgData: {convId: number; msgContent: string; type: SMSMessageType}) => {
        if (msgData.convId.toString() === conversationId) {
          const temp = [...messageList]
          temp.unshift({
            id: Date.now(),
            body: msgData.msgContent,
            createdAt: new Date(),
            type: msgData.type,
            updatedAt: new Date(),
          })
          setMessageList(temp)
        }
      }
    )

    return () => {
      twilioSocket.off('sms-new-msg')
    }
  }, [messageList, twilioSocket, conversationId])

  const sendMessage = () => {
    try {
      if (!message?.trim()) {
        return false
      }
      twilioSocket.emit('send-sms-msg', {
        conversationId: conversationId,
        message: message,
      })
      setMessage('')
    } catch (error) {
      console.log(error)
      showAlert('error', 'Failed to send, please try again later')
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const handleSelectContact = async (selectedContact: any) => {
    const contactStr = `${
      selectedContact?.practiceName ? `${selectedContact?.practiceName}, ` : ''
    } ${selectedContact?.fullName}, ${selectedContact?.specialty}, ${selectedContact?.IPA}, ${
      selectedContact?.phone
    }, ${selectedContact?.fax}, ${selectedContact?.address}, ${selectedContact?.city}, ${
      selectedContact?.ST
    }, ${selectedContact?.zip}`

    setMessage(contactStr)
    setShowSelectContactModal(false)
    showAlert('success', 'Contact info added to messages')
  }

  return (
    <div className='card-body' id='kt_chat_messenger_body'>
      <div
        className='scroll-y pe-2 chat-body-container'
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies='#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        data-kt-scroll-wrappers='#kt_content, #kt_app_content, #kt_chat_messenger_body'
        data-kt-scroll-offset='5px'
      >
        {messageList.map((msg) => (
          <div key={`message-${msg.id}`} className={`d-flex mb-5`}>
            <div
              className={clsx(
                'd-flex flex-column align-items w-100',
                `align-items-${msg.type === SMSMessageType.Incoming ? 'end' : 'start'}`
              )}
            >
              <div className='d-flex align-items-center mb-2'>
                {msg.type === SMSMessageType.Outgoing ? (
                  <>
                    <div className='me-3'>
                      <span className='text-muted fs-7 mb-1'>
                        {moment(msg.createdAt).format('M/D/YY, HH:mm')}
                      </span>
                      <a href='#' className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'>
                        Clinic
                      </a>
                    </div>
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                        C
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='symbol symbol-35px symbol-circle'>
                      <span className='symbol-label bg-light-danger text-danger fs-6 fw-bolder'>
                        P
                      </span>
                    </div>
                    <div className='ms-3'>
                      <span className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'>
                        Patient
                      </span>
                      <span className='text-muted fs-7 mb-1'>
                        {moment(msg.createdAt).format('M/D/YY, HH:mm')}
                      </span>
                    </div>
                  </>
                )}
              </div>

              <div
                className={clsx(
                  'p-5 rounded w-100 text-start',
                  `bg-light-${msg.type === SMSMessageType.Outgoing ? 'primary' : 'info'}`,
                  'text-dark fw-bold mw-lg-50'
                )}
                data-kt-element='message-text'
              >
                {msg.body}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className='card-footer pt-4 d-flex mb-3' id='kt_chat_messenger_footer'>
        <textarea
          className='form-control'
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        />

        <>
          <button
            className='btn btn-icon btn-outline btn-active-light-primary me-1'
            type='button'
            onClick={() => setShowSelectContactModal(true)}
          >
            <i className='fa-solid fa-address-book' />
          </button>

          <SelectContactModal
            show={showSelectContactModal}
            onHide={() => setShowSelectContactModal(false)}
            onSubmit={(selectedContact: any) => handleSelectContact(selectedContact)}
          />
        </>

        <div className='d-flex flex-stack'>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            disabled={!message?.trim()}
            onClick={sendMessage}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}
