/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {useFormik} from 'formik'
import {FC, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {deleteFaxRecipient, updateFaxRecipient} from '../../../apis'
import {useCommonAlert} from '../../../common/CommonAlert'
import {saveFaxRecipientSchema} from '../../../common/types'

type Props = {
  index: number
  recipient: any
  onUpdated: (recipient: any, index: number) => void
  onDeleted: (index: number) => void
}

export const FaxRecipientActionsCell: FC<Props> = (props) => {
  const [showEditModal, setShowEditModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const {showAlert} = useCommonAlert()

  const formik = useFormik({
    initialValues: {
      name: (props.recipient.name as string) || '',
      number: (props.recipient.number as string) || '',
    },
    validationSchema: saveFaxRecipientSchema,
    onSubmit: async (values, {setSubmitting}) => {
      try {
        const rec = await updateFaxRecipient(props.recipient.id, values)
        setShowEditModal(false)
        showAlert('success', 'Fax Recipient updated successfully')
        props.onUpdated(rec, props.index)
      } catch (error: any) {
        if (error.response) {
          const res = error.response
          if (res.status === 400 && res.statusText === 'Bad Request') {
            const msg = res.data.message.map((item: any, i: number) => (
              <p key={i} className='text-white fs-7 mb-0'>
                {item}
              </p>
            ))
            showAlert('error', msg, 5000)
          } else if (res.status === 409) {
            showAlert('error', 'This Fax number is already in use')
          } else {
            showAlert('error')
          }
        } else {
          showAlert('error')
        }
        setSubmitting(false)
      }
    },
    enableReinitialize: true,
  })

  const handleRecipientDelete = async () => {
    try {
      await deleteFaxRecipient(props.recipient.id)
      setShowDeleteModal(false)
      showAlert('success', 'Deleted successfully')
      props.onDeleted(props.index)
    } catch (err) {
      console.log(err)
      showAlert('error', 'Failed to delete')
    }
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Actions
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>

      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-175px py-3'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => setShowEditModal(true)}>
            Edit
          </a>

          <Modal show={showEditModal} centered onHide={() => setShowEditModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Fax Recipient</Modal.Title>
            </Modal.Header>
            <Form onSubmit={formik.handleSubmit} noValidate>
              <Modal.Body>
                <Form.Group className='mb-3' controlId='recNameInput'>
                  <Form.Label>Recipient Name</Form.Label>
                  <Form.Control
                    placeholder='Recipient Name'
                    {...formik.getFieldProps('name')}
                    className={clsx({'is-invalid': formik.touched.name && formik.errors.name})}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.name}</div>
                    </div>
                  )}
                </Form.Group>

                <Form.Group className='mb-3' controlId='numberInput'>
                  <Form.Label>Fax Number</Form.Label>
                  <Form.Control
                    placeholder='Fax Number'
                    {...formik.getFieldProps('number')}
                    className={clsx({'is-invalid': formik.touched.number && formik.errors.number})}
                  />
                  {formik.touched.number && formik.errors.number && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.number}</div>
                    </div>
                  )}
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant='secondary' onClick={() => setShowEditModal(false)}>
                  Close
                </Button>
                <Button type='submit' disabled={formik.isSubmitting || !formik.isValid}>
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div>

        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={() => setShowDeleteModal(true)}>
            Delete
          </a>

          <Modal show={showDeleteModal} centered onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirmation modal</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>Are You Sure You Want To Delete This Fax Recipient?</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant='secondary' onClick={() => setShowDeleteModal(false)}>
                Close
              </Button>
              <Button
                onClick={() => {
                  handleRecipientDelete()
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  )
}
