import axios from 'axios'
import {API_URL} from './helpers'

export const axiosInstace = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
})

export const axiosAuthInstace = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstace.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.status === 401) {
      window.location.replace('/logout')
    }
    return Promise.reject(err)
  }
)
