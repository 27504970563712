import {FC, useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {getSubClinicById} from '../../../apis'
import {SubClinicPatientList} from '../../../components/staff/sub-clinic/SubClinicPatientList'

export const ClinicSubClinicPatientListPageWrapper: FC = () => {
  const navigate = useNavigate()
  const [clinic, setClinic] = useState<any>({})
  const {id} = useParams()

  const getClinicCallback = useCallback(async () => {
    try {
      if (!id) return navigate('/clinic/sub-clinics', {state: {showNotFoundNoti: true}})

      const clinicData = await getSubClinicById(id)
      setClinic(clinicData)
    } catch (error) {
      return navigate('/clinic/sub-clinics', {state: {showNotFoundNoti: true}})
    }
  }, [id, navigate])

  useEffect(() => {
    getClinicCallback()
  }, [getClinicCallback])

  const breadcrumbs: Array<PageLink> = [
    {
      title: 'Sub Clinics',
      path: '/clinic/sub-clinics',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: clinic.name,
      path: '/clinic/sub-clinics',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs}>Patients</PageTitle>
      <SubClinicPatientList clinicId={id || ''} />
    </>
  )
}
