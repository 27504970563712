/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useState} from 'react'
import {Card, Col, Row} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {getStaffClinicInfo} from '../apis'
import {useAuth} from '../modules/auth'

export const StaffDashboard: FC = () => {
  const {currentUser} = useAuth()

  const [data, setData] = useState<any>({})

  const getDataCallback = useCallback(async () => {
    try {
      const staffData = await getStaffClinicInfo()
      setData(staffData)
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    getDataCallback()
  }, [getDataCallback])

  return (
    <>
      <Card className='mb-6'>
        <Card.Header className='min-h-50px py-3'>
          <Card.Title className='m-0'>
            <h3 className='fw-bolder m-0'>Clinic</h3>
          </Card.Title>
          {currentUser?.isClinicAdmin && <Link className='btn btn-primary btn-sm' to='/clinic/edit'>Edit Clinic</Link>}
        </Card.Header>

        <Card.Body className='pt-3'>
          <h4 className='mb-0'>Clinic Name: {data.name}</h4>
        </Card.Body>
      </Card>

      {data.organizations?.length ? (
        <Card>
          <Card.Header className='min-h-50px'>
            <Card.Title className='m-0'>
              <h3 className='fw-bolder m-0'>Organizations</h3>
            </Card.Title>
          </Card.Header>

          <Card.Body className='pt-3'>
            <Row>
              {data.organizations?.map((item: any) => (
                <Col key={item.id} xs={12} md={4} className='p-3'>
                  <div className='bg-light p-3 org-card'>
                    <h4 className='card-title mb-2'>{item.name}</h4>
                    <p className='text-muted org-description'>{item.description}</p>
                  </div>
                </Col>
              ))}
            </Row>
          </Card.Body>
        </Card>
      ) : null}
    </>
  )
}
