import {FC} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {SMSChatLayout} from '../../../components/staff/sms-chat/SMSChatLayout'

interface Props {
  activeConv: number
  convEdit: {id: string; name: string}
  setConvEdit: any
}

export const SMSChatPageWrapper: FC<Props> = (props) => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{undefined}</PageTitle>
      <SMSChatLayout
        convEdit={props.convEdit}
        setConvEdit={props.setConvEdit}
        activeConv={props.activeConv}
      />
    </>
  )
}
