import * as Yup from 'yup'

export enum ParticipantType {
  Staff = 'staff',
  Patient = 'patient',
}

export enum ConversationType {
  External = 'external',
  Internal = 'internal',
}

export enum MessageType {
  Text = 'text',
  Image = 'img',
  Video = 'vid',
  Announcement = 'announcement',
}

export type ChatMessage = {
  id: number
  body: string
  src: string
  type: MessageType
  subject: ChatParticipant
  conversation: Conversation
  createdAt: Date
  updatedAt: Date
}

export enum SMSMessageType {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
}

export type SMSMessage = {
  id: number
  body: string
  type: SMSMessageType
  smsConversation?: Conversation
  createdAt: Date
  updatedAt: Date
}

export type Conversation = {
  id: number
  name: string
  type: ConversationType
  phone: string
  image?: string
  lastMessage?: ChatMessage
  organization?: any
  show?: boolean
  participantCount?: number
  createdAt: Date
  updatedAt: Date
  primaryPatient?: any
}

export type ChatParticipant = {
  id: number
  refId: number
  type: ParticipantType
  // store user values
  avatar?: string
  fullName: string
  createdAt: Date
  updatedAt: Date
}

export type Tag = {
  id: number
  name: string
  faxCount?: number
}

export type IncomingFax = {
  id: string
  faxId: string
  receivedDate: Date
  callerId: string
  dnis: string
  fileName: string
  pageCount: number
  notes: string
  isDeleted: boolean
  clinic: number
  faxTags: Tag[]
}

export const saveFaxRecipientSchema = Yup.object().shape({
  name: Yup.string().required('Recipient name is required'),
  number: Yup.string()
    .required('Fax number is required')
    .matches(/^\d+$/, 'The field should have digits only')
    .length(10, 'Fax number must have exactly 10 digits'),
})

export type CallLogTimeSpent = {
  actionDate: any
  minutesSpent: number
  note: string
}
