import {FC} from 'react'
import {PageTitle} from '../../../../../_metronic/layout/core'
import {ChatLayout} from '../components/ChatLayout'

interface Props {
  activeConv: number
}

export const ChatPageWrapper: FC<Props> = (props) => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>{undefined}</PageTitle>
      <ChatLayout activeConv={props.activeConv} />
    </>
  )
}
