/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {PatientEditProfile} from '../../components/patient/PatientEditProfile'

export const PatienEditDetailsPageWrapper: FC = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>Edit details</PageTitle>
      <PatientEditProfile />
    </>
  )
}
