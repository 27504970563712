import {FC} from 'react'
import {Navigate} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {ClinicAdminEditClinicForm} from '../../../components/staff/ClinicAdminEditClinicForm'
import {useAuth} from '../../../modules/auth'

export const ClinicAdminEditClinicPageWrapper: FC = () => {
  const {currentUser} = useAuth()

  if (!currentUser?.isClinicAdmin) {
    return <Navigate to={'/'} />
  }

  return (
    <>
      <PageTitle breadcrumbs={[]}>Edit Clinic</PageTitle>
      <ClinicAdminEditClinicForm />
    </>
  )
}
