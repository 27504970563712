import {axiosInstace} from '../common/axiosInstace'
import {IncomingFax} from '../common/types'

export const getPaginatedFaxesByClinic = async (
  page: number = 1,
  limit: number = 20,
  tags: number[] = [],
  search: string = '',
  dnis: string = ''
): Promise<{
  data: IncomingFax[]
  pagination: {
    count: number
    page: number
    totalPages: number
  }
}> => {
  const res = await axiosInstace.get('/incoming-faxes/get-by-clinic', {
    params: {
      page,
      limit,
      tags,
      search,
      dnis,
    },
  })
  return res.data
}

export const getDnisListByClinic = async () => {
  const res = await axiosInstace.get('/incoming-faxes/get-dnis-list')
  return res.data
}

export const getFaxTagsWithCountByClinic = async () => {
  const res = await axiosInstace.get('/incoming-faxes/tags-and-count-by-clinic')
  return res.data
}

export const getBlobFaxFile = async (faxId: string) => {
  return await axiosInstace.get('/incoming-faxes/get-fax', {
    params: {
      faxId,
    },
    responseType: 'blob',
  })
}

export const getArrayBufferFaxFile = async (faxId: string) => {
  return await axiosInstace.get('/incoming-faxes/get-fax', {
    params: {
      faxId,
    },
    responseType: 'arraybuffer',
  })
}

export const syncFaxesForClinic = async () => {
  const res = await axiosInstace.post('/incoming-faxes/sync-for-clinic')
  return res.data
}

export const getNextFaxId = async (faxId: string, locationState: any) => {
  const res = await axiosInstace.get('/incoming-faxes/next-fax', {
    params: {
      faxId,
      tags: locationState?.tags || [],
      search: locationState?.search || '',
    },
  })
  return res.data
}

export const getPreviousFaxId = async (faxId: string, locationState: any) => {
  const res = await axiosInstace.get('/incoming-faxes/previous-fax', {
    params: {
      faxId,
      tags: locationState?.tags || [],
      search: locationState?.search || '',
    },
  })
  return res.data
}

export const getFaxInfo = async (faxId: string): Promise<IncomingFax> => {
  const res = await axiosInstace.get(`/incoming-faxes/${faxId}`)
  return res.data
}

export const updateFaxTags = async (faxId: string, tags: any[]): Promise<IncomingFax> => {
  const res = await axiosInstace.put(`/incoming-faxes/${faxId}/tags`, {
    tags,
  })
  return res.data
}

export const sendFaxManually = async (frmData: FormData) => {
  return await axiosInstace.post('/incoming-faxes/send-fax-manually', frmData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}

export const deleteFax = async (faxId: string) => {
  return await axiosInstace.delete(`/incoming-faxes/${faxId}`)
}

export const updateFaxName = async (faxId: string, name: string) => {
  const res = await axiosInstace.put(`/incoming-faxes/${faxId}/name`, {
    name,
  })
  return res.data
}

export const updateFaxNotes = async (faxId: string, notes: string) => {
  const res = await axiosInstace.put(`/incoming-faxes/${faxId}/notes`, {
    notes,
  })
  return res.data
}
