import {FC, useCallback, useEffect} from 'react'
import {Spinner} from 'react-bootstrap'
import {useLocation, useNavigate} from 'react-router-dom'
import {ihealthGetAllToken} from '../../apis'
import {useAuth} from '../../modules/auth'

export const PatientIntegrateIHealth: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const {currentPatient, setCurrentPatient} = useAuth()

  const getDataCallback = useCallback(async () => {
    try {
      const searchParams = new URLSearchParams(location.search)
      if (searchParams.get('code')) {
        const data = await ihealthGetAllToken(searchParams.get('code') || '')
        setCurrentPatient({...currentPatient!, ihealth: data.UserID})
      }
      navigate('/patient/dashboard')
    } catch (error) {
      console.log(error)
    }
  }, [location, currentPatient, setCurrentPatient, navigate])

  useEffect(() => {
    getDataCallback()
  }, [getDataCallback])

  return (
    <div className='d-flex flex-column gap-2 justify-content-center align-items-center vh-100'>
      <Spinner animation='border' variant='primary' />
      <p className='fs-6'>Loading...</p>
    </div>
  )
}
