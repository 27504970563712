import {useMemo, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import Select from 'react-select'

interface Props {
  show: boolean
  onSubmit: (fromLine: number, selectedFile: any, selectedOrg: any) => void
  onHide: () => void
  orgList: any[]
}

export const ImportCsvModal: React.FC<Props> = (props) => {
  const [fromLine, setFromLine] = useState<number>(2)
  const [selectedFile, setSelectedFile] = useState<File>()
  const [selectedOrg, setSelectedOrg] = useState<any>(null)

  const organizationList = useMemo(() => {
    const list = props.orgList.map((item) => ({
      value: item.id,
      label: item.name,
    }))
    return list
  }, [props.orgList])

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Import Patients CSV file</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId='formFile' className='mt-3'>
          <Form.Label>Select CSV file</Form.Label>
          <input
            type='file'
            className='form-control'
            accept='text/csv'
            onChange={(e) => setSelectedFile(e.target.files![0])}
          />
        </Form.Group>

        <Form.Group controlId='formNumber' className='mt-3'>
          <Form.Label>Import start from line:</Form.Label>
          <input
            type='number'
            className='form-control'
            value={fromLine}
            min={1}
            onChange={(e) => setFromLine(parseInt(e.target.value))}
          />
        </Form.Group>

        <Form.Group className='mt-3'>
          <Form.Label>Select Organization (Optional)</Form.Label>
          <Select
            isClearable
            options={organizationList}
            placeholder='Select Organization'
            value={selectedOrg}
            onChange={(value) => {
              setSelectedOrg(value)
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onSubmit(fromLine, selectedFile, selectedOrg)}>Import</Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
