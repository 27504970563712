import {createContext, FC, ReactNode, useContext, useState} from 'react'
import {Toast, ToastContainer} from 'react-bootstrap'
import {WithChildren} from '../../_metronic/helpers'

type CommonAlertType = 'success' | 'error'

type CommonAlertContextProps = {
  show: boolean
  type: CommonAlertType
  content: ReactNode
  delay: number
  showAlert: (type: CommonAlertType, content?: ReactNode, delay?: number) => void
}

const initAuthContextPropsState: CommonAlertContextProps = {
  show: false,
  type: 'success',
  content: '',
  delay: 3000,
  showAlert: (type: CommonAlertType, content?: ReactNode, delay?: number) => {},
}

const CommonAlertContext = createContext<CommonAlertContextProps>(initAuthContextPropsState)

export const useCommonAlert = () => {
  return useContext(CommonAlertContext)
}

export const CommonAlertProvider: FC<WithChildren> = ({children}) => {
  const [show, setShow] = useState<boolean>(initAuthContextPropsState.show)
  const [type, setType] = useState<CommonAlertType>(initAuthContextPropsState.type)
  const [content, setContent] = useState<ReactNode>(initAuthContextPropsState.content)
  const [delay, setDelay] = useState<number>(initAuthContextPropsState.delay)

  const showAlert = (type: CommonAlertType, content?: ReactNode, delay?: number) => {
    setType(type)
    if (!content) {
      content = type === 'success' ? 'Saved successfully' : 'Failed to save'
    }
    if (delay) {
      setDelay(delay)
    }
    setContent(content)
    setShow(true)
  }

  return (
    <CommonAlertContext.Provider value={{show, type, content, delay, showAlert}}>
      <ToastContainer className='p-3 position-fixed' position='top-end'>
        <Toast
          bg={type === 'success' ? 'primary' : 'danger'}
          show={show}
          onClose={() => setShow(false)}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className='me-auto text-capitalize'>{type}</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body className='text-white'>{content}</Toast.Body>
        </Toast>
      </ToastContainer>
      {children}
    </CommonAlertContext.Provider>
  )
}
