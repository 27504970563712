import {FC} from 'react'
import {Nav} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import {useAuth} from '../modules/auth'

type PropsDto = {
  active: 'Profile' | 'change_pass' | 'pin'
}

export const AccountNavbar: FC<PropsDto> = ({active}: PropsDto) => {
  const {currentUser} = useAuth()

  return (
    <>
      <Nav>
        <Nav.Item>
          <Nav.Link
            as={Link}
            className={active === 'Profile' ? 'active' : ''}
            to='/account/personal'
          >
            Profile
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            as={Link}
            className={active === 'change_pass' ? 'active' : ''}
            to='/account/security'
          >
            Change Password
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link as={Link} className={active === 'pin' ? 'active' : ''} to='/account/pin'>
            {currentUser?.hasPin ? 'Change' : 'Setup'} Pin Code
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  )
}
