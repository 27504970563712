import {useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'

interface Props {
  show: boolean
  onSubmit: (fromLine: number, selectedFile: any) => void
  onHide: () => void
}

export const ImportContactsCsvModal: React.FC<Props> = (props) => {
  const [fromLine, setFromLine] = useState<number>(1)
  const [selectedFile, setSelectedFile] = useState<File>()

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Import Contacts CSV file</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId='formFile' className='mt-3'>
          <Form.Label>Select CSV file</Form.Label>
          <input
            type='file'
            className='form-control'
            accept='text/csv'
            onChange={(e) => setSelectedFile(e.target.files![0])}
          />
        </Form.Group>

        <Form.Group controlId='formNumber' className='mt-3'>
          <Form.Label>Import start from line:</Form.Label>
          <input
            type='number'
            className='form-control'
            value={fromLine}
            min={1}
            onChange={(e) => setFromLine(parseInt(e.target.value))}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.onSubmit(fromLine, selectedFile)}>Import</Button>

        <Button variant='secondary' onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
